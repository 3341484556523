import React, { useRef, useState } from "react"
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Flex,
    Form,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Heading,
    Text,
    Input,
    Image,
    Stack,
    HStack,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

export default function UpdateProfile() {
    const nameRef = useRef();
    const emailRef = useRef();
    const emailConfirmRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, currentProfile, updatePasswordInFirebase, updateEmailInFirebase, updateProfileInDb } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match.");
        }
        if (emailRef.current.value !== emailConfirmRef.current.value) {
            return setError("Email addresses do not match.");
        }

        const promises = [];
        setLoading(true);
        setError("");

        if (nameRef.current.value !== currentProfile.name) {
            promises.push(updateProfileInDb({ name: nameRef.current.value }));
        }
        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmailInFirebase(emailRef.current.value));
        }
        if (passwordRef.current.value) {
            promises.push(updatePasswordInFirebase(passwordRef.current.value));
        }

        Promise.all(promises)
            .then(() => {
                navigate("/update-profile");
            })
            .catch((e) => {
                setError(`Failed to update account. Error: ${e}`);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                my={[2, 4, 8]}
            >
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']}
                    maxW={['xs', 'sm', 'md']} py={6} px={2}>

                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    <Heading as="h1" fontSize={["lg", "xl", "4xl"]}>Update User Details</Heading>
                    {error &&
                        <Alert status='error'>
                            <AlertIcon />
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>}
                    <Text fontSize={["xs", "sm", "md"]} pt={["4px", "8px", "16px"]}>
                        Current email: <i>{currentUser.email}</i>
                    </Text>
                    <Text fontSize={["xs", "sm", "md"]} pb={["4px", "8px", "16px"]}>
                        Current name: {currentProfile && currentProfile.name && <i>{currentProfile.name}</i> || <i>Not defined</i>}
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <FormControl pb={9} id="name">
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="input"
                                ref={nameRef}
                                placeholder="Leave blank to keep the same"
                            />
                        </FormControl>
                        <FormControl pb={3} id="email">
                            <FormLabel>New Email</FormLabel>
                            <Input
                                type="email"
                                ref={emailRef}
                                placeholder="Leave blank to keep the same"
                            />
                        </FormControl>
                        <FormControl pb={9} id="email-confirm">
                            <FormLabel>New Email Confirmation</FormLabel>
                            <Input
                                type="email"
                                ref={emailConfirmRef}
                                placeholder="Leave blank to keep the same"
                            />
                        </FormControl>
                        <FormControl pb={3} id="password">
                            <FormLabel>New Password</FormLabel>
                            <Input
                                type="password"
                                ref={passwordRef}
                                placeholder="Leave blank to keep the same"
                            />
                        </FormControl>
                        <FormControl pb={9} id="password-confirm">
                            <FormLabel>New Password Confirmation</FormLabel>
                            <Input
                                type="password"
                                ref={passwordConfirmRef}
                                placeholder="Leave blank to keep the same"
                            />
                        </FormControl>
                        <HStack pb={3} align={'left'} spacing={4}>
                            <Button
                                colorScheme="brand"
                                disabled={loading}
                                type="submit">
                                Update
                            </Button>
                            <Button
                                colorScheme="brand"
                                onClick={() => navigate('/')}
                                variant="outline">
                                Cancel
                            </Button>
                        </HStack>
                    </form>
                    <Alert status='info'>
                        <AlertIcon />
                        <AlertTitle>You will be automatically logged out after a successful change of email and/or password.</AlertTitle>
                    </Alert>
                </Stack>
            </Box>
        </Flex>
    )
}