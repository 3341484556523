import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";

function PriceWrapper({ children }) {
    return (
        <Box
            minWidth='320px'
            mb={4}
            shadow="base"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={useColorModeValue('gray.200', 'gray.500')}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

export default function Plans() {

    const { currentUser, currentProfile } = useAuth();
    const [billYearly, setBillYearly] = useState(true); //Billing switch
    const navigate = useNavigate();

    const responsive_bg = useColorModeValue('gray.50', 'gray.700');

    useEffect(() => {
        const el = document.createElement('script');
        el.onload = () => {
            window.Chargebee.init({
                "site": "fpltm"
            });
            window.Chargebee.registerAgain();
            // this.setState({ chargebeeReady: true });
        };
        el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
        document.body.appendChild(el);

        //Pre-populate email if the customer is logged in
        if (currentUser) {
            let cbInstance = window.Chargebee.getInstance();
            let cart = cbInstance.getCart();
            let customer = { email: currentUser.email };
            // Setting custom fields
            //customer["cf_custom_field1"] = "Custom field 1"
            cart.setCustomer(customer);
        }
    });

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Image
                    src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                    height={["75px", "125px", "175px"]} />
                <Heading as="h1" fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>
                    <b>Premium is now FREE for 3 months.</b>
                </Heading>
                <Heading as="h4" fontSize={["sm", "md", "xl"]} pb={["4px", "8px", "16px"]}>
                    You will be charged at the end of the three month trial period.<br/>You may cancel at any time.
                </Heading>
                <HStack p={["4px", "8px", "16px"]}>
                    <Text>Bill Monthly</Text>
                    <Switch alignSelf='center' id='billing_cycle_switch'
                        defaultChecked='true'
                        onChange={e => setBillYearly(!billYearly)} />
                    <Text>Bill Yearly</Text>
                </HStack>
            </VStack>
            <Stack
                direction={{ base: 'column', lg: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, md: 6, lg: 8 }}
                p={5}>
                {!currentUser &&
                    <PriceWrapper>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Community
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="5xl" fontWeight="900">
                                    FREE
                                </Text>
                            </HStack>
                            <Text mt={-4} fontSize="md" color="gray.500" >Forever</Text>
                        </Box>
                        <VStack
                            bg={responsive_bg}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Fixture Analysis and Metrics
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Player Price and Selection data
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    In-depth Points breakdown
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Minileague chat and analysis
                                </ListItem>

                            </List>
                            <Box w="80%" pt={7}>
                                <Button
                                    onClick={() => navigate('/signup')}
                                    w="full" colorScheme="brand" variant="outline">
                                    Sign up
                                </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>}

                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={useColorModeValue('brand.600', 'brand.500')}
                                px={3}
                                py={1}
                                color={useColorModeValue('#FFFFFF', 'gray.300')}
                                fontSize="sm"
                                fontWeight="600"
                                rounded="xl">
                                Most Popular
                            </Text>
                        </Box>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Premium
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    €
                                </Text>
                                {billYearly && <Text fontSize="5xl" fontWeight="900">3.90</Text>}
                                {!billYearly && <Text fontSize="5xl" fontWeight="900">5.90</Text>}
                                <Text fontSize="3xl" color="gray.500">
                                    /month
                                </Text>
                            </HStack>
                            {billYearly && <Text mt={-4} fontSize="md" color="gray.500" >Billed Yearly</Text>}
                            {!billYearly && <Text mt={-4} fontSize="md" color="gray.500">Billed Monthly</Text>}
                        </Box>
                        <VStack
                            bg={responsive_bg}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    <i>Everything from Community, and...</i>
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Buy/Hold/Sell Ratings
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Personalized transfer scouting
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Transfer Market Technical Analysis
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                {billYearly &&
                                    <Button w="full" colorScheme="brand" variant="solid" as="a"
                                        href="javascript:void(0)"
                                        data-cb-type="checkout"
                                        data-cb-item-0="FPLTM-Premium-EUR-Yearly"
                                        data-cb-item-0-quantity="1">
                                        Subscribe
                                    </Button>}
                                {!billYearly &&
                                    <Button w="full" colorScheme="brand" variant="solid" as="a"
                                        href="javascript:void(0)"
                                        data-cb-type="checkout"
                                        data-cb-item-0="FPLTM-Premium-EUR-Monthly"
                                        data-cb-item-0-quantity="1">
                                        Subscribe
                                    </Button>}
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Premium Plus
                        </Text>
                        <HStack justifyContent="center">
                            <Text fontSize="3xl" fontWeight="600">
                                €
                            </Text>
                            {billYearly && <Text fontSize="5xl" fontWeight="900">6.90</Text>}
                            {!billYearly && <Text fontSize="5xl" fontWeight="900">8.90</Text>}
                            <Text fontSize="3xl" color="gray.500">
                                /month
                            </Text>
                        </HStack>
                        {billYearly && <Text mt={-4} fontSize="md" color="gray.500" >Billed Yearly</Text>}
                        {!billYearly && <Text mt={-4} fontSize="md" color="gray.500">Billed Monthly</Text>}
                    </Box>
                    <VStack
                        bg={responsive_bg}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                <i>Everything from Premium, and...</i>
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Your private scouting report
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Data Downloads
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Pre- and Post-season analytics
                            </ListItem>
                        </List>
                        <Box w="80%" pt={7}>
                            {billYearly &&
                                <Button w="full" colorScheme="brand" variant="solid" as="a"
                                    href="javascript:void(0)"
                                    data-cb-type="checkout"
                                    data-cb-email="gardar@hey.com"
                                    data-cb-item-0="FPLTM-Premium-Plus-EUR-Yearly"
                                    data-cb-item-0-quantity="1">
                                    Subscribe
                                </Button>}
                            {!billYearly &&
                                <Button w="full" colorScheme="brand" variant="solid" as="a"
                                    href="javascript:void(0)"
                                    data-cb-type="checkout"
                                    data-cb-item-0="FPLTM-Premium-Plus-EUR-Monthly"
                                    data-cb-item-0-quantity="1">
                                    Subscribe
                                </Button>}
                        </Box>
                    </VStack>
                </PriceWrapper>
            </Stack>
        </Box>
    );
}