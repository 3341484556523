import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { render } from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams,
    useNavigate
} from "react-router-dom";
import { IoArrowBack, IoShareOutline } from 'react-icons/io5';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    Divider,
    Flex,
    Icon,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    InputLeftElement,
    InputRightElement,
    InputGroup,
    HStack,
    Heading,
    Link,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    ScaleFade,
    Spacer,
    Spinner,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    //HatenaShareButton,
    //InstapaperShareButton,
    //LineShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    //LivejournalShareButton,
    //MailruShareButton,
    //OKShareButton,
    PinterestShareButton,
    PinterestIcon,
    //PocketShareButton,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    //TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    //ViberShareButton,
    //VKShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    WorkplaceShareButton,
    WorkplaceIcon
} from "react-share";
import Header from "../Header";
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import LeagueStandings from "./LeagueStandings";
import LeagueTimeline from "./LeagueTimeline";
import LeagueLive from "./LeagueLive";
import Chat from "../chat"
import { useFetch } from "../../hooks/fetch";
import { ArrowBackIcon, ArrowForwardIcon, CheckIcon, LinkIcon, CopyIcon } from "@chakra-ui/icons";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function League() {

    const { league_id } = useParams();
    console.log(league_id); //DEBUG
    //const entry_page = Math.ceil(entry_rank / 50);

    const { currentUser, currentProfile, currentEntry } = useAuth();

    // Share box
    const [linkCopied, setLinkCopied] = useState(false);
    const league_share_link = `https://www.fpltransfermarket.com/league/${league_id}`;
    const { isOpen, onOpen, onClose } = useDisclosure({ 'onOpen': () => setLinkCopied(false) });

    // League position and pagination state, starts where the user is at in the league
    const [entryRank, setEntryRank] = useState(0);
    const [entryPage, setEntryPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    // If in chat fullscreen mode, is chat currently shown
    const [chatShown, setChatShown] = useState(false);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    // Get league data
    // TRAILING SLASH AT END OF league/ IS CRUCIAL FOR PRODUCTION
    // Otherwise fetch->fastapi will downgrade https to http and fail
    const league_url = league_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/league/?league_id=${league_id}&page_standings=${currentPage}&include_history=yes`;
    //console.log("League: "+league_url); //DEBUG
    const { status: leagueStatus, data: leagueData, error: leagueError }
        = useFetch(league_url);

    // Get current user's standings in league


    // Make tabs horizontal on small screens
    const chat_fullscreen_mode = useBreakpointValue({ base: true, md: false });
    const main_area_bg_colour = useColorModeValue('white', 'gray.700');
    const share_button_title = useBreakpointValue({ base: 'Share', md: 'Share League' });
    const button_size = useBreakpointValue({ base: 'xs', sm: 'md', md: 'md', lg: 'lg' });

    const LeaguePaginator = () => {
        return (
            <HStack justifyContent={'center'} spacing={['4px', '8px', '16px']} p={['4px', '8px', '16px']}>
                {currentPage > 1 && <Link onClick={() => setCurrentPage(1)}>{'<<'}</Link>}
                {currentPage > 1 && <Link onClick={() => setCurrentPage(currentPage - 1)}>{'<'}</Link>}
                {entryPage && entryPage < currentPage && <Link onClick={() => setCurrentPage(Math.ceil(entryRank / 50))}>{entryPage}</Link>}
                <Text fontWeight={'bold'}>{currentPage}</Text>
                {entryPage > currentPage && <Link onClick={() => setCurrentPage(Math.ceil(entryRank / 50))}>{entryPage}</Link>}
                {leagueData.standings.has_next && <Link onClick={() => setCurrentPage(currentPage + 1)}>{'>'}</Link>}
            </HStack>
        )
    }

    useEffect(() => {
        // Find the entry rank
        if (currentEntry && currentEntry.leagues && currentEntry.leagues.classic) {
            currentEntry.leagues.classic.map(league => {
                //console.log("Searching for league id");
                //console.table(league);
                if (league.id == league_id) { // It's string and integer
                    //console.log('Found league ID'); //DEBUG
                    setEntryRank(league.entry_rank);
                    setEntryPage(Math.ceil(league.entry_rank / 50)); //Pagination
                    setCurrentPage(entryPage);
                }
            });
        }
    }, [leagueData]);

    return (
        <>
            {leagueStatus === 'idle' && (
                <div>System is idle.</div>
            )}
            {gwStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{gwError}</AlertTitle>
                    <AlertDescription>Gameweek error.</AlertDescription>
                </Alert>}
            {leagueStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{leagueError}</AlertTitle>
                    <AlertDescription>League fetch error.</AlertDescription>
                </Alert>}
            {leagueStatus === 'fetching' && <SpinnerWithMessage message='Loading League' />}
            {leagueStatus === 'fetched' && (
                <>
                    <Flex
                        direction='horizontal'
                        alignItems='center'
                        p={['4px', '8px', '16px']}
                    >
                        {(chat_fullscreen_mode && !chatShown || !chat_fullscreen_mode) &&
                            <Link color="brand.200" as={ReactRouterLink} to="/leagues">
                                <IoArrowBack size='24px' />
                            </Link>}
                        {chat_fullscreen_mode && chatShown &&
                            <Button
                                m={['2px', '4px', '8px']}
                                p={['2px', '4px', '8px']}
                                onClick={() => { setChatShown(!chatShown) }}
                                leftIcon={<ArrowBackIcon />}
                                align='right'
                                colorScheme='brand'
                                variant='outline'
                                size={button_size}>
                                Show League
                            </Button>}
                        {!chatShown &&
                            <>
                                <Heading
                                    px={['4px', '8px', '16px']}
                                    fontSize={['xs', 'xl', '2xl']}>
                                    {leagueData.league.name}
                                </Heading>
                                {leagueData && leagueData.league && leagueData.league.name &&
                                    <>
                                        <Button
                                            m={['2px', '4px', '8px']}
                                            p={['2px', '4px', '8px']}
                                            onClick={onOpen}
                                            leftIcon={<IoShareOutline />}
                                            colorScheme='brand'
                                            variant='solid'
                                            size={button_size}>
                                            {share_button_title}
                                        </Button>
                                        <Modal
                                            isOpen={isOpen}
                                            onClose={onClose}
                                            size='xs'>
                                            <ModalOverlay />
                                            <ModalContent>
                                                <ModalHeader fontSize={['sm', 'lg']}>Share this league</ModalHeader>
                                                <ModalCloseButton />
                                                <ModalBody>
                                                    <Stack>
                                                        <Text fontSize={['xs', 'sm']}>Share this league through a link or on social networks...</Text>
                                                        {/*<InputGroup>
                                                            <InputLeftElement
                                                                pointerEvents='none'
                                                                color='gray.300'
                                                                fontSize={['xs', 'sm']}
                                                                children={<LinkIcon />}
                                                            />
                                                            <Input size='sm' fontSize={['xs', 'sm']} placeholder={league_share_link} isDisabled />
                                                        </InputGroup>*/}
                                                        <HStack py={['4px', '8px', '16px']}>
                                                            {!linkCopied &&
                                                                <Button
                                                                    colorScheme='brand'
                                                                    variant='solid'
                                                                    fontSize={['xs', 'sm']}
                                                                    onClick={() => {
                                                                        setLinkCopied(true);
                                                                        navigator.clipboard.writeText(league_share_link);
                                                                    }}
                                                                    leftIcon={<CopyIcon />}
                                                                >
                                                                    Copy Link
                                                                </Button>}
                                                            {linkCopied &&
                                                                <Button isDisabled
                                                                    colorScheme='brand'
                                                                    variant='outline'
                                                                    fontSize={['xs', 'sm']}
                                                                    leftIcon={<CheckIcon color='green.500' />}
                                                                >
                                                                    Link Copied
                                                                </Button>}

                                                        </HStack>

                                                        <HStack pb={['4px', '8px', '16px']}>
                                                            <FacebookShareButton
                                                                url={league_share_link}
                                                                quote={leagueData.league.name}
                                                            >
                                                                <FacebookIcon size={32} round='true' />
                                                            </FacebookShareButton>

                                                            <TwitterShareButton
                                                                url={league_share_link}
                                                                title={leagueData.league.name}
                                                            >
                                                                <TwitterIcon size={32} round />
                                                            </TwitterShareButton>

                                                            <TelegramShareButton
                                                                url={league_share_link}
                                                                title={leagueData.league.name}
                                                            >
                                                                <TelegramIcon size={32} round />
                                                            </TelegramShareButton>

                                                            <WhatsappShareButton
                                                                url={league_share_link}
                                                                title={leagueData.league.name}
                                                                separator=": "
                                                            >
                                                                <WhatsappIcon size={32} round />
                                                            </WhatsappShareButton>

                                                            <LinkedinShareButton url={league_share_link} >
                                                                <LinkedinIcon size={32} round />
                                                            </LinkedinShareButton>

                                                            <EmailShareButton
                                                                url={league_share_link}
                                                                subject={leagueData.league.name}
                                                                body={`Attached is a link to the ${leagueData.league.name} on FPL Transfer Market. Note that you still need to join the league through a separate link.`}
                                                            >
                                                                <EmailIcon size={32} round />
                                                            </EmailShareButton>

                                                        </HStack>
                                                        <Alert status='warning' py={['4px', '8px', '16px']} fontSize={['xs', 'sm']}>
                                                            <AlertIcon />Note that you still need to join the league through a separate link.
                                                        </Alert>
                                                    </Stack>
                                                </ModalBody>

                                                <ModalFooter>
                                                    <Button
                                                        fontSize={['xs', 'sm']}
                                                        colorScheme='brand'
                                                        variant='outline'
                                                        mr={3}
                                                        onClick={onClose}>
                                                        Close
                                                    </Button>
                                                </ModalFooter>
                                            </ModalContent>
                                        </Modal>
                                    </>}
                                <Spacer />
                            </>}
                        {chat_fullscreen_mode && !chatShown &&
                            <Button
                                m={['2px', '4px', '8px']}
                                p={['2px', '4px', '8px']}
                                onClick={() => { setChatShown(!chatShown) }}
                                rightIcon={<ArrowForwardIcon />}
                                align='right'
                                colorScheme='brand'
                                variant='outline'
                                size={button_size}>
                                Show Chat
                            </Button>}
                    </Flex>
                    <Divider orientation='horizontal' />
                    <Flex
                        flex={2}
                        minH='0'
                        alignItems="stretch"
                    >
                        {(!chat_fullscreen_mode || (chat_fullscreen_mode && !chatShown)) &&
                            <Flex
                                p={['4px', '8px', '16px']}
                                direction="column"
                                flex={2}
                                overflow="scroll"
                                bg={main_area_bg_colour}
                            >
                                <Tabs isLazy colorScheme="brand">
                                    <TabList>
                                        <Tab>Standings</Tab>
                                        {/*<Tab>Live</Tab>
                                        <Tab>Timeline</Tab>
                                        <Tab isDisabled>Premium</Tab>*/}
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            <LeaguePaginator />
                                            <LeagueStandings standings={leagueData.standings} entry_rank={entryRank} />
                                            <LeaguePaginator />
                                        </TabPanel>
                                        <TabPanel>
                                            <LeaguePaginator />
                                            <LeagueLive standings={leagueData.standings} entry_rank={entryRank} />
                                            <LeaguePaginator />
                                        </TabPanel>
                                        <TabPanel>
                                            <LeagueTimeline history={leagueData.history} />
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Flex>}
                        <Divider orientation='vertical' />
                        {(!chat_fullscreen_mode || (chat_fullscreen_mode && chatShown)) &&
                            <Flex
                                direction="column-reverse"
                                overflow="scroll"
                                h='100vh'
                                maxW={chat_fullscreen_mode ? '100%' : '35%'}
                                flex={1}
                                pb={['2px', '4px', '8px']}
                            >
                                <Spacer />
                                <Chat league_id={league_id} />
                            </Flex>
                        }
                    </Flex>
                </>
            )
            }

        </>
    )



}

