import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Link,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";

export default function FourHundredAndFour() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Image
                    src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                    height={["75px", "125px", "175px"]} />
                <Heading as="h1" fontSize={["xl", "2xl", "4xl"]} p={["4px", "8px", "16px"]}>
                    404
                </Heading>
                <Text width='75%' align='center'>
                    This page does not exist.
                </Text>
                <Text width='75%' align='center'>
                    Why don't you check out <b><Link color='brand.100' href='/'>the dashboard</Link></b>.
                </Text>
                {!currentUser && <Text width='75%' align='center'>Or sign up to one of our <b><Link color='brand.100' href='/plans'>plans</Link></b>.</Text>}
            </VStack>

        </Box>
    );
}