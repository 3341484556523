import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Link,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch,
    SimpleGrid
} from '@chakra-ui/react';
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";

export default function About() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.50', 'gray.900')}
                h='80vh'>
                <Card>
                    <CardHeader title='Data Downloads' />
                    <CardContent>
                        <Link as='a' href="https://dynamic.api.fpltransfermarket.com/fpltm/static/fixturelist_extended.csv" target="_blank" download>fixturelist_extended.csv</Link>
                        <Text> (updated every 15 minutes)</Text>
                    </CardContent>
                </Card>
            </SimpleGrid>
        </>
    );
}