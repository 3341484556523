import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";

export default function RestDays({horizon, nextGameweek}) {

    //const [horizon, setHorizon] = useState(6); //Horizon in gameweeks
    const [restDaysData, setRestDaysData] = useState([]);
    const [gameweeksXAxis, setGameweeksXAxis] = useState([]);
    const [teamsYAxis, setTeamsYAxis] = useState([]);
    const [axisRichText, setAxisRichText] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode();

    // ...........
    // Gather data
    const fixturelist_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/fixturelist_extended.json`;
    const { status, data, error } = useFetch(fixturelist_url);

    // Get next GW
    //const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    useEffect(() => {
        if (status === 'fetched') {

            //console.log(data); //DEBUG
            // First get all pl fixtures
            let pl_fixtures = data.filter(fixture => fixture['competition'] === 'Premier League');

            // Do gameweek calculations
            let gws_set = new Set();
            let teams_set = new Set();
            pl_fixtures.forEach(fixture => {
                gws_set.add(fixture['gameweek']);

                //Stringify, because for Set() {}!={}
                teams_set.add(JSON.stringify(
                    {
                        'short_name': fixture['short_name'],
                        'code': fixture['code']
                    }
                ));
            });

            let teams = [...teams_set].reverse(); //Convert to array
            //console.log(teams);

            //console.log(nextGameweek.id+" "+horizon); //DEBUG

            // Get fixtures in our horizon
            let pl_fixtures_in_horizon = pl_fixtures.filter(fixture => fixture['gameweek'] < nextGameweek.id + horizon)

            //console.log(pl_fixtures_in_horizon); //DEBUG

            // Get an array of all gameweeks in our horizon
            let gameweeks_in_horizon = [...gws_set]
                .filter(gw => gw >= nextGameweek.id)
                .filter(gw => gw < nextGameweek.id + horizon)
                .sort((a, b) => a - b);
            //console.log(gameweeks_in_horizon); //DEBUG

            // Iterate over each gameweek
            // Create an array of series objects with data for all matches that gameweek
            let rest_days_data = [];

            // Iterate over gameweeks (x-axis)
            for (let i = 0; i < gameweeks_in_horizon.length; i++) {
                //console.log(`----- Gameweek: ${gwData['id'] + i} -----`); //DEBUG

                // Iterate over teams (y-axis)
                for (let j = 0; j < teams.length; j++) {
                    //console.log(teams[j]); //DEBUG

                    // Filter the data to show
                    let rd = pl_fixtures_in_horizon
                        .filter(fixture => fixture['gameweek'] === nextGameweek.id + i)
                        .filter(fixture => fixture['short_name'] === JSON.parse(teams[j])['short_name'])
                        .sort((a, b) => a.gameweek - b.gameweek || a.short_name - b.short_name);

                    // Data format [y, x, value, label, tooltip, value]
                    // Value appears twice to ensure proper coloring of heatmap (don't know why)
                    // and must be at the back

                    // UPDATE 11.4.22: Now one empty field followed by value repeated need to be added
                    // to each record to ensure coloring of the heatmap

                    // Start with default entry (empty gameweek)
                    let temp_entry = [i, j, 10, '-', 'Empty gameweek.', '', 10]; 
                    
                    if (rd.length == 1) {
                        // One game this gameweek
                        temp_entry = [i, j, rd[0]['rest_days'], String(rd[0]['rest_days']), rd[0]['opponent_short_name']+'<br/>'+rd[0]['kickoff_date'], '', rd[0]['rest_days']];
                    
                    } else if (rd.length > 1) {
                        // More than one game this gameweek
                        let temp_restdays_value = rd[0]['rest_days']; // Min value for color
                        let temp_restdays_label = '';
                        let temp_opponents_tooltip_header = '';
                        let temp_opponents_tooltip_fixtures= ''; 
                        // Iterator for tags
                        function* tag() {
                            yield* ['Single gameweek:', 'Double gameweek:', 'Triple gameweek:', 'Quadruple gameweek:', 'Insane gameweek:'];
                        }
                        let tag_iterator = tag();
                        rd.forEach(element => {
                            if (temp_restdays_label) {
                                // Add divisor if this is not the first match this gw
                                temp_restdays_label += '/';
                            }
                            temp_restdays_label += element['rest_days'];
                            if (element['rest_days'] < temp_restdays_value) {
                                temp_restdays_value = element['rest_days'];
                            }

                            temp_opponents_tooltip_header = tag_iterator.next().value;
                            temp_opponents_tooltip_fixtures += element['opponent_short_name']+' '+element['kickoff_date']+'<br/>';

                        });

                        temp_entry = [
                                i, j, //Coordinates 
                                temp_restdays_value,  
                                temp_restdays_label,
                                temp_opponents_tooltip_header+'<br/>'+temp_opponents_tooltip_fixtures, 
                                '', 
                                temp_restdays_value
                            ];
                    }
                    //console.log(rd); //DEBUG
                    //console.log(temp_entry); //DEBUG
                    rest_days_data.push(temp_entry);
                }
            }

            // Echarts object for shirt pic axis labels
            let rich = {};

            teams.forEach(team => {
                rich[JSON.parse(team)['short_name']] = {
                    backgroundColor: {
                        image: getShirtImg(JSON.parse(team)['code']),
                    }
                }
            })

            //console.log(rich);

            //console.log(rest_days_data); //DEBUG

            setRestDaysData(rest_days_data);
            setGameweeksXAxis(gameweeks_in_horizon);
            setTeamsYAxis(teams);
            setAxisRichText(rich);

            //console.table(restDaysData); //DEBUG
        }

    }, [horizon, status, nextGameweek])

    return (
        <>
            <ReactEcharts
                option={{
                    backgroundColor: '',
                    textStyle: {
                        fontFamily: 'Roboto Mono, monospace'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function (param) {
                          
                          return param.data[4];
                        }
                      },
                    grid: {

                        top: '3%',
                        left: '20%',
                        right: '5%',
                        bottom: '10%'
                    },
                    xAxis: {
                        type: 'category',
                        data: gameweeksXAxis,
                        name: 'Gameweek',
                        nameLocation: 'middle',
                        nameGap: 20,
                        splitArea: {
                            show: true
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: teamsYAxis,
                        name: 'Team',
                        axisLabel: {
                            formatter: team => {
                                return '{' + JSON.parse(team)['short_name'] + '|  } ' + JSON.parse(team)['short_name'];
                            },
                            margin: 60,
                            align: 'left',
                            rich: axisRichText
                        },
                        nameLocation: 'middle',
                        nameGap: 70,
                        splitArea: {
                            show: true
                        }
                    },
                    visualMap: {
                        show: false,
                        min: 2,
                        max: 5,
                        inRange: {
                            color: ['#F55C47', '#FFFFFF'] //From smaller to bigger value ->
                        },
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '0%'
                    },
                    series: [
                        {
                            name: 'Rest Days',
                            type: 'heatmap',
                            data: restDaysData,
                            label: {
                                normal: {
                                    show: true,
                                    formatter: (param) => {
                                        return param.data[3];

                                    }
                                }
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                }}
                notMerge={true} //Ensures update when horizon is changed
                style={{ height: '550px', width: '100%' }}
                opts={{ renderer: 'canvas' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

