import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Image,
    Link,
    Button,
    ButtonGroup,
    Heading,
    Text,
    Divider,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';
import logoTextLightMode from "../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../assets/img/logo/FPL_Logo_V2_2.png";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";

export default function Landing() {

    const navigate = useNavigate();

    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack align={'center'} spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    <Heading fontSize={['md','lg','2xl']}>You must be signed in to access this resource</Heading>

                    <ButtonGroup spacing="6">
                        <Button
                            onClick={() => navigate('/login')}
                            colorScheme="brand"
                            variant="outline">
                            Log in
                        </Button>
                        <Button
                            onClick={() => navigate('/plans')}
                            colorScheme="brand">
                            Sign up
                        </Button>
                    </ButtonGroup>
                </Stack>
            </Box>
        </Flex>
    );
}