import {
    Badge,
    HStack,
    Text,
    Icon,
    Link,
    Flex,
    Tooltip,
    Button
} from '@chakra-ui/react';
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle, AiOutlineArrowRight } from 'react-icons/ai';
import { BsArrowsExpand } from "react-icons/bs";
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useAuth } from "../../context/AuthContext";
import * as React from 'react';

export const RecommendationBadge = ({ recommendation }) => {

    const { currentUser, getSubscriptionLevel } = useAuth();
    const subscription_level = getSubscriptionLevel();
    console.log(subscription_level); //DEBUG
    const is_subscriber = subscription_level.length >= 1 ? true : false;

    return (
        <>
            {!is_subscriber &&
                <Link href='/plans'>
                    <Tooltip label="Buy/Hold/Sell ratings require Premium subscription" aria-label='A tooltip'>
                        <Badge width={['100px', '125px', '140px']} variant='solid' colorScheme='brand'>
                            <Flex direction='horizontal' p={['2px', '6px', '8px']} justify='center' align='center'>
                                <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Premium</Text>
                                <Icon ml={['2px', '4px', '6px']} as={AiOutlineArrowRight} w={[2, 3, 4]} h={[2, 3, 4]} />
                            </Flex>
                        </Badge>
                    </Tooltip>
                </Link>
            }
            {is_subscriber && recommendation === 'strong_buy' &&
                <Badge width={['100px', '125px', '140px']} variant='solid' colorScheme='green'>
                    <HStack p={['2px', '6px', '8px']} align='center'>
                        <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Strong Buy</Text>
                        <Icon as={GiStarFormation} w={[2, 3, 4]} h={[2, 3, 4]} />
                    </HStack>
                </Badge>
            }
            {is_subscriber && recommendation === 'buy' &&
                <Badge width={['100px', '125px', '140px']} variant='subtle' colorScheme='green'>
                    <HStack p={['2px', '6px', '8px']} justify='center' align='center'>
                        <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Buy</Text>
                        <Icon as={AiTwotoneStar} w={[2, 3, 4]} h={[2, 3, 4]} />
                    </HStack>
                </Badge>
            }
            {is_subscriber && recommendation === 'hold' &&
                <Badge width={['100px', '125px', '140px']} variant='subtle' colorScheme='gray'>
                    <HStack p={['2px', '6px', '8px']} justify='center' align='center'>
                        <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Hold</Text>
                        <Icon as={AiFillQuestionCircle} w={[2, 3, 4]} h={[2, 3, 4]} />
                    </HStack>
                </Badge>
            }
            {is_subscriber && recommendation === 'sell' &&
                <Badge width={['100px', '125px', '140px']} variant='subtle' colorScheme='red'>
                    <HStack p={['2px', '6px', '8px']} justify='center' align='center'>
                        <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Sell</Text>
                        <Icon as={IoWarning} w={[2, 3, 4]} h={[2, 3, 4]} />
                    </HStack>
                </Badge>
            }
            {is_subscriber && recommendation === 'strong_sell' &&
                <Badge width={['100px', '125px', '140px']} variant='solid' colorScheme='red'>
                    <HStack p={['2px', '6px', '8px']} justify='center' align='center'>
                        <Text fontSize={['xs', 'sm', 'md']} fontWeight='bold'>Strong Sell</Text>
                        <Icon as={RiErrorWarningFill} w={[2, 3, 4]} h={[2, 3, 4]} />
                    </HStack>
                </Badge>
            }
        </>
    )
}