import ARS from "../assets/img/shirts2223/ARS.png";
import AVL from "../assets/img/shirts2223/AVL.png";
import BRE from "../assets/img/shirts2223/BRE.png";
import BHA from "../assets/img/shirts2223/BHA.png";
//import BUR from "../assets/img/shirts2223/BUR.png";
import BOU from "../assets/img/shirts2223/BOU.png";
import CHE from "../assets/img/shirts2223/CHE.png";
import CRY from "../assets/img/shirts2223/CRY.png";
import EVE from "../assets/img/shirts2223/EVE.png";
import LEI from "../assets/img/shirts2223/LEI.png";
import LEE from "../assets/img/shirts2223/LEE.png";
import LIV from "../assets/img/shirts2223/LIV.png";
import FUL from "../assets/img/shirts2223/FUL.png";
import MCI from "../assets/img/shirts2223/MCI.png";
import MUN from "../assets/img/shirts2223/MUN.png";
import NEW from "../assets/img/shirts2223/NEW.png";
//import NOR from "../assets/img/shirts2223/NOR.png";
import NFO from "../assets/img/shirts2223/NFO.png";
import SOU from "../assets/img/shirts2223/SOU.png";
import TOT from "../assets/img/shirts2223/TOT.png";
//import WAT from "../assets/img/shirts2223/WAT.png";
import WHU from "../assets/img/shirts2223/WHU.png";
import WOL from "../assets/img/shirts2223/WOL.png";


export function getShirtImg(code) {
    let teams = {
        3: ARS,
        7: AVL,
        94: BRE,
        36: BHA,
        91: BOU,
        //90: BUR,
        8: CHE,
        31: CRY,
        11: EVE,
        13: LEI,
        2: LEE,
        14: LIV,
        54: FUL,
        43: MCI,
        1: MUN,
        4: NEW,
        //45: NOR,
        17: NFO,
        20: SOU,
        6: TOT,
        //57: WAT,
        21: WHU,
        39: WOL  
    };
    return teams[code];
}

export function getShirtImgFromShortName(short_name) {
    let teams = {
        'ARS': ARS,
        'AVL': AVL,
        'BRE': BRE,
        'BHA': BHA,
        'BOU': BOU,
        //'BUR': BUR,
        'CHE': CHE,
        'CRY': CRY,
        'EVE': EVE,
        'FUL': FUL,
        'LEI': LEI,
        'LEE': LEE,
        'LIV': LIV,
        'MCI': MCI,
        'MUN': MUN,
        'NEW': NEW,
        'NFO': NFO,
        //'NOR': NOR,
        'SOU': SOU,
        'TOT': TOT,
        //'WAT': WAT,
        'WHU': WHU,
        'WOL': WOL  
    };
    return teams[short_name];
}