import {
  Button, 
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
//import { AiFillInfoCircle } from 'react-icons/ai';
import * as React from 'react';

export const CardHeader = (props) => {
  const { title, action, children } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex align="center" justify="space-between" px="3" py="2" borderBottomWidth="1px">
      <Heading fontSize="md">{title}</Heading>
      <QuestionIcon 
        color="brand.100"
        _hover={{ color: "brand.600" }}
        onClick={onOpen} />
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}