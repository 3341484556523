import { ReactNode, useEffect } from "react";
import {
  Avatar,
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  HStack,
  Collapse,
  Icon,
  Image,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Switch,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  useColorMode
} from '@chakra-ui/react';
import { background } from "@chakra-ui/styled-system";
import { useAuth } from "../context/AuthContext";
import { app, auth, db, analytics, storage } from "../config/firebase";
//import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { BiMenu } from "react-icons/bi";
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
import logoLightMode from "../assets/img/logo/FPL_Logo_S_1.png";
import logoDarkMode from "../assets/img/logo/FPL_Logo_S_1.png";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";

const Links = [
  { text: 'Home', url: '/' },
  { text: 'Leagues', url: '/leagues' },
  { text: 'Premium', url: '/premium' },
  { text: 'About us', url: '/about' }
];

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('brand.200', 'brand.700'),
    }}>
    {children}
  </Link>
);

const Header = () => {

  const { colorMode, toggleColorMode } = useColorMode();
  const { currentUser, currentProfile, currentProfileImgUrl, logout } = useAuth();
  const { isOpen, onToggle } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    const el = document.createElement('script');
    el.onload = () => {
      window.Chargebee.init({
        "site": "fpltm"
      });
      window.Chargebee.registerAgain();
      // this.setState({ chargebeeReady: true });
    };
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);

  }, []);

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem"
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
      >
        <Flex align="center" mr={5}>
          <Link href='/' >
            <Image src={colorMode === 'light' ? logoLightMode : logoDarkMode} height={['50px', '60px', '70px']} />
          </Link>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}>
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <AiFillCloseCircle w={3} h={3} /> : <AiOutlineMenu w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Open menu'}
            />
          </Flex>
          <HStack spacing={8} alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>

              {Links.map((link) => (
                <Link
                  href={link.url}
                  key={link.text}
                  px={2}
                  py={1}
                  rounded={'md'}
                  _hover={{
                    color: 'white',
                    bg: 'brand.100',
                  }}
                >{link.text}</Link>
              ))}
            </HStack>
          </HStack>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          align="center"
          spacing={6}>
          {colorMode === 'light' &&
            <MoonIcon _hover={{ color: "brand.100" }} onClick={toggleColorMode} />}
          {colorMode === 'dark' &&
            <SunIcon _hover={{ color: "brand.100" }} onClick={toggleColorMode} />}
          {!currentUser && <Button onClick={() => navigate('/login')} colorScheme="brand" variant="outline">Log in</Button>}
          {!currentUser && <Button onClick={() => navigate('/plans')} colorScheme="brand">Sign up</Button>}
          {currentUser &&
            <Menu>
              <MenuButton
                pr={['4px', '6px', '8px']}
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                {currentProfileImgUrl &&
                  <Avatar size={'sm'} bg="#ffffff" src={currentProfileImgUrl} /> ||
                  currentProfile &&
                  <Avatar size={'sm'} name={currentProfile.name} /> || <Avatar size={'sm'} />}

              </MenuButton>
              <MenuList>
                {/*currentProfile && currentProfile.name &&
                  <MenuItem>
                    <Text>{currentProfile.name}</Text>
                  </MenuItem> ||
                  <MenuItem>
                    <Text>{currentUser.email}</Text>
                </MenuItem>*/}
                <MenuItem onClick={() => navigate('/update-profile')}>User Details</MenuItem>
                <MenuItem onClick={() => navigate('/profile-picture')}>Profile Picture</MenuItem>
                <MenuItem onClick={() => navigate('/fpl-login')}>FPL Login</MenuItem>
                {currentProfile && currentProfile.sub_id &&
                  <MenuItem as="a"
                    href="javascript:void(0)"
                    data-cb-type="portal">
                    Subscription
                  </MenuItem>}
                <MenuDivider />
                {currentUser && <MenuItem onClick={() => auth.signOut()}>Sign out</MenuItem>}
              </MenuList>
            </Menu>}
        </Stack>
      </Flex>
      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} px='8px' spacing={4}>
            {Links.map((link) => (
              <Link
                href={link.url}
                key={link.text}
                px={6}
                py={1}
                rounded={'md'}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                  bg: 'brand.100',
                }}
              >{link.text}</Link>
            ))}
          </Stack>
        </Box>
      ) : null}
    </>
  );
};

export default Header;