import React, { useRef, useState } from "react";
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    BeatLoader,
    Box,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Link,
    Flex,
    Stack,
    HStack,
    StackDivider,
    Heading,
    Input,
    Text,
    Spinner,
    Wrap,
    useColorModeValue
} from "@chakra-ui/react";
import { AiOutlineReload, AiOutlineArrowRight } from "react-icons/ai";
import { useAuth } from "../../context/AuthContext";
import { app, auth, db, analytics } from "../../config/firebase";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { useCollectionData } from 'react-firebase-hooks/firestore'; //TODO: Use or not use?
import { useFetch } from "../../hooks/fetch";
import { ExternalLinkIcon } from '@chakra-ui/icons';

export default function FplLogin() {
    // FPL Login
    const fplEmailRef = useRef();
    const fplPasswordRef = useRef();
    const fplTestNowRef = useRef(false);
    const fplLoginTestSuccessRef = useRef(false);

    // FPL Team ID
    const fplManagerIdRef = useRef();
    const fplManagerIdTestSuccessRef = useRef(false);

    const fplSkipForNowRef = useRef();
    //const passwordConfirmRef = useRef()

    const { currentUser, updateProfileInDb } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Initial 'signup' or profile 'update'
    const flow = searchParams.get('flow');

    //Test connection to FPL systems
    const [fplLoginCheck, setFplLoginCheck] = useState({
        status: 'idle',
        data: '',
        error: ''
    })

    //Test FPL team ID
    const [fplManagerIdCheck, setFplManagerIdCheck] = useState({
        status: 'idle',
        data: '',
        error: ''
    })

    // ........................................................................
    const checkFplLogin = async () => {
        setFplLoginCheck({
            status: 'checking',
            data: '',
            error: ''
        });

        const fpl_check_url = `${process.env.REACT_APP_API_ENDPOINT}fpl/${fplEmailRef.current.value}/${fplPasswordRef.current.value}`;

        fetch(fpl_check_url)
            .then(response => response.json())
            .then(result => {
                //console.log(result);
                if (result.fpl_manager_id === 0) {
                    //console.log("Nothing :-(")
                    setFplLoginCheck({
                        status: 'failure',
                        data: result,
                        error: ''
                    });
                } else {
                    //console.log("Something!")
                    setFplLoginCheck({
                        status: 'success',
                        data: result,
                        error: ''
                    });

                    //Save the users profile automatically
                    //LET'S NOT DO THIS
                    //this.refs['fpl_login_form'].submit();
                }
            })
            .catch(exception => {
                setFplLoginCheck({
                    status: 'error',
                    data: '',
                    error: 'Sorry, but there was an error in a backend service. Please try again in a few moments.'
                });
                //console.log(fplcheck);
                return;
            });


        //console.log("Outahere...")
    };

    // ........................................................................
    const checkFplManagerId = async () => {
        setFplManagerIdCheck({
            status: 'checking',
            data: '',
            error: ''
        });

        const fpl_entry_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/entry?fpl_manager_id=${fplManagerIdRef.current.value}`;

        console.log(fpl_entry_url); //DEBUG

        fetch(fpl_entry_url)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.name === 0) {
                    //console.log("Nothing :-(")
                    setFplManagerIdCheck({
                        status: 'failure',
                        data: result,
                        error: ''
                    });
                } else {
                    //console.log("Something!")
                    setFplManagerIdCheck({
                        status: 'success',
                        data: result,
                        error: ''
                    });
                }
            })
            .catch(exception => {
                setFplManagerIdCheck({
                    status: 'error',
                    data: '',
                    error: 'Sorry, but there was an error in a backend service. Please try again in a few moments.'
                });
                //console.log(fplcheck);
                return;
            });


        //console.log("Outahere...")
    };

    // ........................................................................
    async function handleFPLLoginUpdate(e) {
        e.preventDefault()

        const profile = {
            fpl_user: fplEmailRef.current.value,
            fpl_pass: fplPasswordRef.current.value,
            fpl_manager_id: fplLoginCheck.data.fpl_manager_id
        };

        if (!fplLoginTestSuccessRef && !fplSkipForNowRef) {
            return setError('Please provide a valid FPL username and password.');
        }

        try {
            setError("");
            setLoading(true);
            //console.log(profile); //DEBUG
            updateProfileInDb(profile);
            navigate("/home");
        } catch (e) {
            setError(e);
        }

        setLoading(false);
    }

    // ........................................................................
    async function handleFPLManagerIdUpdate(e) {
        e.preventDefault()

        const profile = {
            fpl_manager_id: fplManagerIdRef.current.value
        };

        if (!fplManagerIdTestSuccessRef && !fplSkipForNowRef) {
            return setError('Please provide a valid FPL username and password.');
        }

        try {
            setError("");
            setLoading(true);
            //console.log(profile); //DEBUG
            updateProfileInDb(profile);
            navigate("/");
        } catch (e) {
            setError(e);
        }

        setLoading(false);
    }

    // ........................................................................
    return (

        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={[2, 4, 8]}>
                {!currentUser &&
                    <Alert status="error">
                        <AlertIcon />
                        <AlertTitle mr={2}>You need to be logged in before updating your profile.</AlertTitle>
                        <AlertDescription>
                            <Text>
                                <Link color="brand.100" as={ReactRouterLink} to="/login">Log In</Link>
                                {" "}or{" "}
                                <Link color="brand.100" as={ReactRouterLink} to="/signup">Sign up</Link>
                            </Text>
                        </AlertDescription>
                    </Alert>}
                {currentUser &&
                    <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']}
                        maxW={['xs', 'sm', 'md']} py={6} px={2}>
                        {error && <Alert variant="danger">{error}</Alert>}

                        <Heading as="h1" fontSize={["lg", "xl", "4xl"]}>
                            FPL Profile
                        </Heading>
                        <Text mt={4}>
                            Enter your FPL team details to see all your leagues and enable advanced analytics.
                            Your login details are stored in encrypted form and never given to anyone.
                        </Text>
                        <Text mt={4}>
                            Please provide your <i>FPL ID</i>. <i>FPL Login</i> is coming soon. 
                        </Text>
                        <Tabs>
                            <TabList>
                                <Tab>FPL ID</Tab>
                                <Tab isDisabled>FPL Login</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                <Link color='gray.400' to='/find-your-fpl-id' as={ReactRouterLink} isExternal>How do I find this?<ExternalLinkIcon mx='2px' /></Link>
                                    <form id='fpl_team_id_form' onSubmit={handleFPLManagerIdUpdate}>
                                        <FormControl id="fplManagerId" mt={4}>
                                            <FormLabel>FPL ID</FormLabel>
                                            <Input id="fplManagerId_input" type="number" ref={fplManagerIdRef} placeholder='Digits only' required />
                                        </FormControl>
                                        <Wrap mt={8} spacing="6px" >
                                            <FormControl id="fplchecklogin">
                                                <Button leftIcon={<AiOutlineReload />} disabled={loading || fplManagerIdCheck.status === 'success'} colorScheme="brand"
                                                    type="button" variant="solid" onClick={() => checkFplManagerId()}>
                                                    Test
                                                </Button>
                                            </FormControl>
                                            {fplManagerIdCheck.status === 'error' &&
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    Unexpected error: {fplLoginCheck.error}
                                                </Alert>}
                                            {fplManagerIdCheck.status === 'checking' &&
                                                <Alert status="info">
                                                    <AlertIcon />
                                                    Checking...<Spinner />
                                                </Alert>}
                                            {fplManagerIdCheck.status === 'failure' &&
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    Unable to log in. Please provide a different email and password.
                                                </Alert>}
                                            {fplManagerIdCheck.status === 'success' &&
                                                <Alert status="success">
                                                    <AlertIcon />
                                                    Success. Your FPL team name is {fplManagerIdCheck.data.name}
                                                </Alert>}
                                            {fplManagerIdCheck.status === 'idle' && <div></div>}
                                        </Wrap>
                                        {fplManagerIdCheck.status === 'success' && //Save and exit
                                            <FormControl mt={4}>
                                                <Button
                                                    colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="submit">
                                                    Save and exit
                                                </Button>
                                            </FormControl>
                                        }
                                        {fplManagerIdCheck.status != 'success' && //Exit without saving
                                            <FormControl mt={4}>
                                                <Button
                                                    onClick={() => navigate('/')}
                                                    variant="outline"
                                                    colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="button">
                                                    Skip this for now
                                                </Button>
                                            </FormControl>
                                        }
                                    </form>
                                </TabPanel>

                                <TabPanel>
                                    <form id='fpl_login_form' onSubmit={handleFPLLoginUpdate}>
                                        <FormControl id="fplemail" mt={4}>
                                            <FormLabel>FPL Login Email</FormLabel>
                                            <Input id="fplemail" type="email" ref={fplEmailRef} required />
                                        </FormControl>
                                        <FormControl id="fplpassword" mt={4}>
                                            <FormLabel>FPL Login Password</FormLabel>
                                            <Input id="fplpassword" type="password" ref={fplPasswordRef} required />
                                        </FormControl>
                                        <Wrap mt={8} spacing="6px" >
                                            <FormControl id="fplchecklogin">
                                                <Button leftIcon={<AiOutlineReload />} disabled={loading || fplLoginCheck.status === 'success'} colorScheme="brand"
                                                    type="button" variant="solid" onClick={() => checkFplLogin()}>
                                                    Test
                                                </Button>
                                            </FormControl>
                                            {fplLoginCheck.status === 'error' &&
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    Unexpected error: {fplLoginCheck.error}
                                                </Alert>}
                                            {fplLoginCheck.status === 'checking' &&
                                                <Alert status="info">
                                                    <AlertIcon />
                                                    Checking...<Spinner />
                                                </Alert>}
                                            {fplLoginCheck.status === 'failure' &&
                                                <Alert status="error">
                                                    <AlertIcon />
                                                    Unable to log in. Please provide a different email and password.
                                                </Alert>}
                                            {fplLoginCheck.status === 'success' &&
                                                <Alert status="success">
                                                    <AlertIcon />
                                                    Success. Your FPL team ID is {fplLoginCheck.data.fpl_manager_id}
                                                </Alert>}
                                            {fplLoginCheck.status === 'idle' && <div>Idle...</div>}
                                        </Wrap>
                                        {fplLoginCheck.status === 'success' && //Save and exit
                                            <FormControl mt={4}>
                                                <Button
                                                    colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="submit">
                                                    Save and exit
                                                </Button>
                                            </FormControl>
                                        }
                                        {fplLoginCheck.status != 'success' && //Exit without saving
                                            <FormControl mt={4}>
                                                <Button
                                                    onClick={() => navigate('/')}
                                                    variant="outline"
                                                    colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="button">
                                                    Skip this for now
                                                </Button>
                                            </FormControl>
                                        }
                                    </form>
                                </TabPanel>
                            </TabPanels>

                        </Tabs>

                    </Stack>
                }
            </Box>
        </Flex >
    )

}