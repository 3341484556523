import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Flex,
    Text,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode,
    useBreakpointValue
} from "@chakra-ui/react";
import chroma from "chroma-js";
import moment from 'moment';
import { useFetch } from "../../hooks/fetch";
import { getShirtImg, getShirtImgFromShortName } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function FixtureList({ team_short_name }) {

    const [horizon, setHorizon] = useState(7); //Horizon in gameweeks
    //Fixture highlights are different for each horizon
    const [fixturelistFiltered, setFixturelistFiltered] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode();
    const fixturelist_table_size = useBreakpointValue({ base: 'sm', sm: 'sm', md: 'md'});

    const diffCol =
        chroma.scale(['#BACB2C', '#FFFFFF', '#F55C47'])
            //chroma.scale("Spectral")
            .domain([2, 3.5, 5]);
            //.padding(-0.5);

    const restCol =
        chroma.scale(['#F55C47ff', '#FFFFFF00','#FFFFFF00'])
            //chroma.scale("Spectral")
            .domain([2, 5, 15])
            .nodata('#FFFFFF00');

    // ...........
    // Gather data
    const fixturelist_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/fixturelist_extended.json`;
    const { status, data, error } = useFetch(fixturelist_url);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    useEffect(() => {
        if (status == 'fetched' && gwStatus === 'fetched') {
            //console.log(data); //DEBUG
            // First get all relevant fixtures
            let fixturelist_filtered =
                data.filter(fixture => fixture['short_name'] === team_short_name)
                    .sort((a, b) => {
                        if (a.kickoff_date < b.kickoff_date) {
                            return -1;
                        }
                        if (a.kickoff_date > b.kickoff_date) {
                            return 1;
                        }
                        return 0; //They are equal
                    });

            //console.log(fixturelist_filtered); //DEBUG

            setFixturelistFiltered(fixturelist_filtered);
        }

    }, [horizon, status, gwStatus])

    const DisplayFixture = data && fixturelistFiltered && fixturelistFiltered.map(
        (team_fixture) => {
            return (
                <Tr key={team_fixture.kickoff_date}>
                    <Td px={['0px', '4px', '8px']} 
                        fontSize={['xs','xs','sm']}
                        color='gray.400'
                    >
                        {team_fixture.kickoff_date && moment(team_fixture.kickoff_date).format('MMMM Do')}
                    </Td>
                    <Td px={['0px', '4px', '8px']}
                        fontSize={['xs','xs','sm']} 
                        color={team_fixture.tag === 'double' || team_fixture.tag === 'treble' ? 'brand.600' 
                             : team_fixture.tag === 'empty' ? 'gray.400' : ''}
                        fontWeight={
                            team_fixture.tag === 'double' 
                            || team_fixture.tag === 'treble' 
                            || team_fixture.tag === 'empty'? 'bold' : ''}
                        >
                            {team_fixture.gameweek}
                    </Td>
                    <Td px={['8px', '8px', '8px']} 
                        fontSize={['xs','xs','sm']}
                    >
                        {team_fixture.tag != 'empty' ? team_fixture.competition : <i>Empty gameweek</i>}
                    </Td>
                    <Td px={['0px', '4px', '8px']} >
                        <HStack>
                            <Image src={getShirtImgFromShortName(team_fixture.opponent_short_name)} height={['20px', '30px']} />
                            {team_fixture.opponent_short_name &&
                                <Link 
                                    fontSize={['xs','xs','sm']} 
                                    fontWeight={team_fixture.homeaway === 'home' ? 'bold' : 'normal'}
                                    color="brand.100" 
                                    as={ReactRouterLink} 
                                    to={`../team/${team_fixture.opponent_short_name}`}>
                                        {team_fixture.tag != 'empty' ? team_fixture.opponent_name : '-'}
                                </Link>}
                            {!team_fixture.opponent_short_name && 
                                <Text fontSize={['xs','xs','sm']}>{team_fixture.opponent_name}</Text>}
                        </HStack>
                    </Td>
                    <Td px={['4px', '4px', '8px']}
                        textAlign='center'
                        backgroundColor={team_fixture.competition === 'Premier League' && String(restCol(team_fixture.rest_days))}
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {team_fixture.competition === 'Premier League' && team_fixture.rest_days}
                    </Td>
                    <Td px={['4px', '4px', '8px']}
                        textAlign='center'
                        color={'brand.black'}
                        backgroundColor={team_fixture.competition === 'Premier League' && String(diffCol(team_fixture.fixture_difficulty))}
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        {team_fixture.competition === 'Premier League' && team_fixture.fixture_difficulty}
                    </Td>
                    {/*<Td>{team_fixture.attack_delta}</Td>
                    <Td>{team_fixture.defense_delta}</Td>*/}
                </Tr >
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>Fixture List...</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Loading Fixture List' />}
            {status === 'fetched' && (
                <Table variant='simple' size={fixturelist_table_size} colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Day</Th>
                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>GW</Th>
                            <Th px={['8px', '8px', '8px']} w='15%' fontSize={['xs', 'sm', 'md']}>Comp.</Th>
                            <Th px={['0px', '4px', '8px']} w='25%' fontSize={['xs', 'sm', 'md']}>Opponents</Th>
                            <Th px={['4px', '4px', '8px']} textAlign='center' fontSize={['xs', 'sm', 'md']}>Rest Days</Th>
                            <Th px={['4px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Difficulty</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {DisplayFixture}
                    </Tbody>
                </Table>
            )}
        </>
    );
}

