import React, { useRef, useState } from "react"
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Image,
    Checkbox,
    Stack,
    Button,
    ButtonGroup,
    Heading,
    Text,
    Divider,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);
            login(emailRef.current.value, passwordRef.current.value)
                .then(() => {
                    navigate("/"); //Move to the dashboard
                })
                .catch((e) => {
                    console.log("Login inner catch");
                    setError(e);
                });
        } catch (e) {
            console.log("Login outer catch");
            setError("Fjapp");
        }

        setLoading(false)
    }

    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                my={[2, 4, 8]}
            >
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']} maxW={'auto'} py={6} px={2}>
                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    <Heading as="h1" fontSize={["lg", "xl", "4xl"]}>Log in</Heading>
                    {error &&
                        <Alert status='error'>
                            <AlertIcon />
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>}
                    <form onSubmit={handleSubmit}>
                        <FormControl py={3} id="email">
                            <FormLabel>Email</FormLabel>
                            <Input type="email" ref={emailRef} required/>
                        </FormControl>
                        <FormControl py={3} id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" ref={passwordRef} required />
                        </FormControl>
                        <FormControl py={3}>
                            <Button colorScheme="brand" disabled={loading} type="submit">
                                Log In
                            </Button>
                        </FormControl>
                    </form>
                    <Text color="brand.100">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </Text>
                    <Text color="brand.100"><Link to="/plans">Sign Up</Link></Text>
                </Stack>
            </Box>
        </Flex>
    )
}