import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Badge,
    Flex,
    Text,
    Icon,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import { RecommendationBadge } from "../ui/RecommendationBadge";
import { Card } from '../ui/Card';
import { CardContent } from '../ui/CardContent';
import { CardHeader } from '../ui/CardHeader';
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { ImArrowRight } from 'react-icons/im';
import { BsArrowsExpand } from "react-icons/bs";
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { SteppedLineTo } from 'react-lineto';
import { useAuth } from "../../context/AuthContext";
import chroma from "chroma-js";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function TransferSuggestions() {

    const { currentUser, currentProfile } = useAuth();
    const [transferSuggestions, setTransferSuggestions] = useState([]);
    const [moneyInTheBank, setMoneyInTheBank] = useState(0);

    // Get the manager ID from firestore
    let fpl_manager_id = currentProfile && currentProfile.fpl_manager_id || '1111498';

    // Get next GW
    //const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next');

    const fpl_picks_url = fpl_manager_id
        && `${process.env.REACT_APP_API_ENDPOINT}fpltm/picks/last?entry=${fpl_manager_id}/`;
    const { status, data, error } = useFetch(fpl_picks_url);

    const { status: overviewStatus, data: overviewData, error: overviewError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`);

    //console.log(data);

    useEffect(() => {
        if (status === 'fetched' && overviewStatus === 'fetched') {

            let money_in_the_bank = data.entry_history.bank;
            //console.log('In the bank: ' + String(money_in_the_bank)); //DEBUG

            // Extend the picks with info from overview_current.json
            let picks_extended = [];
            data['picks'].forEach(pick => {
                //console.log(pick); //DEBUG
                let extended_pick = overviewData.find(element => element['id'] === pick['element']);
                picks_extended.push({ ...pick, ...extended_pick });
            });

            // For every pick, filter out the worst players and sort descending
            let potential_transfers_out = picks_extended
                .filter(pick => ['hold', 'sell', 'strong_sell'].includes(pick.recommendation))
                .sort((a, b) => a.score - b.score) // Order by score, worst players first
                .reverse();

            // Get codes of picked players as an array
            let picked_ids = [];
            data.picks.forEach(pick => picked_ids.push(pick.element));
            //console.log(picked_ids); //DEBUG

            // Find all players NOT picked, and with a recommendation of buy or strong_buy
            let potential_transfers_in = [];
            overviewData.forEach(element => {
                if (!picked_ids.includes(element.id) && ['strong_buy', 'buy'].includes(element.recommendation)) // Players not already picked
                    potential_transfers_in.push(element);
            }); // Consider only buy and strong buy

            //console.log(potential_transfers_in); //DEBUG

            // Iterate through every pick to transfer out and find transfer suggestions
            let transfer_suggestions = [];
            potential_transfers_out.forEach(pick => {
                let suggestions = [];
                potential_transfers_in.forEach(potential_suggestion => {
                    if (pick.now_cost + money_in_the_bank >= potential_suggestion.now_cost
                        && pick.now_cost + money_in_the_bank < potential_suggestion.now_cost + 50 // 0.5m price spread
                        && pick.element_type == potential_suggestion.element_type) //Only direct replacements
                        suggestions.push(potential_suggestion);
                });

                pick['suggestions'] = suggestions.sort((a, b) => a.score - b.score).reverse().slice(0, 4);

                transfer_suggestions.push(pick);
            })

            //console.log(transfer_suggestions.slice(0, 4)); //DEBUG

            setTransferSuggestions(transfer_suggestions);
            setMoneyInTheBank(money_in_the_bank);
        }

    }, [status, overviewStatus]);

    const PlayerCard = ({ player }) => {
        return (
            <Card bg={useColorModeValue('gray.100','gray.600')} width='80%'
                m={['4px', '8px', '12px']}>
                <Stack alignItems='center'>

                    <Image
                        pt={['4px', '8px', '12px']}
                        src={getShirtImg(player.team_code)}
                        width={['25px', '35px', '45px']}
                        fit='scale-down' />
                    <Text fontWeight='bold' fontSize={['sm', 'md', 'lg']}>{player.web_name}</Text>

                    <RecommendationBadge recommendation={player.recommendation} />

                    <HStack fontSize={['xs', 'sm']} pb={['4px', '8px', '12px']}>
                        {player.player_position === 'G' && <Text fontStyle='italic'>GKP</Text>}
                        {player.player_position === 'D' && <Text fontStyle='italic'>DEF</Text>}
                        {player.player_position === 'M' && <Text fontStyle='italic'>MID</Text>}
                        {player.player_position === 'F' && <Text fontStyle='italic'>FWD</Text>}
                        <Text color='gray.500'>/</Text>
                        <Text>{player.team_short_name}</Text>
                        <Text color='gray.500'>/</Text>
                        <Text fontWeight='bold'>£{player.now_cost / 10}m</Text>
                    </HStack>
                </Stack>
            </Card>
        )
    }

    const DisplayTransferSuggestions = transferSuggestions.map(
        (player) => {
            return (
                <Tr key={player.code}>
                    <Td align='left'>
                        <PlayerCard player={player} />
                    </Td>
                    <Td align='left'
                        textOverflow="ellipsis">
                        <>
                            {player.suggestions && player.suggestions.map(suggestion => {
                                return (
                                    <PlayerCard key={suggestion.code} player={suggestion} />
                                )
                            })}
                        </>
                    </Td>

                </Tr>
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>Fixture Highlights</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Processing Transfer Suggestions' />}
            {status === 'fetched' && (
                <>
                    <HStack py={['2px', '4px', '6px']}>
                        <Text>In the bank:</Text>
                        <Text fontWeight='bold'>£{moneyInTheBank / 10}m</Text>
                    </HStack>
                    <Table variant='simple' size='xs' colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th fontSize={['sm', 'md', 'lg']}>Out</Th>
                                <Th fontSize={['sm', 'md', 'lg']}>In</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {DisplayTransferSuggestions}
                        </Tbody>
                    </Table>
                </>
            )}
        </>
    );
}

