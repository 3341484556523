import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Flex,
    Text,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import chroma from "chroma-js";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function TransferActivity() {

    const [horizon, setHorizon] = useState(7); //Horizon in gameweeks
    //Fixture highlights are different for each horizon
    const [transferedInToday, setTransferedInToday] = useState([]);
    const [transferedOutToday, setTransferedOutToday] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode();

    const diffCol =
        chroma.scale(['#BACB2C', '#FFFFFF', '#F55C47'])
            //chroma.scale("Spectral")
            .domain([2, 3, 4])
            .padding(-0.5);

    // ...........
    // Gather data
    const overview_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`;
    const { status, data, error } = useFetch(overview_url);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    const font_color = useColorModeValue('gray.800','gray.200');

    useEffect(() => {
        if (status == 'fetched' && gwStatus === 'fetched') {

            // The data is changed in place, so must create temp objects
            let in_data = data;
            let out_data = data;

            //console.log(data); //DEBUG
            // First get all pl fixtures
            let transfers_in =
                in_data.sort((a, b) => {
                    if (a.transfers_in_event < b.transfers_in_event) {
                        return -1;
                    }
                    if (a.transfers_in_event > b.transfers_in_event) {
                        return 1;
                    }
                    return 0; //They are equal
                })
                    .reverse()
                    .slice(0, 5);

            let transfers_out =
                out_data.sort((a, b) => {
                    if (a.transfers_out_event < b.transfers_out_event) {
                        return -1;
                    }
                    if (a.transfers_out_event > b.transfers_out_event) {
                        return 1;
                    }
                    return 0; //They are equal
                })
                    .reverse()
                    .slice(0, 5);

            //console.log(transfers_out); //DEBUG

            setTransferedInToday(transfers_in);
            setTransferedOutToday(transfers_out);
        }

    }, [horizon, status, gwStatus])

    const DisplayTransferedInToday = data && transferedInToday && transferedInToday.map(
        (player) => {
            return (
                <Tr key={player.web_name}>
                    <Td minWidth={0}>
                        <HStack>
                            <Image src={getShirtImg(player.team_code)} height="20px" />
                            <Link fontSize={['xs','sm']} color="brand.100" as={ReactRouterLink} to={`../player/${player.code}/${player.web_name}`}>{player.web_name}</Link>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isNumeric>
                        <Text>{player.selected_by_percent}%</Text>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isNumeric>
                        {player.transfers_in_event.toLocaleString(undefined)}
                    </Td>
                </Tr>
            )
        }
    )

    const DisplayTransferedOutToday = data && transferedOutToday && transferedOutToday.map(
        (player) => {
            return (
                <Tr key={player.web_name}>
                    <Td minWidth={0}>
                        <HStack>
                            <Image src={getShirtImg(player.team_code)} height="20px" />
                            <Link fontSize={['xs','sm']} color="brand.100" as={ReactRouterLink} to={`../player/${player.code}/${player.web_name}`}>{player.web_name}</Link>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isNumeric>
                        <Text>{player.selected_by_percent}%</Text>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isNumeric>
                        {player.transfers_out_event.toLocaleString(undefined)}
                    </Td>
                </Tr>
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>Transfer Activity</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Loading Transfer Activity' />}
            {status === 'fetched' && (
                <>
                    <Heading py='6px' my='6px' align='left' fontSize={['xs','sm','md']}>Most transfered In this gameweek</Heading>
                    <Table variant='simple' size='sm' colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th fontSize={['xs','sm']}>Player</Th>
                                <Th fontSize={['xs','sm']} isNumeric>Ownership</Th>
                                <Th fontSize={['xs','sm']} isNumeric>Transfers</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {DisplayTransferedInToday}
                        </Tbody>
                    </Table>

                    <Heading py='6px' my='6px' align='left' fontSize={['xs','sm','md']}>Most transfered out this gameweek</Heading>
                    <Table variant='simple' size='sm' colorScheme="brand">
                        <Thead>
                            <Tr>
                                <Th fontSize={['xs','sm']}>Player</Th>
                                <Th fontSize={['xs','sm']} isNumeric>Ownership</Th>
                                <Th fontSize={['xs','sm']} isNumeric>Transfers</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {DisplayTransferedOutToday}
                        </Tbody>
                    </Table>
                </>
               
            )}
        </>
    );
}

