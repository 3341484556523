import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Avatar,
    Box,
    Flex,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import moment from 'moment';
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function News() {

    const { currentUser, currentProfile } = useAuth();

    // ...........
    // Gather data
    const news_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/news`;
    const { status, data, error } = useFetch(news_url, 300); //Refresh every 5 mins

    //const fixtures_url = '/fpltm/fixtures_current.json';
    //const { fixturesStatus, fixturesData, fixturesError } = useFetch(fixtures_url);

    //const overview_url = '/fpltm/overview_current.json';
    //const { overviewStatus, overviewData, overviewError } = useFetch(overview_url)

    //console.log(fpl_entry_url);

    const font_color_news = useColorModeValue('gray.800','gray.200');
    const font_color_time = useColorModeValue('gray.400','gray.500');

    const DisplayNewsItem = data && data.slice(0,10).map(
        (news_item) => {
            return (
                <Tr key={news_item.news_added+'-'+news_item.web_name}>
                    <Td width={{base: '30%', md: '15%'}}>
                        <HStack>
                            <Image src={getShirtImg(news_item.team_code)} height="20px" />
                            <Link fontSize={['xs','sm']} color="brand.100" as={ReactRouterLink} to={`../player/${news_item.code}/${news_item.web_name.replace(/[^\x00-\x7F]/g, "")}`}>{news_item.web_name}</Link>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color_news}
                        overflow="hidden"
                        textOverflow="ellipsis">
                        {news_item.news}
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={font_color_time}
                        minWidth={0}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis">
                        {moment(news_item.news_added).fromNow()}
                    </Td>
                </Tr>
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>News</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Processing Transfer Activity' />}
            {status === 'fetched' && (

                <Table size='xs' colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th fontSize={['xs','sm']}>Player</Th>
                            <Th fontSize={['xs','sm']}>News</Th>
                            <Th fontSize={['xs','sm']}>Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {DisplayNewsItem}
                    </Tbody>
                </Table>

            )}
        </>

    )
}

