import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Container,
    useColorMode
} from "@chakra-ui/react";
import ReactEcharts from "echarts-for-react";
//import * as dfd from "danfojs/src/index";

export default function LeagueTimeline({ history }) {
    
    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode()

    //echarts legend
    let history_legend = history.map(entry => {
        return(entry.entry_name);
    })

    // echarts xAxis data - number of gameweeks
    // history.current should all be same length (number of gameweeks) - use first one
    let history_xAxis = Array(history[0].current.length).fill().map((x,i)=>i+1)

    //console.log(history_legend); //DEBUG

    // echarts series data
    let history_series = history.map(entry => {
        let entry_name = entry.entry_name;
        let total_points = entry.current.map(curr => {
            return(curr.total_points);
        })
        
        return({
            "name": entry_name,
            "type" : "line",
            "data": total_points
        })
    })

    //console.log(history_series); //DEBUG

    return (
        <ReactEcharts
            option={{
                legend: {
                    data: history_legend,
                    bottom: 0
                },
                xAxis: {
                    type: "category",
                    data: history_xAxis
                },
                yAxis: {
                    type: "value"
                },
                series: history_series
                //series: [{
                //    data: [1, 2, 3, 4, 5, 6, 7],
                //    type: "line"
                //}]
            }}
            style={{ height: '500px', width: '100%' }}
            opts={{renderer: 'svg'}} 
            theme={colorMode === "light" ? "light" : "dark"}
        />
    );
}