import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Link,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";

export default function About() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Image
                    src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                    height={["75px", "125px", "175px"]} />
                <Heading as="h1" fontSize={["lg", "xl", "4xl"]} p={["4px", "8px", "16px"]}>
                    About Us
                </Heading>
                <Text width='75%' align='left'>
                    FPL Transfer Market is a data analytics website for fans of the <Link href='http://fantasy.premierleague.com'>Fantasy Premier League</Link>.
                    We help you maximize your points haul and have more fun in the process.
                </Text>
                <Text width='75%' align='left'>
                    FPL Transfer Market is owned and operated by <Link href='https://stakkar.io' isExternal>Stakkar Analytics ehf<ExternalLinkIcon mx='2px' /></Link>. Registration number: 5512003020.
                    {' '}<Link href='https://www.skatturinn.is/fyrirtaekjaskra/leit/kennitala/5512003020' isExternal>Company registry information (in Icelandic)<ExternalLinkIcon mx='2px' /></Link>.
                </Text>
                <Text width='75%' align='left'>
                    Email: <Link href='mailto:info@stakkar.io'>info@stakkar.io</Link>
                </Text>
                <Text width='75%' align='left'>
                    Tel: +354 8466856.
                </Text>
                <Text width='75%' align='left'>
                    Address: <Link href='https://goo.gl/maps/Jhfpboio4w1Gr8vH9' isExternal>Icelandic Ocean Cluster, Grandagarður 16, 101 Reykjavík, Iceland.<ExternalLinkIcon mx='2px' /></Link>
                </Text>
            </VStack>

        </Box>
    );
}