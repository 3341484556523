import React, { useState } from "react"
import { Container, Alert, Button } from "@chakra-ui/react"
import { useAuth } from "../context/AuthContext"
import { Link, useNavigate } from "react-router-dom"

export default function Dashboard() {
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const navigate = useNavigate()

    async function handleLogout() {
        setError("")

        try {
            await logout()
            navigate("/login")
        } catch {
            setError("Failed to log out")
        }
    }

    return (
        <Container maxW="container.md">
            <h2>Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <strong>Email:</strong> {currentUser.email}
            <br/>
            <Link to="/update-profile">
                Update Profile
            </Link>

            <div>
                <Button variant="link" onClick={handleLogout}>
                    Log Out
                </Button>
            </div>
        </Container>
    )
}