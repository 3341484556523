import React from "react";
import ReactDOM from "react-dom";
//import { Helmet } from "react-helmet"; //For Chargebee drop-in script
import App from "./components/App";
import {
  ChakraProvider,
  ColorModeScript,
  LightMode,
  DarkMode
} from "@chakra-ui/react";
import { HashRouter as Router } from "react-router-dom";
import theme from "./theme"
import { AuthProvider } from "./context/AuthContext";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);