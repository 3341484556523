import React from "react";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Image,
    Heading,
    Flex,
    Box,
    Button,
    ButtonGroup,
    Stack,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../assets/img/logo/FPL_Logo_V2_2.png";
import { Route, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useFetch } from "../hooks/fetch";
import { SpinnerWithMessage } from "./ui/SpinnerWithMessage";

export default function RequirePremium({ children }) {
    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    // Check the subscription status in CB systems
    const cb_subscription_url = currentProfile && currentProfile.sub_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/subscription/${currentProfile.sub_id}/status`;
    const { status, data, error } = useFetch(cb_subscription_url);

    //console.log(currentUser);
    //console.log(currentProfile);
    //console.log(status)

    const responsive_bg = useColorModeValue('gray.50', 'gray.800');
    const responsive_box = useColorModeValue('white', 'gray.700');
    const responsive_logo = useColorModeValue(logoTextLightMode, logoTextDarkMode);

    function premiumAccessDenied() {
        return (
            <Flex
                minH={['40vh', '60vh', '80vh']}
                align={'center'}
                justify={'center'}
                bg={responsive_bg}>
                <Box
                    rounded={'lg'}
                    bg={responsive_box}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack align={'center'} spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                        <Stack align={'center'}>
                            <Image
                                src={responsive_logo}
                                height={["75px", "125px", "175px"]}
                                fit='scale-down' />
                            <Heading fontSize={['2xl', '3xl', '4xl']}>Premium Subscription Required</Heading>
                        </Stack>

                        <ButtonGroup spacing="6">
                            <Button
                                onClick={() => navigate('/login')}
                                colorScheme="brand"
                                variant="outline">
                                Log in
                            </Button>
                            <Button
                                onClick={() => navigate('/plans')}
                                colorScheme="brand">
                                Plans & Pricing
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </Box>
            </Flex>
        )
    }

    return (
        <>
            {status === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{error}</AlertTitle>
                </Alert>}
            {status === 'fetching' && <SpinnerWithMessage message='Checking Subscription Status' />}
            {status === 'idle' &&
                premiumAccessDenied()}
            {status === 'fetched' && !['in_trial', 'valid', 'non_renewing', 'active'].includes(data) &&
                premiumAccessDenied()}
            {status === 'fetched' && ['in_trial', 'valid', 'non_renewing', 'active'].includes(data) && children}
        </>
    )
}