import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode,
    useColorModeValue,
    useBreakpointValue,
    useTheme
} from "@chakra-ui/react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import chroma from "chroma-js";
import moment from 'moment';
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { groupBy } from "../../util/groupby";

export default function PointsByGameweek({ team_short_name, element_code, web_name }) {

    const [horizon, setHorizon] = useState(7); //Horizon in gameweeks

    // Echarts state objects
    const [series, setSeries] = useState([]);
    const [yAxis, setYAxis] = useState([]);

    // Color mode and theme
    const { colorMode, toggleColorMode } = useColorMode();
    //const theme = useTheme(); // Best use default colors :-|
    //const colors = theme.colors.echarts;

    // display variant
    const responsive_height = useBreakpointValue({ base: '600px', md: '650px', lg: '700px' });
    const responsive_grid_top = useBreakpointValue({ base: '20%', md: '15%', lg: '10%' });
    console.log('Responsive height: '+responsive_height);
    //const responsive_width_pixels = useBreakpointValue({ base: 'full', sm: '500px', md: '600px', lg: '800px' })

    // ...........
    // Gather data
    const points_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/points_current.json`;
    const { status, data, error } = useFetch(points_url);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    useEffect(() => {
        if (status == 'fetched' && gwStatus === 'fetched') {

            console.log(data.data[0][2]); //DEBUG
            // First get all relevant fixtures
            let points_filtered =
                data.data.filter(fixture => fixture[2] === parseInt(element_code));

            // X-axis point types
            let point_types_x_axis = [
                'Goals', //"goalpts", 
                'Assists', //"assistpts", 
                'Minutes', //"minpts", 
                'Clean sheets',//"cleansheetpts", 
                'Saves', //"savespts", 
                'Penalties', //"penaltysavepts", 
                'Panalty misses', //"penaltymisspts", 
                'Goals conceded', //"goalsconcededpts", 
                'Yellow cards', //"yellowpts", 
                'Red cards', //"redpts", 
                'Own goals', //"owngoalpts"
            ];

            let temp_series = [];

            // Iterate over point types (x-axis)
            // The point types start in place 28 in the vector
            for (let i = 28; i < 38; i++) {
                let series_entry = {};

                series_entry['name'] = point_types_x_axis[i - 28];
                series_entry['type'] = 'bar';
                series_entry['stack'] = 'total';
                series_entry['emphasis'] = {
                    focus: 'series'
                };

                series_entry['data'] = [];

                // Iterate over gameweeks (y-axis)
                // This will populate each series with data
                for (let j = 1; j < gwData['id'] - 1; j++) {

                    // Generate the entries for the chart data
                    let series_entry_value = 0;
                    let series_entry_label = '';
                    let series_entry_tooltip_header = '';
                    let series_entry_tooltip_fixtures = '';

                    // Iterator for tags
                    function* tag() {
                        yield* ['', 'Double gameweek:<br/>', 'Triple gameweek:<br/>', 'Quadruple gameweek:<br/>', 'Insane gameweek:<br/>'];
                    }
                    let tag_iterator = tag();

                    let gameweek_fixtures = points_filtered
                        .filter(fixture => fixture[1] === j); //GW is no 1 in the array

                    // Get the points for the current gameweek
                    series_entry_value = gameweek_fixtures
                        .reduce((sum, next) => sum + next[i], 0);

                    // Create tooltip
                    gameweek_fixtures.forEach(fixture => {
                        series_entry_tooltip_header = tag_iterator.next().value;
                        if (fixture[16]) //home?
                            series_entry_tooltip_fixtures += fixture[14] + '-' + team_short_name
                                + ': ' + point_types_x_axis[i - 28] + ' ' + fixture[i] + ' points<br/>';
                        else
                            series_entry_tooltip_fixtures += team_short_name + '-' + fixture[14]
                                + ': ' + point_types_x_axis[i - 28] + ' ' + fixture[i] + ' points<br/>';
                    }); // Initial value of 0 (if array is empty)

                    // Add a data point with the combined fixture opportunity for all gameweek matches
                    // The tooltip value is used AUTOMATICALLY - I have no idea why this works and cannot find it in the docs
                    series_entry['data'].push(
                        {
                            'value': series_entry_value,
                            'label': "FJAOPP", //String(series_entry_value),
                            'tooltip': series_entry_tooltip_header + series_entry_tooltip_fixtures
                        });

                }

                temp_series.push(series_entry);
            }

            console.log(temp_series['data']); //DEBUG

            setSeries(temp_series);

            // Array of 1..last GW
            setYAxis([...Array(gwData.id - 1).keys()].map(i => i + 1));
        }

    }, [horizon, status, gwStatus])

    return (
        <>
            <ReactEcharts
                option={{
                    //color: colors,
                    backgroundColor: '',
                    textStyle: {
                        fontFamily: 'Roboto Mono, monospace'
                    },
                    tooltip: {
                        trigger: 'item',

                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' 
                        }
                    },
                    legend: {
                        tooltip: {
                            show: true,
                            position: 'left',
                            formatter: function (params) {
                                //console.log(params); //DEBUG
                                return `${params.name}`;
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top: responsive_grid_top,
                        bottom: '8%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        name: 'Points',
                        nameLocation: 'middle',
                        nameGap: 30,
                        splitArea: {
                            show: true
                        }
                    },
                    yAxis: {
                        type: 'category',
                        inverse: true,
                        data: yAxis,
                        name: "Gameweek",
                        nameLocation: 'middle',
                        nameGap: 30,
                        splitArea: {
                            show: true
                        }
                    },
                    series: series,
                }}
                style={{ height: responsive_height, width: '100%' }}
                opts={{ renderer: 'svg' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

