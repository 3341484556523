import React from "react";
import { AuthProvider, useAuth } from "../context/AuthContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import Landing from "./Landing";
import Admin from "./Admin";
import About from "./pages/About";
import Plans from "./pages/Plans";
import TermsOfService from "./pages/TermsOfService";
import FindYourFPLId from "./pages/FindYourFPLId";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundAndCancellationPolicy from "./pages/RefundAndCancellationPolicy";
import Contact from "./pages/Contact";
import League from "./leagues/League";
import LeagueLanding from "./leagues/LeagueLanding";
import Leagues from "./leagues";
import Premium from "./premium";
import DataDownloads from './premium/DataDownloads';
import TechnicalAnalysis from "./premium/TechnicalAnalysis";
import PlayerOverview from "./players-and-teams/PlayerOverview";
import TeamOverview from "./players-and-teams/TeamOverview";
import Dashboard from "./dashboard";
import Login from "./users/Login";
import ThankYou from "./users/ThankYou";
import Signup from "./users/Signup";
import Subscribe from "./users/Subscribe";
import PrivateRoute from "./RequireAuth";
import ForgotPassword from "./users/ForgotPassword";
import UpdateProfile from "./users/UpdateProfile";
import FplLogin from "./users/FplLogin";
import ProfilePicture from "./users/ProfilePicture";
import Beta from "./Beta";
import Header from "./Header";
import Footer from "./Footer";
import SiteLayout from './ui/layouts/SiteLayout';
import ChatLayout from './ui/layouts/ChatLayout';
import PremiumLayout from './ui/layouts/PremiumLayout';
import RequireAuth from "./RequireAuth";
import RequirePremium from "./RequirePremium";
import FourHundredAndFour from "./pages/404";

function App() {

  // Initialize Chargebee. The js is loaded in index.html
  //window.Chargebee.init({
  //  site: "fpltm-test",
  //  publishableKey: "test_gsJU44ggTTpgMUd9J7O6fAA1Bt67V6nI"
  //})

  //const navigate = useNavigate();

  window.Chargebee.init({
    site: "fpltm",
    publishableKey: "live_TJcuYXxANDQIm9VILeezfvMxyEfdcucu5Ja"
  })

  return (
    <>
      <Router>
        <AuthProvider>
          {/*<Beta />*/}
          <Routes>
            <Route element={<SiteLayout />} >
              <Route path="/about" element={<About />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path='/find-your-fpl-id' element={<FindYourFPLId />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/refund-and-cancellation-policy" element={<RefundAndCancellationPolicy />} />
              {/*<Route path="/league/:league_id" element={<League />} />*/}
              <Route path="/player/:element_code/:web_name" element={<PlayerOverview />} />
              <Route path="/team/:team_short_name" element={<TeamOverview />} />
              <Route path="/landing" element={<Landing />} />
              <Route path="/league-landing" element={<LeagueLanding />} />
              <Route path="/login" element={<Login />} />
              {/*<Route path="/thank-you/:sub_id/:invoice_id" element={<ThankYou />} />*/}
              <Route path="/thank-you/:sub_id" element={<ThankYou />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/admin" element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              } />
              <Route path="/update-profile" element={
                <RequireAuth>
                  <UpdateProfile />
                </RequireAuth>
              } />
              <Route path="/fpl-login" element={
                <RequireAuth>
                  <FplLogin />
                </RequireAuth>
              } />
              <Route path="/profile-picture" element={
                <RequireAuth>
                  <ProfilePicture />
                </RequireAuth>
              } />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/leagues" element={
                <RequireAuth fallback='/league-landing'>
                  <Leagues />
                </RequireAuth>
              } />
              <Route path="/" element={
                <Dashboard />
              } />
            </Route>

            <Route element={<ChatLayout />} >
              <Route path="/league/:league_id" element={<League />} />
            </Route>

            <Route element={<PremiumLayout />} >
              <Route path="/premium" element={
                <RequirePremium>
                  <Premium />
                </RequirePremium>
              } />
              {<Route path="/premium/technical-analysis" element={
                <RequirePremium>
                  <TechnicalAnalysis />
                </RequirePremium>
              } />}
              <Route path="/premium/data-downloads" element={
                <RequirePremium>
                  <DataDownloads />
                </RequirePremium>
              } />
            </Route>

            <Route path="*" element={<FourHundredAndFour />} />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App