import React, { useEffect, useState } from "react";
import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Stack,
    HStack,
    Text,
    TableCaption,
    Container
} from "@chakra-ui/react"

export default function LeagueStandings({ standings, entry_rank }) {
    //console.log(entry_rank); //DEBUG
    const DisplayEntries = standings.results.map(
        (entry) => {
            //console.log(entry.rank); //DEBUG
            return (
                <Tr key={entry.entry_name} bg={entry.rank == entry_rank ? 'brand.100' : ''}>
                    <Td>
                        <Stack>
                            <Text fontSize={['sm', 'md', 'lg']}>{entry.entry_name}</Text>
                            <Text fontSize={['xs', 'sm', 'md']} color={'gray.500'}>{entry.player_name}</Text>
                        </Stack>
                    </Td>
                    <Td>{entry.event_total}</Td>
                </Tr>
            )
        }
    )

    // .................................................
    useEffect(() => {
        //return(<pre>{JSON.stringify(standings.results)}</pre>)

        // All players live:
        // https://fantasy.premierleague.com/api/event/31/live/

        // Teams:
        // https://fantasy.premierleague.com/api/entry/1111498/event/31/picks/
    }, [])

    return (
        <Table size={'sm'} colorScheme="brand">
            <Thead>
                <Tr>
                    <Th>Entry/Player</Th>
                    <Th>GW</Th>
                </Tr>
            </Thead>
            <Tbody>
                {DisplayEntries}
            </Tbody>
        </Table>
    )
}