import {
    Flex,
    Stack,
    Spinner,
    Text
} from '@chakra-ui/react';
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrowsExpand } from "react-icons/bs";
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import * as React from 'react';

export const SpinnerWithMessage = ({ message }) => {
    //const { title, action, children } = props;
    //const { isOpen, onOpen, onClose } = useDisclosure();
    //console.log(recommendation); //DEBUG
    return (
        <Stack mt={['8px', '16px', '32px']} align='center' space={['4px', '8px', '16px']} >
            <Spinner
                thickness="6px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.100"
                size="xl"
            />
            <Text fontSize={['xs', 'sm', 'md']}>{message}</Text>
        </Stack>
    )
}