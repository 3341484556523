import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { BsArrowRight } from 'react-icons/bs'
import { RiUser3Line, RiUser3Fill } from 'react-icons/ri'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Avatar,
    Button,
    ButtonGroup,
    Box,
    Flex,
    Image,
    Text,
    HStack,
    FormControl,
    Input,
    Spacer,
    Stack,
    Spinner
} from "@chakra-ui/react";
import moment from 'moment';
import { useAuth } from "../../context/AuthContext";
import { storage, db } from "../../config/firebase";
import { ref } from "firebase/storage";
import { doc } from "firebase/firestore";
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { SpinnerWithMessage } from '../ui/SpinnerWithMessage';

// Represents a group of messages sent consecutively by the same user within a timespan
function ChatMessageGroup(props) {
    //const uid = props.uid;
    const message_group = props.message_group; // Array of messages sent consecutively by this user
    //const last_msg_time = props.last_msg_time; // Send time of the last message in this group

    // Chat context
    //const {
    //    getProfile,
    //    getProfileImgUrl,
    //    getGroupedMessages,
    //    loading,
    //    error
    //} = useChat();

    const {
        currentUser,
        currentProfile,
        currentProfileImgUrl,
    } = useAuth();

    // Profile images for non-logged in users
    const [urlValue, urlLoading, urlError] = useDownloadURL(
        ref(storage, message_group.uid + "/profileimg")
    );

    // User profile for non-logged in users
    const [profileValue, profileLoading, profileError] = useDocumentDataOnce(
        doc(db, 'fpltm-profiles', message_group.uid)
    );

    // Is this message from the logged in user (received), or someone else (sent)
    //const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

    if (message_group.uid === currentUser.uid) {
        // This is a message SENT by the logged in user
        return (
            <HStack 
                px={['2px', '4px', '8px']}
                align='top'>
                {currentProfileImgUrl &&
                    <Avatar size={'sm'} bg="#ffffff" src={currentProfileImgUrl} /> ||
                    currentProfile && currentProfile.name &&
                    <Avatar size={'sm'} name={currentProfile.name} /> || <Avatar size={'sm'} />}
                <Stack spacing='0px' align='left'>
                    {currentProfile && currentProfile.name &&
                        <Text fontSize={['sm', 'md']} align='left' colour="brand.gray">{currentProfile.name}</Text> ||
                        <Text fontSize={['sm', 'md']} align='left' colour="brand.gray">{currentUser.email.split("@")[0]}</Text>}
                    <Box
                        as="button"
                        borderRadius="xl"
                        bg="brand.100"
                        color="#E6F4F1"
                        px={['2px','4px','8px']}
                        py='0px'
                        alignContent="start">
                        <Stack>
                            {message_group.messages.map(msg =>
                                <Text fontSize={['sm', 'md']} align='left' key={msg.createdAt}>
                                    {msg.content}
                                </Text>)}
                        </Stack>
                    </Box>
                    <Text align='right' fontSize='xs' color='gray.500'>
                        {moment(message_group.latestCreatedAt).fromNow()}
                    </Text>
                </Stack>
            </HStack>
        )
    } else {
        // This is a message RECEIVED, from another (not logged in) user
        return (
            <HStack 
                px={['2px', '4px', '8px']}
                align='top'>
                {urlLoading || profileLoading && <SpinnerWithMessage message='Loading Chat Messages' />}
                {(!urlLoading && !profileLoading) && urlError && profileError && <Avatar size={'sm'} />}
                {!urlLoading && urlValue && <Avatar size={'sm'} bg="#ffffff" src={urlValue} />}
                {urlError && !profileLoading && profileValue &&
                    <Avatar size={'sm'} name={profileValue.name} />}

                {urlError && (profileError || !profileValue || !profileValue.name) && <Avatar size={'sm'} />}
                <Stack spacing='0px' align='left'>
                    {(profileValue && profileValue.name &&
                        <Text fontSize={['sm', 'md']} align='left' colour="brand.gray">{profileValue.name}</Text>) ||
                        <Text fontSize={['sm', 'md']} align='left' colour="brand.gray">{currentUser.email.split("@")[0]}</Text>}
                    <Box
                        as="button"
                        borderRadius="xl"
                        bg="brand.gray"
                        color="#E6F4F1"
                        px={['2px','4px','8px']}
                        mt={['2px','4px','8px']}
                        mb='0'
                        alignContent="start">
                        {message_group.messages.map(msg =>
                            <Text key={msg.createdAt} fontSize={['sm', 'md']} align='left'>
                                {msg.content}
                            </Text>
                        )}
                    </Box>
                    <Text mt='0' align='right' fontSize={'xs'} color='gray.500'>
                        {moment(message_group.latestCreatedAt).fromNow()}
                    </Text>
                </Stack>
            </HStack>
        )
    }
}

export default ChatMessageGroup;