import React, { useContext, useState, useEffect } from "react"
import {
    getAuth,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    //resetPassword,
    updateEmail,
    updatePassword
} from "firebase/auth";
import { app, auth, db, analytics, storage } from "../config/firebase";
import {
    query, orderBy, limit, getDoc,
    collection, addDoc, getDocs,
    doc, setDoc,
    getDocsFromServer,
    serverTimestamp
} from "firebase/firestore";
import { useFetch } from "../hooks/fetch";
import { ref, getDownloadURL } from "firebase/storage";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [currentProfile, setCurrentProfile] = useState();
    const [currentProfileImgUrl, setCurrentProfileImgUrl] = useState('');
    const [currentEntry, setCurrentEntry] = useState({});
    const [nextGameweek, setNextGameweek] = useState({});
    const [loading, setLoading] = useState(true);

    // Entry info for context
    let fpl_manager_id = currentProfile && currentProfile.fpl_manager_id;
    const fpl_entry_url = fpl_manager_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/entry?fpl_manager_id=${fpl_manager_id}`;
    const { status: entryStatus, data: entryData, error: entryError }
        = useFetch(fpl_entry_url);

    // Get next GW for the context
    //const { status: gwStatus, data: gwData, error: gwError }
    //    = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    // ........................................................................
    function signup(name, email, password) {
        //console.log(name + ' ' + email + ' ' + password); //DEBUG
        //return auth.createUserWithEmailAndPassword(email, password);

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                setCurrentUser(user);

                console.log("Signed in..."); //DEBUG
                console.log(user.uid); //DEBUG

                // Add the users name to the profile in firestore
                const profileRef = doc(db, 'fpltm-profiles', user.uid);
                setDoc(profileRef, { name: name, sub_level: 'community' }, { merge: true })
                    .then(() => {
                        // Get the user's profile from firebase            
                        const profileRef = doc(db, 'fpltm-profiles', user.uid);
                        const profile = getDoc(profileRef);

                        console.log("Updating profile in signup"); //DEBUG
                        //console.log(profile); //DEBUG

                        // Add the user's profile to state
                        setCurrentProfile(profile);
                    })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log(error.code + ' ' + error.message);

                //return(error.code + ' ' + error.message);
            })

    }

    // ........................................................................
    function signupWithSubscription(name, email, password, sub_id, sub_level) {
        //console.log(name + ' ' + email + ' ' + password); //DEBUG
        //return auth.createUserWithEmailAndPassword(email, password);

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                setCurrentUser(user);

                console.log("Signed in..."); //DEBUG
                console.log(user.uid); //DEBUG

                // Add the users name to the profile in firestore
                const profileRef = doc(db, 'fpltm-profiles', user.uid);
                setDoc(profileRef, { name: name, sub_id: sub_id, sub_level: sub_level}, { merge: true })
                    .then(() => {
                        // Get the user's profile from firebase            
                        const profileRef = doc(db, 'fpltm-profiles', user.uid);
                        const profile = getDoc(profileRef);

                        console.log("Updating profile in signup"); //DEBUG
                        //console.log(profile); //DEBUG

                        // Add the user's profile to state
                        setCurrentProfile(profile);
                    })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log(error.code + ' ' + error.message);

                //return(error.code + ' ' + error.message);
            })

    }

    // ........................................................................
    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                setCurrentUser(user);

                // Get the user's profile from firebase            
                const profileRef = doc(db, 'fpltm-profiles', user.uid);
                getDoc(profileRef).then((profileDoc) => {
                    //console.log("Login...");
                    console.log(profileDoc.data()); //DEBUG

                    // Add the user's profile to state
                    setCurrentProfile(profileDoc.data());
                });

                // Update the profile image url
                updateProfileImgUrl(user.uid);
            })
            .catch((error) => {
                console.log(error.code + ' ' + error.message);
                throw (error.code);
            });
    }

    // ........................................................................
    function logout() {
        return signOut(auth);

        //Make sure old user info is not left hanging
        //setCurrentUser(undefined);
        //setCurrentProfile(undefined);

    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function updateEmailInFirebase(email) {
        return updateEmail(currentUser, email);
    }

    function updatePasswordInFirebase(password) {
        return updatePassword(currentUser, password);
    }

    // ........................................................................
    // Should not be confused with the updateProfile function in the Firebase API
    function updateProfileInDb(profile_obj) {
        //console.log(profile_obj); //DEBUG
        const profileRef = doc(db, 'fpltm-profiles', currentUser.uid);
        setDoc(profileRef, profile_obj, { merge: true })
            .then(() => {
                // Get the user's updated profile from firebase and update state       
                const profileRef = doc(db, 'fpltm-profiles', currentUser.uid);
                getDoc(profileRef).then((profileDoc) => setCurrentProfile(profileDoc.data()));
            })
            .catch((error) => console.error("Error in updateProfileInDb: ", error));
    }

    // ........................................................................
    // Get the profile image url and store it in the context
    function updateProfileImgUrl(uid) {
        const path = uid + '/profileimg';
        const storageRef = ref(storage, path);

        //console.log("In updateProfileImgUrl..."); //DEBUG

        // In case of logging out of one profile and into another, 
        // make sure last user's profile image does not carry over
        setCurrentProfileImgUrl('');

        // Get the download URL
        getDownloadURL(storageRef)
            .then((url) => {
                //console.log(url); //DEBUG
                setCurrentProfileImgUrl(url);
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log(error.code); //DEBUG
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
            });
    }

    function getSubscriptionLevel() {
        // sub_id should only exist with subscribers
        // TODO: remove sub_id when subscription runs out.

        if(!currentUser) {
            return {};
        }

        return currentProfile && currentProfile.sub_level !== undefined ? currentProfile.sub_level : {};
    }

    // ........................................................................
    useEffect(() => {
        console.log("Rerendering context..."); //DEBUG

        setCurrentEntry(entryData);
        //setNextGameweek(gwData);

        //console.log(entryData); //DEBUG
        //console.log(gwData); //DEBUG

        // ...........................................................
        // onAuthStateChanged returns a function to unsubscribe itself
        const unsubscribe = onAuthStateChanged(auth, user => {

            // Add the user object to state
            setCurrentUser(user);

            if(user) {
                // Get the user's profile from firebase and update state       
                const profileRef = doc(db, 'fpltm-profiles', user.uid);
                getDoc(profileRef).then((profileDoc) => setCurrentProfile(profileDoc.data()));

                // Update the image URL in the context
                updateProfileImgUrl(user.uid);
            }

            setLoading(false);
        }, [entryData]);

        // The unsubscribe function is returned as the useEffect cleanup function
        // and will be run upon unmounting of the component
        return unsubscribe;
    }, [entryData]);

    // This will be provided in the context
    const value = {
        currentUser,
        currentProfile,
        currentProfileImgUrl,
        currentEntry,
        //nextGameweek,
        login,
        signup,
        signupWithSubscription,
        updateProfileInDb,
        updateProfileImgUrl,
        logout,
        resetPassword,
        updateEmailInFirebase,
        updatePasswordInFirebase,
        getSubscriptionLevel
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}