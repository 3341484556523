import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    ButtonGroup,
    Box,
    Flex,
    Stack,
    Heading,
    Link,
    HStack,
    Spinner,
    SimpleGrid,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    useColorModeValue
} from "@chakra-ui/react";
import Header from "../Header";
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import { useNavigate } from "react-router-dom";
import LeagueStandings from "./LeagueStandings";
import LeagueTimeline from "./LeagueTimeline";
import Chat from "../chat"
import { useFetch } from "../../hooks/fetch";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function Leagues() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    let fpl_manager_id = currentProfile && currentProfile.fpl_manager_id; // || 'q';
    const fpl_entry_url = fpl_manager_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/entry?fpl_manager_id=${fpl_manager_id}`;
    //const fpl_entry_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/entry?fpl_manager_id=1111498';
    const { status, data, error } = useFetch(fpl_entry_url);

    const DisplayLeagues = data.leagues && data.leagues.classic.map(
        (entry) => {
            return (
                entry.league_type === "x" &&
                <Tr key={entry.id}>
                    <Td><Link color="brand.100" as={ReactRouterLink} to={`/league/${entry.id}`}>{entry.name}</Link></Td>
                    <Td>{entry.entry_last_rank}</Td>
                    <Td>
                        {entry.entry_rank < entry.entry_last_rank &&
                            <HStack>
                                <Text fontSize={['sm', 'md', 'lg']} color='brand.400'>
                                    {entry.entry_rank}
                                </Text>
                                <Text fontSize={['xs', 'sm']} color='brand.400'>
                                    (+{Math.abs(entry.entry_rank - entry.entry_last_rank)})
                                </Text>
                            </HStack>}
                        {entry.entry_rank > entry.entry_last_rank &&
                            <HStack>
                                <Text fontSize={['sm', 'md', 'lg']} color='brand.600'>
                                    {entry.entry_rank}
                                </Text>
                                <Text fontSize={['xs', 'sm']} color='brand.600'>
                                    (-{Math.abs(entry.entry_rank - entry.entry_last_rank)})
                                </Text>
                            </HStack>}
                        {entry.entry_rank === entry.entry_last_rank &&
                            <>
                                <Text fontSize={['sm', 'md', 'lg']} >
                                    {entry.entry_rank}
                                </Text>
                            </>}
                    </Td>
                </Tr>
            )
        }
    )

    //console.log(status); //DEBUG
    //console.log(currentUser); //DEBUG
    //console.log(currentProfile); //DEBUG
    //console.log(fpl_manager_id); //DEBUG

    /*useEffect(() => {

    }, []);*/

    return (
        <>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                {status === 'idle' && (
                    <div>{fpl_manager_id}</div>
                )}
                {status === 'error' &&
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>{error}</AlertTitle>
                    </Alert>}
                {status === 'fetching' && <SpinnerWithMessage message='Loading League List' />}
                {status === 'fetched' && !fpl_manager_id &&
                    <Alert status="info"
                        variant="subtle"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center">
                        <AlertIcon boxSize="40px" mt={6} />
                        <AlertTitle m={6} fontSize={['sm', 'md', 'lg']}>
                            Please provide your FPL login details to see your leagues...
                        </AlertTitle>
                        <AlertDescription maxWidth="sm" mb={6}>
                            <Button colorScheme="brand"
                                onClick={() => navigate('/fpl-login')}
                            >
                                Edit FPL Profile
                            </Button>
                        </AlertDescription>
                    </Alert>
                }
                {status === 'fetched' && fpl_manager_id && //currentUser && currentProfile &&
                    <Card>
                        <Stack p={['8px','16px','24px']} spacing={['8px','16px','24px']}>
                            <Heading fontSize={['xl','2xl','4xl']}>{data.name}</Heading>
                            <Heading fontSize={['lg','xl','2xl']}>{data.player_first_name + " " + data.player_last_name}</Heading>
                            <Table colorScheme="brand">
                                <Thead>
                                    <Tr>
                                        <Th>League</Th>
                                        <Th>Last Rank</Th>
                                        <Th>Current Rank</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {DisplayLeagues}
                                </Tbody>
                            </Table>
                        </Stack>
                    </Card>
                }
            </SimpleGrid>
        </>
    )
}

