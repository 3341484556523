import React, { useRef, useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    Box,
    Flex,
    Text,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { CardComponent, CardNumber, CardExpiry, CardCVV } from "@chargebee/chargebee-js-react-wrapper";


export default function Subscribe() {

    const { currentUser, currentProfile } = useAuth();
    const [token, setToken] = useState();
    const [status, setStatus] = useState({ loading: false, error: "" });
    const [name, setName] = useState({ first: "", last: "" });
    const cardRef = useRef(null);

    let state = {
        //token: "",
        //error: "",
        //loading: false,
        //firstName: "",
        options: {
            // Custom classes - applied on container elements based on field's state
            classes: {
                focus: 'focus',
                invalid: 'invalid',
                empty: 'empty',
                complete: 'complete',
            },

            style: {
                // Styles for default field state
                base: {
                    color: '#333',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    ':focus': {
                        color: '#424770',
                    },

                    '::placeholder': {
                        color: 'transparent',
                    },

                    ':focus::placeholder': {
                        color: '#7b808c',
                    },
                },

                // Styles for invalid field state
                invalid: {
                    color: '#e41029',

                    ':focus': {
                        color: '#e44d5f',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            },

            // locale
            locale: 'en',

            // Custom placeholders
            placeholder: {
                number: "4111 1111 1111 1111",
                expiry: "MM / YY",
                cvv: "CVV"
            },

            // Custom fonts
            fonts: [
                'https://fonts.googleapis.com/css?family=Roboto:300,500,600'
            ]
        },
    }
    //this.handleChange = this.handleChange.bind(this);
    //this.tokenize = this.tokenize.bind(this);

    let tokenize = () => {
        setStatus({ loading: true });

        // Call tokenize methods through  card component's ref
        cardRef.current.tokenize({}).then((data) => {
            setStatus({ loading: false, token: data.token, error: "" });
        }).catch((error) => {
            setStatus({ loading: false, token: "", error: "Problem while tokenizing your card details" });
        });
    }

    let handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setStatus({
            [name]: value
        });
    }

    /*
    useEffect(() => {
        const el = document.createElement('script');
        el.onload = () => {
            window.Chargebee.init({
                "site": "fpltm-test"
            });
            window.Chargebee.registerAgain();
            // this.setState({ chargebeeReady: true });
        };
        el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
        document.body.appendChild(el);
    });
    */

    return (

        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={[2, 4, 8]}>
                {!currentUser &&
                    <Alert status="error">
                        <AlertIcon />
                        <AlertTitle mr={2}>Forward to email collection screen</AlertTitle>
                    </Alert>}
                {currentUser &&
                    <>
                        <div className="ex1-field">
                            <input name="firstName" className={name.first ? "ex1-input val" : "ex1-input"} type="text" placeholder="John Doe" value={name.first} onChange={handleChange} />
                            <label className="ex1-label">Name on Card</label><i className="ex1-bar"></i>
                        </div>

                        <CardComponent ref={cardRef} className="fieldset field"
                            styles={state.options.style}
                            classes={state.options.classes}
                            locale={state.options.locale}
                            placeholder={state.options.placeholder}
                            fonts={state.options.fonts}>
                            <div className="ex1-field">
                                {/* Card number component */}
                                <CardNumber className="ex1-input" />
                                <label className="ex1-label">Card Number</label><i className="ex1-bar"></i>
                            </div>

                            <div className="ex1-fields">
                                <div className="ex1-field">
                                    {/* Card expiry component */}
                                    <CardExpiry className="ex1-input" />
                                    <label className="ex1-label">Expiry</label><i className="ex1-bar"></i>
                                </div>

                                <div className="ex1-field">
                                    {/* Card cvv component */}
                                    <CardCVV className="ex1-input" />
                                    <label className="ex1-label">CVC</label><i className="ex1-bar"></i>
                                </div>

                            </div>
                        </CardComponent>
                    </>
                }
                <button type="submit" className={status.loading ? "submit ex1-button" : "ex1-button"} onClick={tokenize}>Pay $x & Tokenize</button>
                {status.error && <div className="error" role="alert">{status.error}</div>}
                {status.token && <div className="token" >{status.token}</div>}

            </Box>
        </Flex>
    );
}