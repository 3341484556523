import React, { useRef, useState, useEffect } from "react";
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Image,
    Link,
    Spinner,
    Flex,
    Stack,
    Heading,
    Input,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useFetch } from "../../hooks/fetch";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useAuth } from "../../context/AuthContext";
import { app, auth, db, analytics } from "../../config/firebase";
import { Link as ReactRouterLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

// ............................................................................
// Captures flow from the Chargebee drop-in checkout component
// Flow has three possibilities:
//
// CASE 1: User has a profile and is logged in:
//  => Check for {currentUser}
//
// CASE 2: User does not have a profile and is (therefore) not logged in:
//  => Check for absence of login through {!currentUser}
//  THIS MAY BE INCORRECT - SEE 3...
//
// CASE 3: User has a profile BUT is NOT logged in
//  => Display message to contact support to filter this out
//  TODO: Handle this somehow automatically

export default function ThankYou() {
    // There is no invoice ID in the url in the case of trial
    const { sub_id } = useParams(); //Subscription id from CB
    const { currentUser, currentProfile, signupWithSubscription, updateProfileInDb } = useAuth();

    const [customer, setCustomer] = useState([]); //The CB customer object
    const [subscription, setSubscription] = useState([]); //The CB subscription object

    const emailRef = useRef();
    const nameRef = useRef();
    //const subRef = useRef(sub_id);
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const cb_customer_url = sub_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/customer/${sub_id}`;
    const { status: cbStatus, data: cbData, error: cbError } = useFetch(cb_customer_url);

    // Fetch the subscription level from the web service
    const sub_level_url  = sub_id && `${process.env.REACT_APP_API_ENDPOINT}fpltm/subscription/${sub_id}/item_price_ids`;
    const { status: subLevelStatus, data: subLevelData, error: subLevelError } = useFetch(sub_level_url);
   
    
    // ........................................................................
    // CASE 1
    // The user already exists => Store the subscription ID in the profile immediately
    // This is handled in useEffect


    // ........................................................................
    // CASE 2 submit handler
    // Store the subscription ID in the profile upon creation of the user
    async function handleSubmitNewUser(e) {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match");
        }

        try {
            setError("");
            setLoading(true);

            // Create the user in Firebase and add the subscription id to the profile
            signupWithSubscription(nameRef.current.value, cbData.email, passwordRef.current.value, sub_id, subLevelData[0])
                //.updateProfileInDb({ sub_id: sub_id }) // Store the subscription ID in the profile
                .then(() => {
                    navigate("/profile-picture?flow=signup"); //Move to the profile page
                });

        } catch {
            setError("Failed to create an account.");
        }

        setLoading(false);
    }



    // ........................................................................
    useEffect(() => {

        console.log("In useEffect..."); //DEBUG
        console.log(cbData.email); //DEBUG
        console.log(subLevelData); //DEBUG

        // If the user is logged in, we only need to store the sub_id in his profile
        // and we are all set
        if (currentUser) {
            console.log(currentUser.email);
            try {
                setError("");

                // Store the subscription ID in the user's profile
                updateProfileInDb({ sub_id: sub_id, sub_level: subLevelData[0] });

            } catch {
                setError("Failed to store subscription ID in profile.");
            }
        }

        emailRef.current = cbData.email;

    }, [subLevelStatus]);

    // ........................................................................
    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={[2, 4, 8]}>
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']} maxW={['xs', 'sm', 'md']} py={6} px={2}>
                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    <Heading as="h1" fontSize={["lg", "xl", "4xl"]} p={["4px", "8px", "16px"]}>
                        Thank you for subscribing
                    </Heading>
                    <Text fontSize={["xs", "sm", "md"]} px={["4px", "8px", "16px"]}>
                        You will receive a welcome email in your inbox shortly.
                    </Text>
                    {cbStatus === 'idle' && (
                        <div>Idle thank you page. Please contact support@fpltransfermarket.com for assistance.</div>
                    )}
                    {cbStatus === 'error' &&
                        <Alert
                            status='error'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'>
                            <AlertIcon />
                            <AlertTitle>{cbError}</AlertTitle>
                            <AlertDescription>There was a problem initiating your subscription: CB Error. Please contact support@fpltransfermarket.com for assistance.</AlertDescription>
                        </Alert>}
                    {(cbStatus === 'fetching' || subLevelStatus === 'fetching') && <SpinnerWithMessage message='Processing Subscription' />}
                    {subLevelStatus === 'error' &&
                        <Alert
                            status='error'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'>
                            <AlertIcon />
                            <AlertTitle>{subLevelError}</AlertTitle>
                            <AlertDescription>There was a problem initiating your subscription: Sub Level Error. Please contact support@fpltransfermarket.com for assistance.</AlertDescription>
                        </Alert>}
                    {error &&
                        <Alert
                            status='error'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'>
                            <AlertIcon />
                            <AlertTitle>{error}</AlertTitle>
                            <AlertDescription>There was a problem initiating your subscription: General error. Please contact support@fpltransfermarket.com for assistance.</AlertDescription>
                        </Alert>}
                    {!currentUser && cbStatus == 'fetched' &&
                        <>
                            <Heading as="h3" fontSize={["sm", "md", "2xl"]} p={["4px", "8px", "16px"]}>
                                Let's finish setting up your profile...
                            </Heading>
                            <form onSubmit={handleSubmitNewUser}>
                                <FormControl id="name">
                                    <FormLabel>Name</FormLabel>
                                    <Input type="text" colorScheme="brand" ref={nameRef} required />
                                </FormControl>
                                <FormControl id="email" mt={4}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        type="email"
                                        colorScheme="brand"
                                        value={cbData.email}
                                        ref={emailRef}
                                        isDisabled={true} />
                                </FormControl>
                                <FormControl id="password" mt={4}>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" colorScheme="brand" ref={passwordRef} required />
                                </FormControl>
                                <FormControl id="password-confirm" mt={4}>
                                    <FormLabel>Password Confirmation</FormLabel>
                                    <Input type="password" colorScheme="brand" ref={passwordConfirmRef} required />
                                </FormControl>
                                <FormControl mt={4}>
                                    <Button
                                        disabled={loading} rightIcon={<AiOutlineArrowRight />}
                                        colorScheme="brand" className="w-100" type="submit">
                                        Next, let's create your profile pic
                                    </Button>
                                </FormControl>
                            </form>
                            <Text fontSize={["xs", "sm", "sm"]} p={["2px", "4px", "8px"]}>
                                If you already created a profile using a different email address, please
                                {' '}<Link color="brand.100" href="mailto:support@fpltransfermarket.com">contact support</Link>.
                            </Text>
                        </>}
                    {currentUser && !error &&
                        <>
                            <FormControl mt={4}>
                                {currentProfile && currentProfile.fpl_user ?
                                    <>
                                        <Text fontSize={["xs", "sm", "md"]} px={["4px", "8px", "16px"]}>
                                            You're all set to start enjoying the premium features.
                                        </Text>
                                        <Button
                                            mx={["4px", "8px", "16px"]}
                                            my={["4px", "8px", "16px"]}
                                            onClick={() => navigate('/premium')}
                                            variant="solid"
                                            colorScheme="brand"
                                            rightIcon={<AiOutlineArrowRight />} type="button">
                                            Got to Premium now
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Text fontSize={["xs", "sm", "md"]} px={["4px", "8px", "16px"]}>
                                            To make the most of the Premium features, you need to add your FPL team details...
                                        </Text>
                                        <Button
                                            mx={["4px", "8px", "16px"]}
                                            my={["4px", "8px", "16px"]}
                                            onClick={() => navigate('/fpl-login')}
                                            variant="solid"
                                            colorScheme="brand"
                                            rightIcon={<AiOutlineArrowRight />} type="button">
                                            Add your FPL details
                                        </Button>
                                    </>}
                            </FormControl>
                        </>
                    }


                </Stack>
            </Box>
        </Flex>
    )

}