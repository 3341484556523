import React from "react";
import { Route, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function RequireAuth({ fallback, children }) {
    const { currentUser } = useAuth();

    //console.log(currentUser);
    //console.log(currentProfile);
    //console.log(currentEntry);
    //console.log(fallback);

    return currentUser ? children : <Navigate to={fallback ? fallback : '/landing'} />
    
}