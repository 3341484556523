import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode,
    useColorModeValue
} from "@chakra-ui/react";
import chroma from "chroma-js";
import moment from 'moment';
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";

export default function DeltaBar({ team_short_name }) {


    const [horizon, setHorizon] = useState(7); //Horizon in gameweeks
    //Fixture highlights are different for each horizon
    const [fixturelistFiltered, setFixturelistFiltered] = useState([]);
    const [attackDelta, setAttackDelta] = useState([]);
    const [defenceDelta, setDefenceDelta] = useState([]);
    const [fixtureList, setFixtureList] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode();

    const diffCol =
        chroma.scale(['#BACB2C', '#FFFFFF', '#F55C47'])
            //chroma.scale("Spectral")
            .domain([2, 3.5, 5]);
    //.padding(-0.5);

    const restCol =
        chroma.scale(['#F55C47ff', '#FFFFFF00', '#FFFFFF00'])
            //chroma.scale("Spectral")
            .domain([2, 5, 15])
            .nodata('#FFFFFF00');

    // ...........
    // Gather data
    const fixturelist_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/fixturelist_extended.json`;
    const { status, data, error } = useFetch(fixturelist_url);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    useEffect(() => {
        if (status == 'fetched' && gwStatus === 'fetched') {

            //.filter(fixture => fixture['gameweek'] >= gwData['id'] || !fixture['gameweek'])
            //.filter(fixture => fixture['gameweek'] < gwData['id']+horizon || fixture['gameweek'])

            let fixtures_filtered = data.filter(fixture => fixture['short_name'] === team_short_name)
                .filter(fixture => fixture.competition === 'Premier League')
                .sort((a, b) => a.gameweek - b.gameweek)
                .reverse();

            let attack_delta =
                fixtures_filtered.map(fixture => fixture.attack_delta);

            let defence_delta =
                fixtures_filtered.map(fixture => fixture.defense_delta);

            let fixture_list =
                fixtures_filtered.map(fixture => fixture.opponent_name);

            setFixturelistFiltered(fixtures_filtered);
            setAttackDelta(attack_delta);
            setDefenceDelta(defence_delta);
            setFixtureList(fixture_list);
        }

    }, [horizon, status, gwStatus])

    return (
        <>
            <ReactEcharts
                option={{
                    backgroundColor: '',
                    textStyle: {
                        fontFamily: 'Roboto Mono, monospace'
                    },
                    grid: {

                        top: '10%',
                        left: '25%',
                        right: '5%',
                        bottom: '10%'
                    },
                    xAxis: {
                        type: 'value',
                        name: 'Delta',
                        nameLocation: 'middle',
                        nameGap: 30,
                        axisPointer: {
                            type: 'shadow'
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    },
                    legend: {
                        data: ['Attack Delta', 'Defense Delta']
                    },
                    yAxis: {
                        type: 'category',
                        data: fixtureList
                    },
                    series: [
                        {
                            name: 'Attack Delta',
                            data: attackDelta,
                            type: 'bar'
                        },
                        {
                            name: 'Defense Delta',
                            data: defenceDelta,
                            type: 'bar'
                        },
                    ]
                }}
                style={{ height: '400px', width: '100%' }}
                opts={{ renderer: 'svg' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

