import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Flex,
    Text,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode
} from "@chakra-ui/react";
import chroma from "chroma-js";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function FixtureHighlights({horizon}) {

    console.log(horizon); //DEBUG

    //const [horizon, setHorizon] = useState(6); //Horizon in gameweeks
    //Fixture highlights are different for each horizon
    const [horizonHighlights, setHorizonHighlights] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode();

    const diffCol =
        chroma.scale(['#BACB2C', '#FFFFFF', '#F55C47'])
            //chroma.scale("Spectral")
            .domain([2, 3, 4])
            .padding(-0.5);

    // ...........
    // Gather data
    const highlights_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/fixturelist_highlights.json`;
    const { status, data, error } = useFetch(highlights_url);

    // Get next GW
    //const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    useEffect(() => {
        //console.log(data);
        if (status == 'fetched') {

            //console.log(data); //DEBUG
            // First get all pl fixtures
            let horizon_highlights =
                data.filter(highlights => highlights['horizon'] === horizon)
                    .sort((a, b) => a.mean_fix_diff - b.mean_fix_diff );

            //console.log(horizon_highlights); //DEBUG

            setHorizonHighlights(horizon_highlights);
        }

    }, [horizon, status])

    const DisplayHorizonHighlights = data && horizonHighlights && horizonHighlights.map(
        (team_highlights) => {
            return (
                <Tr key={team_highlights.short_name}>
                    <Td minWidth={0}>
                        <HStack>
                            <Image src={getShirtImg(team_highlights.code)} height="20px" />
                            <Link fontSize={['xs','sm']} color="brand.100" as={ReactRouterLink} to={`../team/${team_highlights.short_name}`}>{team_highlights.name}</Link>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={'gray.500'}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        >
                        <HStack justify='end'>
                            {/* pl='32px' is a hack because content is not right-justified on mobile browsers */}
                            <Text pl='32px' fontSize={['xs','sm']}>{team_highlights.num_games_all_comp}</Text>
                            <Text fontSize='xs'>({team_highlights.num_non_pl_games})</Text>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={'gray.500'}
                        minWidth={0}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis">
                        {team_highlights.num_unsceduled_pl_games > 0 &&
                            <HStack align='right' justify='end'>
                                {/* pl='32px' is a hack because content is not right-justified on mobile browsers */}
                                <Text pl='32px' fontSize={['xs','sm']}>{team_highlights.num_unsceduled_pl_games}</Text>
                                <Text fontSize='xs'>({team_highlights.num_unsceduled_non_pl_games})</Text>
                            </HStack>}
                        {team_highlights.num_unsceduled_pl_games === 0 &&
                            <Text></Text>}

                    </Td>
                    <Td fontSize={['xs','sm']}
                        color={'brand.black'}
                        backgroundColor={String(diffCol(team_highlights.mean_fix_diff))}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        isNumeric>
                        {(Math.round(team_highlights.mean_fix_diff * 100) / 100).toFixed(2)}
                    </Td>
                </Tr >
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>Fixture Highlights</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Loading Fixtures' />}
            {status === 'fetched' && 
                <Table variant='simple' size='xs' colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th fontSize={"xs"}>Team</Th>
                            <Th fontSize={"xs"} isNumeric>Number of matches</Th>
                            <Th fontSize={"xs"} isNumeric>Unscheduled matches</Th>
                            <Th fontSize={"xs"} isNumeric>Average Difficulty</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {DisplayHorizonHighlights}
                    </Tbody>
                </Table>
            }
        </>
    );
}

