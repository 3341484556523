import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
//import { BsArrowRight } from 'react-icons/bs';
//import { RiUser3Line, RiUser3Fill } from 'react-icons/ri'
import { BiSmile, BiSend } from 'react-icons/bi';
import Picker from 'emoji-picker-react'; // Lazy!
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    ButtonGroup,
    Box,
    Flex,
    HStack,
    Icon,
    Image,
    Text,
    FormControl,
    Input,
    Spacer,
    Stack,
    Spinner
} from "@chakra-ui/react";

//import { useAuthState } from 'react-firebase-hooks/auth';
import { useChat } from "../../hooks/chat";
import { useCollectionData } from 'react-firebase-hooks/firestore'; //TODO: Use or not use?
import { useAuth } from "../../context/AuthContext"
import { app, auth, db, analytics } from "../../config/firebase";
import {
    query, orderBy, limit,
    collection, addDoc, getDocs, getDocsFromServer,
    serverTimestamp
} from "firebase/firestore";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import ChatMessageGroup from "./ChatMessageGroup";
import { SpinnerWithMessage } from '../ui/SpinnerWithMessage';

// ............................................................................
function Chat(props) {
    //const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState(''); //Send message form
    const [pickerVisible, setPickerVisible] = useState(false);
    //const [chosenEmoji, setChosenEmoji] = useState(null);
    const inputRef = useRef(null);

    const onEmojiClick = (e, emojiObject) => {
        const { selectionStart, selectionEnd } = inputRef.current;
        // replace selected text with clicked emoji (or at cursor)
        const newVal = inputValue.slice(0, selectionStart) + emojiObject.emoji + inputValue.slice(selectionEnd);
        setInputValue(newVal);
    }

    const league_id = props.league_id;
    const league_subcollection = '/league-' + league_id; //Firestore collection

    const { currentUser } = useAuth();

    const [
        groupedMessages,
        loading,
        error,
        messagesCollectionRef
    ] = useChat(currentUser.uid, league_subcollection); // /src/hooks/chat.js

    const navigate = useNavigate();

    const scroll_to_bottom_of_chat = useRef(); //Make sure last message is visible

    // ........................................................................
    const sendMessage = async (e) => {
        e.preventDefault();

        const { uid, email } = auth.currentUser;

        //console.log("Sending..."); //DEBUG

        let msg = {
            content: inputValue,
            type: 'text',
            createdAt: serverTimestamp(),
            uid
        };

        // Make the emoji picker invisible, if it's visible
        setPickerVisible(false);

        //console.log(msg); //DEBUG

        await addDoc(messagesCollectionRef, msg)

        //updateMessages(currentUser.uid); //rerender the messages from the server

        setInputValue('');
        if (scroll_to_bottom_of_chat.current)
            scroll_to_bottom_of_chat.current.scrollIntoView({ behavior: 'smooth' });
    }

    // ........................................................................
    return (
        <>
            {!currentUser &&
                <Alert status="info"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center">
                    <AlertIcon boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                        Log in to chat with others from your league!
                    </AlertTitle>
                    <AlertDescription maxWidth="sm" mt={2}>
                        <ButtonGroup spacing="6">
                            <Button colorScheme="brand.100"
                                variant="outline"
                                onClick={() => navigate('/login')}
                            >
                                Login
                            </Button>
                            <Button colorScheme="brand.100"
                                onClick={() => navigate('/signup')}
                            >
                                Sign up
                            </Button>
                        </ButtonGroup>
                    </AlertDescription>
                </Alert>
            }
            {currentUser &&
                <Stack>
                    {error &&
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Error loading chat history.</AlertTitle>
                            <AlertDescription>Sorry about that. Keep calm and try again.</AlertDescription>
                        </Alert>
                    }
                    {loading && <SpinnerWithMessage message='Loading Chat' />}

                    {groupedMessages && !loading &&
                        groupedMessages.map(msg => <ChatMessageGroup key={msg.latestCreatedAt + '_' + msg.uid} message_group={msg} />)}
                    
                    <form onSubmit={sendMessage}>
                        <FormControl id="chat_message">
                            <HStack align='center' spacing={2}>
                                <Button onClick={() => setPickerVisible(!pickerVisible)} variant='link'>
                                    <Icon as={BiSmile} color='brand.100' size='xl' />
                                </Button>
                                <Input
                                    autoFocus
                                    ref={inputRef}
                                    value={inputValue}
                                    focusBorderColor='brand.100'
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="Your message..."
                                    maxLength='100' />
                                <Button type="submit" disabled={!inputValue} variant='link'>
                                    <Icon as={BiSend} color='brand.100' size="xl" />
                                </Button>
                            </HStack>
                        </FormControl>
                    </form>
                    {pickerVisible &&
                        <Picker
                            onEmojiClick={onEmojiClick}
                            pickerStyle={{ width: '100%', boxShadow: 'none' }} />}
                    <span ref={scroll_to_bottom_of_chat} />

                </Stack>
            }


        </>
    );
}

export default Chat;