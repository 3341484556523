import React, { useEffect, useState } from "react";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Stack,
  HStack,
  Text,
  TableCaption,
  Container
} from "@chakra-ui/react"

export default function LeagueStandings({ standings, entry_rank }) {
  //console.log(entry_rank); //DEBUG
  const DisplayEntries = standings.results.map(
    (entry) => {
      //console.log(entry.rank); //DEBUG
      return (
        <Tr key={entry.entry_name} bg={entry.rank == entry_rank ? 'brand.100' : ''}>
          <Td>
            {entry.rank < entry.last_rank &&
              <HStack>
                <Text fontSize={['sm', 'md', 'lg']} color='brand.400' fontWeight={entry.rank == entry_rank ? 'bold' : ''}>
                  {entry.rank}
                </Text>
                <Text fontSize={['xs', 'sm']} color='brand.400'>
                  (+{Math.abs(entry.rank - entry.last_rank)})
                </Text>
              </HStack>}
            {entry.rank > entry.last_rank &&
              <HStack>
                <Text fontSize={['sm', 'md', 'lg']} color='brand.600' fontWeight={entry.rank == entry_rank ? 'bold' : ''}>
                  {entry.rank}
                </Text>
                <Text fontSize={['xs', 'sm']} color='brand.600'>
                  (-{Math.abs(entry.rank - entry.last_rank)})
                </Text>
              </HStack>}
            {entry.rank === entry.last_rank &&
              <>
                <Text fontSize={['sm', 'md', 'lg']} fontWeight={entry.rank == entry_rank ? 'bold' : ''}>
                  {entry.rank}
                </Text>
              </>}
          </Td>
          {/*<Td fontSize={['xs', 'sm', 'md']}>{entry.last_rank}</Td>*/}
          <Td>
            <Stack>
              <Text fontSize={['sm', 'md', 'lg']}>{entry.entry_name}</Text>
              <Text fontSize={['xs', 'sm', 'md']} color={'gray.500'}>{entry.player_name}</Text>
            </Stack>
          </Td>
          <Td>{entry.event_total}</Td>
          <Td>{entry.total}</Td>
        </Tr>
      )
    }
  )
  //return(<pre>{JSON.stringify(standings.results)}</pre>)

  return (
    <Table size={'sm'} colorScheme="brand">
      <Thead>
        <Tr>
          <Th>Rank</Th>
          {/*<Th>Last Rank</Th>*/}
          <Th>Entry/Player</Th>
          <Th>GW</Th>
          <Th>Total</Th>
        </Tr>
      </Thead>
      <Tbody>
        {DisplayEntries}
      </Tbody>
    </Table>
  )
}