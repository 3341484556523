import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode,
    useColorModeValue,
    useTheme,
    useBreakpointValue
} from "@chakra-ui/react";
import chroma from "chroma-js";
import moment from 'moment';
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";

export const Candlestick = ({ selected_by, prices }) => {

    console.log(prices); //DEBUG

    const [xAxis, setXAxis] = useState([]);
    const [pricesSeries, setPricesSeries] = useState([]);
    const [selectedBySeries, setSelectedBySeries] = useState([]);

    const upColor = '#00da3c';
    const upBorderColor = '#008F28';
    const downColor = '#ec0000';
    const downBorderColor = '#8A0000';

    // Theme and color mode
    const { colorMode, toggleColorMode } = useColorMode();
    const theme = useTheme();
    const colors = theme.colors.echarts_default;

    //console.log(colors); //DEBUG

    const responsive_chart_height = useBreakpointValue({ base: '550px', sm: '600px', md: '700px', lg: '800px' })

    useEffect(() => {
        // Get chart data
        let x_axis_data = [];
        let selected_by_series_data = [];
        let prices_series_data = [];

        // From memory (might be wrong!):
        //[
        //    "2022-04-15",
        //    39, //open
        //    39, //close
        //    39, //low
        //    39, //high
        //    39,
        //    0
        //    ],
        selected_by.map(sb => {
            x_axis_data.push(moment(sb.shift(), 'YYYY-MM-DD').format('MMM Do'));
            selected_by_series_data.push(sb); // note shifted array!
        });
        //console.log(x_axis_data); //DEBUG

        prices.map(p => {
            prices_series_data.push(p[2]/10);
        });

        // The data arrives reversed from the API
        setXAxis(x_axis_data.reverse());
        setSelectedBySeries(selected_by_series_data.reverse());
        setPricesSeries(prices_series_data.reverse());

        //console.log(selectedBySeries); //DEBUG
        //console.log(pricesSeries); //DEBUG
    }, [])

    return (
        <>
            <ReactEcharts
                option={{
                    color: colors,
                    backgroundColor: '',
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: {
                        data: ['Selected By', 'Price']
                    },
                    grid: {
                        left: '10%',
                        right: '10%',
                        bottom: '15%'
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxis,
                        boundaryGap: false,
                        axisLine: { onZero: false },
                        splitLine: { show: false },
                        min: 'dataMin',
                        max: 'dataMax'
                    },
                    yAxis: [
                        {
                            type: 'value',
                            name: 'Selected By',
                            scale: true,
                            position: 'left',
                            axisLine: {
                                show: true,
                                //lineStyle: {
                                //    color: colors[0]
                                //}
                            },
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        },
                        {
                            type: 'value',
                            name: 'Price',
                            scale: true,
                            position: 'right',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                                //lineStyle: {
                                //    color: colors[1]
                                //}
                            },
                            axisLabel: {
                                formatter: '{value} m'
                            }
                        },
                    ],
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 50,
                            end: 100
                        },
                        {
                            show: true,
                            type: 'slider',
                            top: '90%',
                            start: 50,
                            end: 100
                        }
                    ],
                    series: [
                        {
                            name: 'Selected By',
                            type: 'candlestick',
                            yAxisIndex: 0,
                            data: selectedBySeries,
                            itemStyle: {
                                color: upColor,
                                color0: downColor,
                                borderColor: upBorderColor,
                                borderColor0: downBorderColor
                            },
                            markPoint: {
                                label: {
                                    formatter: function (param) {
                                        return param != null ? Math.round(param.value) + '%' : '';
                                    }
                                },
                                data: [
                                    {
                                        name: 'Mark',
                                        coord: ['2013/5/31', 2300],
                                        value: 2300,
                                        itemStyle: {
                                            color: 'rgb(41,60,85)'
                                        }
                                    },
                                    {
                                        name: 'highest value',
                                        type: 'max',
                                        valueDim: 'highest'
                                    },
                                    {
                                        name: 'lowest value',
                                        type: 'min',
                                        valueDim: 'lowest'
                                    },
                                    {
                                        name: 'average value on close',
                                        type: 'average',
                                        valueDim: 'close'
                                    }
                                ],
                                tooltip: {
                                    formatter: function (param) {
                                        return param.name + '<br>' + (param.data.coord || '');
                                    }
                                }
                            },
                            markLine: {
                                symbol: ['none', 'none'],
                                data: [
                                    [
                                        {
                                            name: 'from lowest to highest',
                                            type: 'min',
                                            valueDim: 'lowest',
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            label: {
                                                show: false
                                            },
                                            emphasis: {
                                                label: {
                                                    show: false
                                                }
                                            }
                                        },
                                        {
                                            type: 'max',
                                            valueDim: 'highest',
                                            symbol: 'circle',
                                            symbolSize: 10,
                                            label: {
                                                show: false
                                            },
                                            emphasis: {
                                                label: {
                                                    show: false
                                                }
                                            }
                                        }
                                    ],
                                    {
                                        name: 'min line on close',
                                        type: 'min',
                                        valueDim: 'close'
                                    },
                                    {
                                        name: 'max line on close',
                                        type: 'max',
                                        valueDim: 'close'
                                    }
                                ],
                                label: {
                                    position: 'start',
                                    formatter: function (param) {
                                        return param.data.value + '%';
                                    }
                                }
                            }
                        },
                        {
                            name: 'Price',
                            type: 'line',
                            yAxisIndex: 1,
                            data: pricesSeries,
                            smooth: true,
                            lineStyle: {
                                opacity: 0.5
                            }
                        }
                    ]
                }}
                style={{ height: responsive_chart_height, width: '100%' }}
                opts={{ renderer: 'svg' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

