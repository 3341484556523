import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode
} from "@chakra-ui/react";
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";

export default function FixtureDifficulty({horizon, nextGameweek}) {

    //const [horizon, setHorizon] = useState(6); //Horizon in gameweeks
    const [series, setSeries] = useState([]);
    const [yAxis, setYAxis] = useState([]);

    // Color mode, for setting correctly on chart
    const { colorMode, toggleColorMode } = useColorMode()

    // ...........
    // Gather data
    const fixturelist_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/fixturelist_extended.json`;
    const { status, data, error } = useFetch(fixturelist_url);

    //console.log(fixturelist_url); //DEBUG
    //console.log(nextGameweek.id+' '+horizon); //DEBUG

    // Get next GW
    //const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    //echarts legend
    //let history_legend = history.map(entry => {
    //    return (entry.entry_name);
    //})

    // echarts xAxis data - number of gameweeks
    // history.current should all be same length (number of gameweeks) - use first one
    //let history_xAxis = Array(history[0].current.length).fill().map((x, i) => i + 1)
    let fixtures_yaxis = Array();

    useEffect(() => {
        if (status == 'fetched') {

            //console.log(data); //DEBUG
            // First get all pl fixtures
            let pl_fixtures_in_horizon = data.filter(fixture =>
                fixture['competition'] === 'Premier League' ||
                fixture['competition'] === 'Premier league' // Todo: Must fix in the backend
            )
                .filter(fixture => fixture['gameweek'] >= nextGameweek.id)
                .filter(fixture => fixture['gameweek'] < nextGameweek.id + horizon)
                .sort((a, b) => a['gameweek'] - b['gameweek'] || a['short_name'] - b['short_name']);

            // Get the proper structures of teams and gameweeks
            let gws_set = new Set();
            let teams_set = new Set();
            pl_fixtures_in_horizon.forEach(fixture => {
                gws_set.add(fixture['gameweek']);
                teams_set.add(fixture['short_name']);
            });

            let gameweeks = Array
                .from(gws_set)
                .sort((a, b) => a - b);
            //console.log(gameweeks); //DEBUG

            let teams = Array
                .from(teams_set)
                .sort((a, b) => a - b);
            //console.log(teams); //DEBUG

            // Iterate over each gameweek (x-axis)
            // Create an array of series objects with data for all matches that gameweek
            let temp_series = [];
            gameweeks.forEach(gw => {
                let series_entry = {};

                //console.log(gw); //DEBUG
                series_entry['name'] = `${gw}`;

                // Iterate over each team (y-axis)
                series_entry['data'] = [];
                //series_entry['label_data'] = [];
                //series_entry['tooltip_data'] = [];
                teams.forEach(team => {
                    //console.log(team); //DEBUG

                    // Get the fixtures for the current team and gameweek
                    // This will be zero (empty gameweek), one (most gameweeks), two
                    // (double gameweeks) or perhaps three (triple gameweek) fixtures
                    // This corresponds to exactly one square on the chart
                    let fixtures = pl_fixtures_in_horizon
                        .filter(fixture => fixture['gameweek'] === gw) //Filter the gameweek
                        .filter(fixture => fixture['short_name'] === team) //Filter the team
                        .sort((a, b) => a['kickoff_date'] - b['kickoff_date']);

                    //console.log(fixtures); //DEBUG

                    // Generate the entries for the chart data
                    let series_entry_value = 0;
                    let series_entry_label = '';
                    let series_entry_tooltip_header = '';
                    let series_entry_tooltip_fixtures = '';

                    // Iterator for tags
                    function* tag() {
                        yield* ['', 'Double gameweek:<br/>', 'Triple gameweek:<br/>', 'Quadruple gameweek:<br/>', 'Insane gameweek:<br/>'];
                    }
                    let tag_iterator = tag();

                    // Home match: UPPERCASE, away match: lowercase
                    let opponent_formatted = '';

                    // Create the data object components (value, label and tooltip)
                    fixtures.forEach(match => {
                        match['ha'] === 'h' ? 
                            opponent_formatted = String(match['opponent_short_name']).toUpperCase() :
                            opponent_formatted = String(match['opponent_short_name']).toLowerCase() //ha == a
                            
                        series_entry_value += 1 / match['fixture_difficulty'] || 0;
                        series_entry_label ?
                            series_entry_label += `+${opponent_formatted}` :
                            series_entry_label += opponent_formatted;
                        series_entry_tooltip_header = tag_iterator.next().value;
                        series_entry_tooltip_fixtures += opponent_formatted + ' ' + match['kickoff_date'] + '<br/>';
                    });

                    // Add a data point with the combined fixture opportunity for all gameweek matches
                    // The tooltip value is used AUTOMATICALLY - I have no idea why this works and cannot find it in the docs
                    series_entry['data'].push(
                        {
                            'value': series_entry_value,
                            'label': series_entry_label,
                            'tooltip': series_entry_tooltip_header + series_entry_tooltip_fixtures
                        });
                });

                //series_entry['total'] = series_entry['data'].reduce((a, b) => a + b, 0);

                series_entry['type'] = 'bar';
                series_entry['stack'] = 'total';
                series_entry['label'] = {
                    show: true,
                    formatter: function(param) {
                        //console.log(param); //DEBUG
                        return param.data.label != 'undefined' ? param.data.label : '';
                    }
                };
                series_entry['emphasis'] = {
                    focus: 'series'
                };

                temp_series.push(series_entry);
            })

            //console.log(temp_series); //DEBUG

            setSeries(temp_series);
            setYAxis(Array.from(teams));

            //console.table(series);
        }

    }, [status, horizon, nextGameweek])

    return (
        <>
            <ReactEcharts
                option={{
                    backgroundColor: '',
                    textStyle: {
                        fontFamily: 'Roboto Mono, monospace'
                    },
                    tooltip: {
                        trigger: 'item',

                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' 
                        }
                    },
                    legend: {
                        tooltip: {
                            show: true,
                            position: 'left',
                            formatter: function(params) {
                                //console.log(params); //DEBUG
                                return `Gameweek ${params.name}`;
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top: '12%',
                        bottom: '8%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        name: 'Fixture Opportunity Index',
                        nameLocation: 'middle',
                        nameGap: 30,
                        splitArea: {
                            show: true
                        }
                    },
                    yAxis: {
                        type: 'category',
                        inverse: true,
                        data: yAxis
                    },
                    series: series,
                }}
                notMerge={true} //Ensures update when horizon is changed
                style={{ height: '550px', width: '100%' }}
                opts={{ renderer: 'svg' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

