import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Badge,
    Flex,
    Text,
    Tr,
    Td,
    Th,
    Table,
    TableCaption,
    Thead,
    Tbody,
    Spinner,
    HStack,
    Image,
    Link,
    Heading,
    Container,
    Stack,
    useColorMode
} from "@chakra-ui/react";
import { RecommendationBadge } from "../ui/RecommendationBadge";
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useAuth } from "../../context/AuthContext";
import chroma from "chroma-js";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function MyTeam() {

    const { currentUser, currentProfile } = useAuth();
    const [picksExtended, setPicksExtended] = useState([]);

    // Get the manager ID from firestore
    let fpl_manager_id = currentProfile && currentProfile.fpl_manager_id; // || '1111498';

    // Get next GW
    //const { status: gwStatus, data: gwData, error: gwError } = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next');

    const fpl_picks_url = fpl_manager_id
        && `${process.env.REACT_APP_API_ENDPOINT}fpltm/picks/last?entry=${fpl_manager_id}/`;
    const { status, data, error } = useFetch(fpl_picks_url);

    const { status: overviewStatus, data: overviewData, error: overviewError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`);

    //console.log(status);

    useEffect(() => {
        if (status === 'fetched' && overviewStatus === 'fetched') {

            let picks_extended = []; // picks + overview

            // For every pick, add data from overview api endpoint
            data['picks'].forEach(pick => {
                let extended_pick = overviewData.find(element => element['id'] === pick['element']);
                picks_extended.push({ ...pick, ...extended_pick });
            });

            //console.log(picks_extended); //DEBUG
            setPicksExtended(picks_extended);
        }

    }, [status, overviewStatus]);

    const DisplayPlayer = picksExtended.map(
        (player) => {
            return (
                <Tr key={player.code}>
                    <Td fontSize={['xs', 'sm', 'md']}
                        color={'gray.500'}
                        overflow="hidden"
                        textOverflow="ellipsis"

                    >
                        <HStack>
                            {player.player_position === 'G' && <Text color='brand.300'>G</Text>}
                            {player.player_position === 'D' && <Text color='brand.400'>D</Text>}
                            {player.player_position === 'M' && <Text color='brand.900'>M</Text>}
                            {player.player_position === 'F' && <Text color='brand.600'>F</Text>}
                            {player.multiplier === 0 && <Text color='gray.600'>sub</Text>}
                        </HStack>
                    </Td>
                    <Td minWidth={0}>
                        <HStack>
                            <Image src={getShirtImg(player.team_code)} height={['25px', '35px', '45px']} />
                            <Link fontSize={['xs', 'sm', 'md']} color="brand.100" as={ReactRouterLink} to={`../players/${player.code}/${player.web_name.replace(/[^\x00-\x7F]/g, "")}`}>{player.web_name}</Link>
                            <Text color='gray.400'>
                                {player.is_captain && '(C)'}
                                {player.is_vice_captain && '(VC)'}
                            </Text>
                        </HStack>
                    </Td>
                    <Td fontSize={['xs', 'sm', 'md']}
                        color={'gray.500'}
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        <RecommendationBadge recommendation={player.recommendation} />
                    </Td>
                </Tr>
            )
        }
    )

    return (
        <>
            {status === 'idle' && (
                <div>Fixture Highlights</div>
            )}
            {status === 'error' && <div>{error}</div>}
            {status === 'fetching' && <SpinnerWithMessage message='Loading Team' />}
            {status === 'fetched' && (
                <Table variant='simple' size='xs' colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th fontSize={['xs', 'sm', 'md']}></Th>
                            <Th fontSize={['xs', 'sm', 'md']}>Player</Th>
                            <Th fontSize={['xs', 'sm', 'md']}>Recommendation</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {DisplayPlayer}
                    </Tbody>
                </Table>
            )}
        </>
    );
}

