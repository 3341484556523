// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-emPxM6ZwycZcdpmg9iSXK1hmULJHW5Y",
  authDomain: "fpltm-1ac00.firebaseapp.com",
  projectId: "fpltm-1ac00",
  storageBucket: "fpltm-1ac00.appspot.com",
  messagingSenderId: "399512764941",
  appId: "1:399512764941:web:ec43e621a1f6730d310b44",
  //measurementId: "G-ENLJ6FSPR5" //Fetched from server
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
//export const messaging = getMessaging(app);

//console.log(app.name);

//export const analytics = getAnalytics(app);
//export const auth = getAuth(app);



