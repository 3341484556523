import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Avatar,
    Badge,
    Box,
    Button,
    Flex,
    SimpleGrid,
    Icon,
    Grid,
    GridItem,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    Wrap,
    WrapItem,
    useColorModeValue,
    useBreakpointValue
} from "@chakra-ui/react";
import Header from "../Header";
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Card } from '../ui/Card';
import { CardContent } from '../ui/CardContent';
import { CardHeader } from '../ui/CardHeader';
import { RecommendationBadge } from "../ui/RecommendationBadge";
import Gameweek from "../dashboard/Gameweek";
import FixtureList from './FixtureList';
import DeltaBar from './DeltaBar';
import moment from 'moment';
import chroma from "chroma-js";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { BsArrowsExpand } from "react-icons/bs";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function TeamOverview() {

    const { currentUser, currentProfile } = useAuth();
    const { team_short_name } = useParams();
    const [teamName, setTeamName] = useState('');
    const [teamCode, setTeamCode] = useState(0);
    const [teamOverview, setTeamOverview] = useState([]);

    //console.log(team_short_name); //DEBUG

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    // Get overview of all players, and extract info for this specific player
    const { status: overviewStatus, data: overviewData, error: overviewError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`);

    const bg_colour = useColorModeValue('gray.200', 'gray.800');
    const team_table_size = useBreakpointValue({ base: 'xs', sm: 'sm', md: 'md' });

    //console.log(data);

    useEffect(() => {
        if (gwStatus === 'fetched' && overviewStatus === 'fetched') {

            // Find and sort the playes of the team
            let team_overview = overviewData
                .filter(element => element.team_short_name === team_short_name)
                .sort((a, b) => a.element_type - b.element_type || a.now_cost - b.now_cost)

            //console.log(team_overview); //DEBUG
            setTeamOverview(team_overview);

            // From the first player in the list, set the full team name and team code
            setTeamName(team_overview[0].team_name);
            setTeamCode(team_overview[0].team_code);
            //console.log(teamName); //DEBUG
        }

    }, [gwStatus, overviewStatus]);

    const DisplayPlayer = teamOverview.map(
        (player) => {
            return (
                <Tr>
                    <Td px={['0px', '4px', '8px']}
                        fontSize={['xs', 'sm', 'md']}
                        fontWeight='bold'
                        color={'gray.500'}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        align='center'
                        justify='center'
                    >
                        {player.player_position === 'G' && <Text color='brand.300'>GKP</Text>}
                        {player.player_position === 'D' && <Text color='brand.400'>DEF</Text>}
                        {player.player_position === 'M' && <Text color='brand.900'>MID</Text>}
                        {player.player_position === 'F' && <Text color='brand.600'>FWD</Text>}
                    </Td>
                    <Td px={['0px', '4px', '8px']}>
                        <HStack>
                            {/*<Image src={getShirtImg(player.team_code)} height={['20px', '30px']} />*/}
                            <Link fontSize={['xs', 'sm', 'md']} color="brand.100" as={ReactRouterLink} to={`../player/${player.code}/${player.web_name.replace(/[^\x00-\x7F]/g, "")}`}>{player.web_name}</Link>
                        </HStack>
                    </Td>
                    <Td px={['0px', '4px', '8px']}
                        fontSize={['xs', 'sm', 'md']} isNumeric>
                        {player.selected_by_percent >= 1 ? <>{player.selected_by_percent}%</> : '-'}
                    </Td>
                    <Td px={['0px', '4px', '8px']}
                        fontSize={['xs', 'sm', 'md']} isNumeric>{player.now_cost / 10}m</Td>
                    <Td px={['4px', '4px', '8px']}
                        fontSize={['xs', 'sm', 'md']}
                        color={'gray.500'}
                        overflow="hidden"
                        textOverflow="ellipsis"
                    >
                        <RecommendationBadge recommendation={player.recommendation} />
                    </Td>
                </Tr>
            )
        }
    )

    return (
        <>
            {
                overviewStatus === 'idle' && (
                    <div>Player Details</div>
                )
            }
            {overviewStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{overviewError}</AlertTitle>
                </Alert>}
            {gwStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{gwError}</AlertTitle>
                </Alert>}
            {overviewStatus === 'fetching' && <SpinnerWithMessage message='Loading Team Overview' />}
            {gwStatus === 'fetched' && overviewStatus === 'fetched' &&
                <>
                    <SimpleGrid minChildWidth='320px' spacing='8px'
                        p='4px'
                        bt={'brand.500'}
                        bg={bg_colour}>
                        <Stack>
                            <Card>
                                <CardHeader title="Overview" />
                                <CardContent>
                                    <Stack align={'center'}>
                                        <Image
                                            src={getShirtImg(teamCode)}
                                            width={['100px', '150px', '200px']}
                                            fit='scale-down' />
                                        <Text fontWeight='bold'>{teamName}</Text>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader title="Fixture List" />
                                <CardContent>
                                    <FixtureList team_short_name={team_short_name} />
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader title="Fixture Deltas" />
                                <CardContent>
                                    <DeltaBar team_short_name={team_short_name} />
                                </CardContent>
                            </Card>
                        </Stack>
                        <Card>
                            <CardHeader title="Team overview" />
                            <CardContent>
                                <Table variant='simple' size={team_table_size} colorScheme="brand">
                                    <Thead>
                                        <Tr>
                                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Pos</Th>
                                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Player</Th>
                                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Sel. By</Th>
                                            <Th px={['0px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>Cost</Th>
                                            <Th px={['4px', '4px', '8px']} fontSize={['xs', 'sm', 'md']}>AI Rating</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {DisplayPlayer}
                                    </Tbody>
                                </Table>
                            </CardContent>
                        </Card>
                    </SimpleGrid>
                </>

            }
        </>
    );
}

