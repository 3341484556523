import {
    Box,
    chakra,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    Image,
    IconButton,
    HStack,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    //HatenaShareButton,
    //InstapaperShareButton,
    //LineShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    //LivejournalShareButton,
    //MailruShareButton,
    //OKShareButton,
    PinterestShareButton,
    PinterestIcon,
    //PocketShareButton,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    //TumblrShareButton,
    TwitterShareButton,
    TwitterIcon,
    //ViberShareButton,
    //VKShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    WorkplaceShareButton,
    WorkplaceIcon
} from "react-share";
import { ExternalLinkIcon } from '@chakra-ui/icons';
//import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { ReactNode } from 'react';
import logoTextLightMode from "../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../assets/img/logo/FPL_Logo_V2_2.png";
import { FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';

const SocialButton = ({
    children,
    label,
    href,
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function Footer() {

    const { colorMode, toggleColorMode } = useColorMode();

    const share_url='https://www.fpltransfermarketcom';
    const share_text='FPL Transfer Market';

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={2}>
                        <Box>
                            <Image
                                src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                                height={["75px", "125px", "175px"]}
                                fit='scale-down' />
                        </Box>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        <Link href={'/about-us'}>About us</Link>
                        <Link href={'/plans'}>Plans & Pricing</Link>
                        <Link href={'/contact-us'}>Contact us</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Legal</ListHeader>
                        <Link href={'/terms-of-service'}>Terms of Service</Link>
                        <Link href={'/privacy-policy'}>Privacy Policy</Link>
                        <Link href={'/refund-and-cancellation-policy'}>Refund & Cancellation Policy</Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Share</ListHeader>
                        <HStack pb={['4px', '8px', '16px']}>
                            <FacebookShareButton
                                url={share_url}
                                quote={share_text}
                            >
                                <FacebookIcon size={32} round='true' />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={share_url}
                                title={share_text}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>

                            <TelegramShareButton
                                url={share_url}
                                title={share_text}
                            >
                                <TelegramIcon size={32} round />
                            </TelegramShareButton>

                            <WhatsappShareButton
                                url={share_url}
                                title={share_text}
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton url={share_url} >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={share_url}
                                subject={share_text}
                                body={`Attached is a link to the FPL Transfer Market homepage. It's an awesome service for FPL Managers!`}
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                        </HStack>
                    </Stack>
                    {/*<Stack align={'flex-start'}>
                        <ListHeader>Subscribe to the free FPL fixture report</ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={'Your email address'}
                                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                border={0}
                                _focus={{
                                    bg: 'whiteAlpha.300',
                                }}
                            />
                            <IconButton
                                bg={useColorModeValue('brand.500', 'brand.500')}
                                color={useColorModeValue('white', 'gray.800')}
                                _hover={{
                                    bg: 'brand.100',
                                }}
                                aria-label="Subscribe"
                                icon={<BiMailSend />}
                            />
                        </Stack>
                        <Stack justify='center' pt={["4px", "10px", "16px"]} direction={'row'} spacing={6}>
                            <SocialButton label={'Twitter'} href={'#'}>
                                <FaTwitter />
                            </SocialButton>
                            <SocialButton label={'LinkedIn'} href={'#'}>
                                <FaLinkedin />
                            </SocialButton>
                            <SocialButton label={'Instagram'} href={'#'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>*/}
                </SimpleGrid>
                <Text pt={['6px', '8px', '16px']} fontSize={'xs'} color={useColorModeValue('gray.400', 'gray.500')}>
                    © 2022 FPL Transfer Market. All rights reserved. FPL Transfer Market is owned and operated by <Link href='https://stakkar.io' isExternal>Stakkar Analytics ehf<ExternalLinkIcon mx='2px' /></Link>. Registration number: 5512003020. <Link href='https://www.skatturinn.is/fyrirtaekjaskra/leit/kennitala/5512003020' isExternal>Company registry information (in Icelandic)<ExternalLinkIcon mx='2px' /></Link>  Email: <Link href='mailto:info@stakkar.io'>info@stakkar.io</Link> Address: <Link href='https://goo.gl/maps/Jhfpboio4w1Gr8vH9' isExternal>Icelandic Ocean Cluster, Grandagarður 16, 101 Reykjavík, Iceland.<ExternalLinkIcon mx='2px' /></Link>
                </Text>
            </Container>
        </Box>
    );
}