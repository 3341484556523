import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams,
    useNavigate
} from "react-router-dom";
import {
    Avatar,
    Box,
    Badge,
    Button,
    Flex,
    SimpleGrid,
    Grid,
    GridItem,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Slider,
    SliderMark,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import Header from "../Header";
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import Gameweek from "./Gameweek";
import News from "./News";
import FixtureOpportunityIndex from "./FixtureOpportunityIndex";
import FixtureHighlights from "./FixtureHighlights";
import RestDays from "./RestDays";
import TransferActivity from "./TransferActivity";
import { Adsense } from '@ctrl/react-adsense';
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { IoCode } from "react-icons/io5";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";
import { AiOutlineArrowRight } from 'react-icons/ai';

export default function Dashboard() {

    const { currentUser, currentProfile, currentEntry } = useAuth();
    const [horizon, setHorizon] = useState(6);

    const navigate = useNavigate();

    // Get next GW 
    const { status: gwStatus, data: gwData, error: gwError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    return (
        <>
            {/*<Modal closeOnOverlayClick={false} isOpen={true} size={'xl'} isCentered>
                <ModalOverlay
                    backdropFilter='blur(10px) hue-rotate(90deg)' />
                <ModalContent>
                    <ModalHeader>Closed for the off-season</ModalHeader>
                    <ModalBody>
                        <Text>FPL Transfer Market will be back in July 2022 for the 22/23 season.</Text>
                        <Text pt='6px'>See you then!</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>*/}
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Card>
                    <CardHeader title="Gameweek" />
                    <CardContent>
                        {gwStatus === 'error' && <div>{gwError}</div>}
                        {gwStatus === 'fetching' && <SpinnerWithMessage message='Loading Gameweek Info' />}
                        {gwStatus === 'fetched' && <Gameweek horizon={horizon} nextGameweek={gwData} />}
                    </CardContent>
                </Card>
                {/*<Card>
                    <CardHeader title="End-of-Season Offer" />
                    <CardContent>
                        <Stack>
                            <Text>Subscribe now and get Premium FREE until the start of the 22/23 season!</Text>
                            <Button
                                onClick={() => navigate('/plans')}
                                variant="solid"
                                colorScheme="brand"
                                rightIcon={<AiOutlineArrowRight />} type="button">See plans and pricing</Button>
                        </Stack>
                    </CardContent>
                </Card>*/}
                <Card>
                    <CardHeader title="Fixture Horizon">
                        <p>A slider to choose the number of gameweeks that the FPL Transfer Market analytics engine looks forward during calculations.</p>
                        <br />
                        <p>This affects the Fixture Highlights, Fixture Opportunity Index (FOI) and Rest Days charts in the dashboard.</p>
                        <br />
                        <p>You can choose a horizon of anywhere from 3 to 8 gameweeks, but we recommend looking 5 to 7 games ahead when planning your transfers. However, this is a matter of oppinion.</p>
                        <p>Towards the end of each season, fixture horizon is fixed to end at gameweek 38 (the last gameweek).</p>
                    </CardHeader>
                    <CardContent>
                        {gwStatus === 'idle' && (
                            <div>Fixture Highlights</div>
                        )}
                        {gwStatus === 'error' && <div>{gwError}</div>}
                        {gwStatus === 'fetching' && <SpinnerWithMessage message='Loading Horizon Selector' />}
                        {gwStatus === 'fetched' &&
                            <Stack mx={['8px', '16px', '32px']} mt='32px' >
                                <Slider
                                    colorScheme='brand'
                                    aria-label='slider-horizon'
                                    focusThumbOnChange={false}
                                    onChangeEnd={(val) => setHorizon(val)}
                                    defaultValue={Math.min(6, 1 + 38 - gwData.id)}
                                    min={3}
                                    max={Math.min(8, 1 + 38 - gwData.id)}
                                    step={1}>
                                    {gwData.id + 2 <= 38 &&
                                        <SliderMark value={3} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 2}
                                        </SliderMark>}
                                    {gwData.id + 3 <= 38 &&
                                        <SliderMark value={4} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 3}
                                        </SliderMark>}
                                    {gwData.id + 4 <= 38 &&
                                        <SliderMark value={5} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 4}
                                        </SliderMark>}
                                    {gwData.id + 5 <= 38 &&
                                        <SliderMark value={6} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 5}
                                        </SliderMark>}
                                    {gwData.id + 6 <= 38 &&
                                        <SliderMark value={7} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 6}
                                        </SliderMark>}
                                    {gwData.id + 7 <= 38 &&
                                        <SliderMark value={8} mt='2' ml='-1' fontSize='sm'>
                                            {gwData.id + 7}
                                        </SliderMark>}
                                    <SliderMark
                                        value={horizon}
                                        textAlign='center'
                                        bg='brand.100'
                                        mt='-10'
                                        ml='-6'
                                        w='12'
                                    >
                                        {horizon}
                                    </SliderMark>
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb boxSize={6}>
                                        <Box color='brand.100' as={IoCode} />
                                    </SliderThumb>
                                </Slider>
                                <Text pt={['4px', '8px', '12px']}>Gameweek</Text>
                            </Stack>}
                    </CardContent>
                 </Card>
                {/*<Adsense
                    client="ca-pub-1760666475710788"
                    slot="6637885333"
                    style={{ display: 'inline-block', width: '450px', height: '50px' }}
                />*/}
            </SimpleGrid>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Card>
                    <CardHeader title="Fixture Highlights" >
                        <p>The <b>Fixture Highlights</b> table shows a 7-gameweek overview of upcoming fixtures,
                            in order of average fixture difficulty.</p>
                        <br />
                        <p>The <b>Number of matches</b> column shows how many games each team has upcoming,
                            of which games in competitions other than PL are in brackets. Example: 9(1) indicates
                            9 matches played in the next 7 gameweeks, of which one is in a competition other than
                            PL, and thus 8 PL matches will be played (one double gameweek).</p>
                        <br />
                        <p>The <b>Unscheduled matches</b> column shows how many matches are unscheduled, of
                            which matches in competitions other than PL are in brackets. Uncheduled PL matches
                            may indicate future double gameweeks.</p>
                    </CardHeader>
                    <CardContent>
                        <FixtureHighlights horizon={horizon} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Fixture Opportunity Index" >
                        <p>The <b>Fixture Opportunity Index</b> is calculated as an inverse of
                            fixture difficulty. It quantifies the opportunity to score points
                            that arises from a team's upcoming fixture schedule, taking into
                            account the strength of the opponents.</p>
                        <br />
                        <p>Empty gameweeks present no opportunity (and hence are not shown),
                            while double gameweeks present a larger opportunity (the sum of two
                            games' opportunities).</p>
                        <br />
                        <p>The horizon is seven gameweeks. <b>Higher is better</b>.</p>
                    </CardHeader>
                    <CardContent>
                        <FixtureOpportunityIndex horizon={horizon} nextGameweek={gwData} />
                    </CardContent>
                </Card>
            </SimpleGrid>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Card>
                    <CardHeader title="Latest News" >
                        Latest FPL official player news.
                    </CardHeader>
                    <CardContent>
                        <News />
                    </CardContent>
                </Card>
            </SimpleGrid>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Card>
                    <CardHeader title="Rest Days" >
                        <p>The <b>Rest days</b> heatmap shows the number of days rest a team gets
                            before the matches of upcoming gameweeks, <b>taking into account matches
                                from other competitions</b> (FA Cup, UCL, etc.).</p>
                        <br />
                        <p>Short rest periods indicate a higher likelyhood that players will be rested.</p>
                        <br />
                        <p>Hover/touch the squares for a list of games.</p>
                    </CardHeader>
                    <CardContent>
                        <RestDays horizon={horizon} nextGameweek={gwData} />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Transfer Activity">
                        Shows the most transfered players (in and out) this gameweek.
                    </CardHeader>
                    <CardContent>
                        <TransferActivity />
                    </CardContent>
                </Card>
                {/*<Card>
                    <CardHeader title="Price trends" />
                    <CardContent>
                        <Text>Price trends</Text>
                    </CardContent>
                </Card>*/}
            </SimpleGrid>
        </>
    )
}

