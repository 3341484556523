import { Box, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const Card = (props) => (
  <Box  
    bg={useColorModeValue('white', 'gray.700')}
    rounded={{
      md: 'lg',
    }}
    shadow="base"
    overflow="hidden"
    width='100%'
    {...props}
  />
)