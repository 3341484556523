import React, { useRef, useState } from "react"
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Image,
    Link,
    Flex,
    Stack,
    Heading,
    Input,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useAuth } from "../../context/AuthContext";
import { app, auth, db, analytics } from "../../config/firebase";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";

export default function Signup() {
    const emailRef = useRef();
    const nameRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, signup, updateProfileInDb } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // ........................................................................
    async function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match.");
        }

        try {
            setError("");
            setLoading(true);
            signup(nameRef.current.value, emailRef.current.value, passwordRef.current.value)
                .then(() => {
                    navigate("/profile-picture?flow=signup"); //Move to the profile page
                });

        } catch (e) {
            setError(e);
        }

        setLoading(false);
    }

    // ........................................................................
    return (

        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={[2, 4, 8]}>
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']} maxW={'auto'} py={6} px={2}>
                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    {currentUser &&
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>Please sign out before signing up with a new user.</AlertTitle>
                            <AlertDescription>
                                <Button onClick={() => auth.signOut()}>Sign Out</Button>
                            </AlertDescription>
                        </Alert>}
                    {!currentUser &&
                        <>
                            <Heading fontSize={'4xl'}>Sign up</Heading>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <form onSubmit={handleSubmit}>
                                <FormControl id="name">
                                    <FormLabel>Name</FormLabel>
                                    <Input type="text" colorScheme="brand" ref={nameRef} required />
                                </FormControl>
                                <FormControl id="email" mt={4}>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" colorScheme="brand" ref={emailRef} required />
                                </FormControl>
                                <FormControl id="password" mt={4}>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" colorScheme="brand" ref={passwordRef} required />
                                </FormControl>
                                <FormControl id="password-confirm" mt={4}>
                                    <FormLabel>Password Confirmation</FormLabel>
                                    <Input type="password" colorScheme="brand" ref={passwordConfirmRef} required />
                                </FormControl>
                                <FormControl mt={4}>
                                    <Button
                                        disabled={loading} rightIcon={<AiOutlineArrowRight />}
                                        colorScheme="brand" className="w-100" type="submit">
                                        Let's go
                                    </Button>
                                </FormControl>
                            </form>

                            <Text>Already have an account?{" "}
                                <Link color="brand.100" as={ReactRouterLink} to="/login">Log In</Link>
                            </Text>
                        </>
                    }
                </Stack>
            </Box>
        </Flex>
    );

}