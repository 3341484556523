import React, { useContext, useState, useEffect } from "react"
import {
    getAuth,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    //resetPassword,
    updateEmail,
    updatePassword
} from "firebase/auth";
import { app, auth, db, analytics, storage } from "../config/firebase";
import {
    query, orderBy, limit, getDoc,
    collection, addDoc, getDocs,
    doc, setDoc,
    getDocsFromServer,
    serverTimestamp
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import { useCollectionData } from 'react-firebase-hooks/firestore';


export function useChat(uid, chat_room_id) { 
    const MAX_MESSAGES = 25;

    const [groupedMessages, setGroupedMessages] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Get messages from firestore
    const messagesCollectionRef = collection(db, 'fpltm-chat', 'rooms', chat_room_id); // subcollection is /fpltm-chat/rooms/{chat_room_id}
    const q = query(messagesCollectionRef, orderBy('createdAt', 'desc'), limit(MAX_MESSAGES))
    const [messages] = useCollectionData(q, { idField: 'id' });

    useEffect(() => {
        if(!messages) 
            return;

        setLoading(true);

        // Array to hold the entire conversation
        let msg_groups = [];

        //Iterate over messages to crate message groups
        let current_group = {}; //Holds each message group
        let current_group_messages = []; //All messages within the group
        let last_uid = '<none/>'; //For detecting change of group
        //console.log(">>>>>>> START >>>>>>>");
        messages && messages.forEach((msg) => {
            //let msg = doc.data();

            let current_uid = msg.uid;
            if (current_uid != last_uid) { //New uid => new group
                //Store the group of the previous pass, if not at start
                if (last_uid != '<none/>') {
                    current_group.messages = current_group_messages;
                    msg_groups.push(current_group); //Store group in main array
                }

                //console.log("----------------Starting a new group----------------"); //DEBUG
                // Initialize next message group
                current_group = {}; //Reinitialize group
                current_group_messages = []; //Reinitialize group message array
                current_group.uid = msg.uid;
                //if(hasProfileImg(msg.uid)) {
                //current_group.profileImgUrl = getProfileImgUrl(msg.uid);
                //} else {
                //    current_group.profileImgUrl = "https://localhost:3000/errorimg";
                //}

                //let profile = getProfile(msg.uid);
                //console.log('Name: ' + profile.name);
                //current_group.name = profile.name;
            }

            //Get the message contents and push onto the group
            let next_msg = { 'content': msg.content, 'type': msg.type, 'createdAt': msg.createdAt, 'uid': msg.uid };
            //console.log("next message:" + next_msg.content); //DEBUG
            //current_group_messages.push(next_msg); // We want it reversed...
            current_group_messages.unshift(next_msg);
            if(msg.createdAt && (current_group.latestCreatedAt < msg.createdAt.toDate() || !current_group.latestCreatedAt)) {
                current_group.latestCreatedAt = msg.createdAt.toDate(); //Always update latest time
            }
            last_uid = current_uid;
        });

        // Push final message group into the array of messages
        current_group.messages = current_group_messages;
        msg_groups.push(current_group);

        //console.log(">>>>>>> END >>>>>>>"); //DEBUG
        //console.log("All messages:"); //DEBUG
        //console.log(JSON.stringify(msg_groups)); //DEBUG

        // Store the grouped messages in the context
        if(msg_groups[0]['messages'].length > 0) {
            //console.log(msg_groups.length); //DEBUG
            //console.table(msg_groups); //DEBUG
            setGroupedMessages(msg_groups.reverse()); //Reverse because of rendering from bottom
        }
        //else
            //console.log("No messages"); //DEBUG

        setLoading(false);

    }, [messages])

    return [groupedMessages, loading, error, messagesCollectionRef];
}