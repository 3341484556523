import React from "react";
import { Outlet, Link, useRoutes, useParams } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import PremiumSidebar from "../../premium/PremiumSidebar";

export default function PremiumLayout() {
    return (
        <>
            <Header />
            <PremiumSidebar >
                <Outlet />
            </PremiumSidebar>
            <Footer />
        </>
    )
}
