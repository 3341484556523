import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Image,
    Link,
    Button,
    ButtonGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Heading,
    Text,
    Divider,
    Spacer,
    useColorModeValue,
} from '@chakra-ui/react';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";

export default function LeagueLanding() {

    const navigate = useNavigate();

    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack align={'center'} spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                    <Stack align={'center'}>
                        <Image
                            src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                            height={["75px", "125px", "175px"]}
                            fit='scale-down' />
                        <Heading fontSize={['lg', 'xl', '2xl']}>Enter your league ID...</Heading>
                        <Popover>
                            <PopoverTrigger>
                                <Link color="brand.100">Where do I find this?</Link>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Text as='i'>Go to your league on the Fantasy Premier League website. The league ID is a number in the URL:</Text>
                                    <Text mt={['4px','8px']}>https://fantasy.premierleague.com/leagues/<Text color='red'>xxxxxxx</Text>/standings/c</Text>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>

                    </Stack>

                    <FormControl id="league_id">
                        <Flex spacing={4}>
                            <Input placeholder="League ID" />
                            <Button colorScheme="brand">Go!</Button>
                        </Flex>
                    </FormControl>

                    <Divider />
                    <Heading align="center" fontSize={['lg', 'xl', '2xl']}>...or log in to see all your leagues</Heading>

                    <ButtonGroup spacing="6">
                        <Button
                            onClick={() => navigate('/login')}
                            colorScheme="brand"
                            variant="outline">
                            Log in
                        </Button>
                        <Button
                            onClick={() => navigate('/plans')}
                            colorScheme="brand">
                            Sign up
                        </Button>
                    </ButtonGroup>
                </Stack>
            </Box>
        </Flex>
    );
}