import React, { useRef, useState, useCallback } from "react";
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Avatar,
    AvatarGroup,
    BeatLoader,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Image,
    Link,
    Flex,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Stack,
    HStack,
    StackDivider,
    Heading,
    Input,
    Text,
    Spinner,
    Wrap,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { AiOutlineReload, AiOutlineArrowRight } from "react-icons/ai";
import { useAuth } from "../../context/AuthContext";
import { storage } from "../../config/firebase";
import { getStorage, ref, uploadBytes, uploadString, UploadResult } from "firebase/storage";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';

export default function ProfilePicture() {

    //const passwordConfirmRef = useRef()

    const {
        currentUser,
        currentProfile,
        currentProfileImgUrl,
        updateProfileInDb,
        updateProfileImgUrl
    } = useAuth();

    //Upload state
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    const [loading, setLoading] = useState(false); //Not needed?

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Avatar
    //const editorRef = useRef('');
    var editor = "";
    const [avatarImage, setAvatarImage] = useState('');
    const [avatarPosition, setAvatarPosition] = useState();
    const [avatarScale, setAvatarScale] = useState(1.0);
    const [avatarImageReady, setAvatarImageReady] = useState(false);
    const [avatarImageChanged, setAvatarImageChanged] = useState(false);

    const setEditorRef = (ed) => {
        //console.log("Setting AvatarEditor ref...");
        //editorRef.current = ed;
        editor = ed;
    };

    // Initial 'signup' or profile 'update'
    const flow = searchParams.get('flow');

    // ........................................................................
    // Firebase storeage
    const uploadImageToDb = async () => {
        //if (!blobUrl || !name) return null
        //const canvasScaled = editorRef.current.value.getImageScaledToCanvas();
        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();

        //console.log(croppedImg); //DEBUG

        try {
            const path = currentUser.uid + '/profileimg';
            const storageRef = ref(storage, path);
            await uploadString(storageRef, croppedImg, 'data_url')
                .then((uploadresult) => {
                    setAvatarImageChanged(false);
                    //console.log(uploadresult.metadata); //DEBUG
                })
        } catch (error) {
            console.log(error.code + ' ' + error.message);
            return (error.code + ' ' + error.message);
        } finally {
            //console.log('Calling updateProfileImgUrl...');
            updateProfileImgUrl(currentUser.uid);
        }
    }

    // ........................................................................
    // Dropzone
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles?.[0];

        if (!file) {
            return
        }

        //Set the avatar
        setAvatarImage(file);

    }, [])
    // ........................................................................
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png",
        noClick: true,
        noKeyboard: true,
        multiple: false
    })

    // ........................................................................
    const handleNewImage = e => {
        setAvatarImage(e.target.files[0]);
    }

    // ........................................................................
    const handleScale = e => {
        const scale = parseFloat(e.target.value);
        setAvatarScale(scale);
    }

    // ........................................................................
    const handlePositionChange = position => {
        setAvatarPosition(position);
    }

    // ........................................................................
    async function handleAvatarUpdate(e) {
        e.preventDefault();

        const profile = {
            //fpl_user: fplEmailRef.current.value, 
            //fpl_pass: fplPasswordRef.current.value,
            //fpl_manager_id: fplcheck.data.fpl_manager_id
        }

        try {
            setError("");
            setLoading(true);
            //console.log(profile); //DEBUG
            updateProfileInDb(profile);
            navigate("/");
        } catch (e) {
            setError(e);
        }

        setLoading(false);
    }

    // ........................................................................
    return (

        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={[2, 4, 8]}>
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']}
                    maxW={['xs', 'sm', 'md']} py={6} px={2}>

                    <Heading as="h1" fontSize={["lg", "xl", "4xl"]}>
                        Profile picture
                    </Heading>
                    {!currentUser &&
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle mr={2}>You need to be logged in before updating your profile.</AlertTitle>
                            <AlertDescription>
                                <Text>
                                    <Link color="brand.100" as={ReactRouterLink} to="/login">Log In</Link>
                                    {" "}or{" "}
                                    <Link color="brand.100" as={ReactRouterLink} to="/signup">Sign up</Link>
                                </Text>
                            </AlertDescription>
                        </Alert>}
                    {currentUser &&
                        <>
                            {error && <Alert variant="danger">{error}</Alert>}

                            {currentProfileImgUrl && <Text>Your current profile picture looks this:</Text>}
                            {currentProfileImgUrl && <Image height="200" witdh="200" fit="none" src={currentProfileImgUrl} />}

                            <form id='avatar_select_form' onSubmit={handleAvatarUpdate}>

                                {isDragActive ? (
                                    <Text mt={4}>
                                        Drop the file into the square...
                                    </Text>
                                ) : (
                                    <Text mt={4}>
                                        Drag your new profile picture into the square below, or use the button.
                                        Allowed file types are PNG/JPEG.
                                    </Text>
                                )}

                                <Flex
                                    justify="center"
                                    align="center"
                                    textAlign="center"
                                    bg="#ffffff"
                                    w={250}
                                    h={250}
                                    p={50}
                                    mt={[6, 8, 10]}
                                    borderRadius={5}
                                    {...getRootProps()}
                                >

                                    <AvatarEditor
                                        ref={setEditorRef}
                                        onImageReady={() => setAvatarImageReady(true)}
                                        onImageChange={() => setAvatarImageChanged(true)}
                                        allowZoomOut={true}
                                        scale={avatarScale}
                                        justify="center"
                                        align="center"
                                        textAlign="center"
                                        width={200}
                                        height={200}
                                        p={50}
                                        mt={[6, 8, 10]}
                                        borderRadius={5}
                                        image={avatarImage} />

                                    <Input {...getInputProps()} />

                                </Flex>

                                <Input id="profileimg" name="newImage" colorScheme="brand" type="file" onChange={handleNewImage} />

                                <Text mt={[6, 8, 10]}>Click and drag to place the image or move the slider to zoom.</Text>

                                <Slider
                                    aria-label="scale"
                                    step={0.01}
                                    defaultValue="1"
                                    name="scale"
                                    type="range"
                                    onChange={(val) => setAvatarScale(val)}
                                    min={0.1}
                                    max={2.0}
                                >
                                    <SliderTrack bg="brand.100">
                                        <SliderFilledTrack bg="brand.100" />
                                    </SliderTrack>
                                    <SliderThumb boxSize={6}>
                                        <Box color="brand.100" />
                                    </SliderThumb>
                                </Slider>

                                {avatarImageReady &&
                                    <FormControl mt={4}>
                                        <Button
                                            onClick={() => uploadImageToDb()}
                                            disabled={!avatarImageChanged}
                                            visibility={avatarImageChanged ? '' : 'hidden'}
                                            variant="outline"
                                            colorScheme="brand"
                                            type="button">
                                            Save image
                                        </Button>
                                    </FormControl>
                                }

                                {flow === 'signup' && avatarImageReady && !avatarImageChanged &&
                                    <FormControl mt={4}>
                                        <Button onClick={() => navigate('/fpl-login?flow=signup')}
                                            colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="submit">
                                            Continue
                                        </Button>
                                    </FormControl>
                                }

                                {flow != 'signup' &&
                                    <FormControl mt={4}>
                                        <Button onClick={() => navigate('/home')}
                                            colorScheme="brand" rightIcon={<AiOutlineArrowRight />} type="submit">
                                            Look's good
                                        </Button>
                                    </FormControl>
                                }

                            </form>

                        </>
                    }
                </Stack>
            </Box>
        </Flex >
    )

}