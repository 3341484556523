import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Avatar,
    Box,
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    InputGroup,
    InputLeftElement,
    Icon,
    SimpleGrid,
    Grid,
    GridItem,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    List,
    ListItem,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Slider,
    SliderMark,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import Header from "../Header";
import { IoShirt } from 'react-icons/io5';
import { SearchIcon } from '@chakra-ui/icons';
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import { useFetch } from "../../hooks/fetch";
import { useKeyPress } from '../../hooks/keypress';
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";
import { RecommendationBadge } from "../ui/RecommendationBadge";
import { Candlestick } from './Candlestick';

export default function TechnicalAnalysis() {

    const { currentUser, currentProfile, currentEntry } = useAuth();

    // The search modal
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef();
    const finalRef = React.useRef(); // Ref of element to receive focus after close
    //const keyboardRef = React.useRef(); // For listenint to up/down keystrokes

    const [selected, setSelected] = useState(undefined);
    const downPress = useKeyPress("ArrowDown");
    const upPress = useKeyPress("ArrowUp");
    const enterPress = useKeyPress("Enter");
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);

    // The live query
    const [elementQuery, setElementQuery] = useState("");
    const [filteredElements, setFilteredElements] = useState([]);
    const [elementOverview, setElementOverview] = useState(); // The selected player overview
    //const [selectedElementDetails, setSelectedElementDetails] = useState(); // The selected player details

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    // Get overview of all players, and extract info for this specific player
    const { status: overviewStatus, data: overviewData, error: overviewError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`);

    const element_url = elementOverview && `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/elements/${elementOverview.web_name}${elementOverview.code}_details_current.json`
    //console.log(element_url); //DEBUG
    const { status: detailsStatus, data: detailsData, error: detailsError } = useFetch(element_url);

    const bg_colour = useColorModeValue('gray.200', 'gray.800');

    // Abreviate position and team on small screens
    const abbreviate = useBreakpointValue({ base: true, md: false });

    useEffect(() => {
        // Update the list of filtered elements
        if (elementQuery.length >= 2) {
            let filtered_elements = overviewData.filter((element) => {
                return element.first_name.startsWith(elementQuery) || element.second_name.startsWith(elementQuery);
            });

            setFilteredElements(filtered_elements);
            //console.log(filteredElements); //DEBUG
        }
    }, [elementQuery]);

    useEffect(() => {
        //console.log('Downpress');
        if (filteredElements.length && downPress && !hovered) {
            setCursor(prevState =>
                prevState < filteredElements.length - 1 ? prevState + 1 : prevState
            );
        }
    }, [downPress]);
    useEffect(() => {
        //console.log('Uppress');
        if (filteredElements.length && upPress && !hovered) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
        }
    }, [upPress]);
    useEffect(() => {
        //console.log('Enterpress');
        if (filteredElements.length && enterPress) {
            setElementOverview(filteredElements[cursor]);
            onClose();
            //setSelected(filteredElements[cursor]);
        }
    }, [cursor, enterPress]);
    useEffect(() => {
        if (filteredElements.length && hovered) {
            setCursor(filteredElements.indexOf(hovered));
        }
    }, [hovered]);

    //useEffect(() => { // HANDLED IN RERENDER
    // Fetch element details
    // element_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/elements/${elementOverview.web_name}${elementOverview.element_code}_details_current.json`
    //console.log(element_url); //DEBUG
    //const element_data = await fetch(element_url);

    //setSelectedElementDetails(element_data);
    //console.log('Details...'); //DEBUG
    //console.log(detailsData); //DEBUG
    //}, [detailsStatus]);

    return (
        <>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                scrollBehavior="inside"
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Player Search</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {overviewStatus === 'error' && <div>{overviewError}</div>}
                        {overviewStatus === 'fetching' && <SpinnerWithMessage message='Loading Search' />}
                        {overviewStatus === 'fetched' &&
                            <FormControl>
                                <Input w='100%' type="text" size='lg'
                                    ref={initialRef}
                                    placeholder={"Player Search"}
                                    onChange={event => {
                                        setCursor(0);
                                        setElementQuery(event.target.value);
                                    }}
                                    value={elementQuery}
                                />
                            </FormControl>}
                        <List>
                            {filteredElements && filteredElements.map((element, index) => (
                                <ListItem
                                    active={(index === cursor || index === hovered) ? 'true' : 'false'}
                                    //item={item}
                                    //setSelected={setSelected}
                                    //setHovered={setHovered}
                                    onMouseEnter={() => {
                                        setCursor(index);
                                        setHovered(index);
                                    }}
                                    onMouseLeave={() => {
                                        setHovered(undefined);
                                    }}
                                    tabIndex={index}
                                    key={element.id}
                                    onClick={() => {
                                        setElementOverview(element);
                                        onClose();
                                    }}
                                    bg={(index === cursor || index === hovered) && 'brand.100'}
                                    color={(index === cursor || index === hovered) && 'white'}
                                    w='100%'
                                    align="center"
                                    p="4"
                                    my="4"
                                    borderRadius="lg"
                                    role="group"
                                    cursor="pointer"
                                >
                                    <HStack>
                                        <Image src={getShirtImg(element.team_code)} height="40px" alt={element.team_short_name} />
                                        <Text>{element.first_name} {element.second_name} ({element.team_short_name})</Text>
                                    </HStack>
                                </ListItem>
                            ))}
                        </List>
                        {/*!elementOverview && <Text>Sorry!</Text>}
                        {elementOverview && <Text>{elementOverview.web_name}</Text>*/}
                        {detailsStatus === 'error' && <div>{detailsError}</div>}
                        {detailsStatus === 'fetching' && <SpinnerWithMessage message='Loading Player Details' />}
                        {/*detailsStatus === 'fetched' && <Text>{detailsData.web_name}</Text>*/}
                    </ModalBody>
                </ModalContent>
            </Modal>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Stack>
                    <Card>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='brand.100' />}
                            />
                            <Input
                                onFocus={() => {
                                    setElementQuery('');
                                    setFilteredElements([]);
                                    onOpen();
                                }}
                                placeholder='Player Name'
                                w='100%'
                                type="text" />
                        </InputGroup>
                    </Card>
                    <Card>
                        <CardHeader title="Overview" />
                        <CardContent>
                            <Stack align='center'>
                                {detailsStatus != 'fetched' &&
                                    <>
                                        <Icon as={IoShirt} w={32} h={32} color='brand.100' />
                                        <Text color='brand.100'>Please select a player via the above input box.</Text>
                                    </>
                                }
                                {detailsStatus === 'fetched' &&
                                    <>
                                        <HStack alignItems='center' p='12px'>
                                            <Image
                                                src={getShirtImg(elementOverview.team_code)}
                                                width={['100px', '150px', '200px']}
                                                fit='scale-down' />
                                            <Stack>
                                                <HStack>
                                                    <Text fontWeight='bold' fontSize={["lg", "xl", "3xl"]}>{elementOverview.first_name} {elementOverview.second_name}</Text>
                                                </HStack>
                                                <RecommendationBadge recommendation={elementOverview.recommendation} />
                                                <HStack>
                                                    {elementOverview.player_position === 'G' && abbreviate && <Text fontStyle='italic'>GKP</Text>}
                                                    {elementOverview.player_position === 'D' && abbreviate && <Text fontStyle='italic'>DEF</Text>}
                                                    {elementOverview.player_position === 'M' && abbreviate && <Text fontStyle='italic'>MID</Text>}
                                                    {elementOverview.player_position === 'F' && abbreviate && <Text fontStyle='italic'>FWD</Text>}
                                                    {elementOverview.player_position === 'G' && !abbreviate && <Text fontStyle='italic'>Goalkeeper</Text>}
                                                    {elementOverview.player_position === 'D' && !abbreviate && <Text fontStyle='italic'>Defender</Text>}
                                                    {elementOverview.player_position === 'M' && !abbreviate && <Text fontStyle='italic'>Midfielder</Text>}
                                                    {elementOverview.player_position === 'F' && !abbreviate && <Text fontStyle='italic'>Forward</Text>}
                                                    <Text color='gray.500'>/</Text>
                                                    {abbreviate && <Text fontWeight='bold'>{elementOverview.team_short_name}</Text>}
                                                    {!abbreviate && <Text fontWeight='bold'>{elementOverview.team_name}</Text>}
                                                </HStack>
                                            </Stack>
                                        </HStack>

                                        <Table size='xs' colorScheme="brand">
                                            <Tbody>

                                                <Tr>
                                                    <Td fontSize={['xs', 'sm', 'md']}
                                                        color={'gray.500'}
                                                        overflow="hidden"
                                                        textOverflow="ellipsis">Selected by:</Td>
                                                    <Td pl={['4px', '6px', '8px']}>{elementOverview.selected_by_percent}%</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td fontSize={['xs', 'sm', 'md']}
                                                        color={'gray.500'}
                                                        overflow="hidden"
                                                        textOverflow="ellipsis">Cost:</Td>
                                                    <Td pl={['4px', '6px', '8px']}>{elementOverview.now_cost / 10}m</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td fontSize={['xs', 'sm', 'md']}
                                                        color={'gray.500'}
                                                        overflow="hidden"
                                                        textOverflow="ellipsis">Points:</Td>
                                                    <Td pl={['4px', '6px', '8px']}>{elementOverview.total_points}</Td>
                                                </Tr>
                                                {elementOverview.news &&
                                                    <Tr>
                                                        <Td fontSize={['xs', 'sm', 'md']}
                                                            color={'gray.500'}
                                                            overflow="hidden"
                                                            textOverflow="ellipsis">News:</Td>
                                                        <Td>{elementOverview.news}</Td>
                                                    </Tr>}
                                            </Tbody>
                                        </Table>
                                    </>
                                }
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </SimpleGrid>
            <SimpleGrid minChildWidth='640px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.200', 'gray.800')}>
                <Card>
                    <CardHeader title="Candlestick" />
                    <CardContent>
                        {detailsStatus === 'error' && <div>{detailsError}</div>}
                        {detailsStatus === 'fetching' && <SpinnerWithMessage message='Loading Market Data' />}
                        {detailsStatus === 'fetched' &&
                            <Candlestick
                                selected_by={detailsData.selected_by}
                                prices={detailsData.prices}
                            />}
                    </CardContent>
                </Card>

            </SimpleGrid>
            {/*<Card>
                    <CardHeader title="Price trends" />
                    <CardContent>
                        <Text>Price trends</Text>
                    </CardContent>
                </Card>*/}

        </>
    )
}

