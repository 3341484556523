import { ReactNode } from "react";
import {
  Avatar,
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  HStack,
  Collapse,
  Icon,
  Image,
  Heading,
  Badge,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Switch,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  useColorMode
} from '@chakra-ui/react';
import { background } from "@chakra-ui/styled-system";
import { useAuth } from "../context/AuthContext";
import { app, auth, db, analytics, storage } from "../config/firebase";
import { BsMoonFill, BsSunFill } from "react-icons/bs";
import { BiMenu } from "react-icons/bi";
import { AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
import logoLightMode from "../assets/img/logo/FPL_Logo_S_1.png";
import logoDarkMode from "../assets/img/logo/FPL_Logo_S_1.png";
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";

const Header = () => {

  const { colorMode, toggleColorMode } = useColorMode();
  const { currentUser, currentProfile, currentProfileImgUrl, logout } = useAuth();
  const { isOpen, onToggle } = useDisclosure();

  const navigate = useNavigate();

  return (
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem"
        borderBottom={1}
        borderStyle={'solid'}
        bg={useColorModeValue('gray.200', 'gray.600')}
      >
        <HStack px={['1','2','3']} >
          <Badge bg='brand.600' fontSize={['sm','md']}>BETA</Badge>
          <Text fontSize={['xs','sm']}>Please send feedback to{' '}
            <Link _hover={{'text-decoration': 'underline'}} to='mailto:support@fpltransfermarket.com'>support@fpltransfermarket.com</Link>.</Text>
        </HStack>
      </Flex>
  );
};

export default Header;