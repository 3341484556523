import { extendTheme } from "@chakra-ui/react"

const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
    fonts: {
      heading: 'Roboto Mono, monospace',
      body: 'Roboto Mono, monospace',
    },
    colors: {
        brand: {
          100: "#8D8FF7",
          200: "#E7B084",
          300: "#E37933",
          400: "#BACB2C",
          500: "#4034AB",
          600: "#F55C47",
          700: "#7BD2D8",
          800: "#FACE00",
          900: "#AA2EE6",
          "gray": "#272C40",
          "white": "#FFFFFF",
          "off-white": "#E6F4F1",
          "black": "#191D28"
        },
        echarts_default:
        [
          "#8D8FF7",
          "#E7B084",
          "#E37933",
          "#BACB2C",
          "#4034AB",
          "#F55C47",
          "#7BD2D8",
          "#FACE00",
          "#AA2EE6",
          "#083D77",
          "#6A2E35",
          "#E83F6F",
        ],
        echarts_alternative_1:
        [
          "#8D8FF7",
          "#4034AB",
          "#AA2EE6",
          "#7BD2D8",
          "#BACB2C",
          "#E83F6F",
          "#E37933",
          "#E7B084",
          "#FACE00",
          "#F55C47",
          "#083D77",
          "#6A2E35"
        ]
      },
}

export default extendTheme( config );