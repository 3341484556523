import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Avatar,
    Badge,
    Box,
    Button,
    Flex,
    SimpleGrid,
    Icon,
    Grid,
    GridItem,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    Wrap,
    WrapItem,
    useColorModeValue,
    useBreakpointValue
} from "@chakra-ui/react";
import Header from "../Header";
import { AiTwotoneStar, AiFillQuestionCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrowsExpand } from "react-icons/bs";
import { GiStarFormation } from 'react-icons/gi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Card } from '../ui/Card';
import { CardContent } from '../ui/CardContent';
import { CardHeader } from '../ui/CardHeader';
import Gameweek from "../dashboard/Gameweek";
import FixtureList from './FixtureList';
import DeltaBar from './DeltaBar';
import PointsByGameweek from "./PointsByGameweek";
import PricesAndSelectedBy from "./PricesAndSelectedBy";
import moment from 'moment';
import chroma from "chroma-js";
import { RecommendationBadge } from "../ui/RecommendationBadge";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function PlayerOverview() {

    const [elementOverview, setElementOverview] = useState({});
    const { currentUser, currentProfile } = useAuth();
    const { element_code, web_name } = useParams();

    // Fetch element details
    const element_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/static/elements/${web_name}${element_code}_details_current.json`
    console.log(element_url); //DEBUG
    const { status, data, error } = useFetch(element_url);

    // Get next GW
    const { status: gwStatus, data: gwData, error: gwError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`);

    // Get overview of all players, and extract info for this specific player
    const { status: overviewStatus, data: overviewData, error: overviewError }
        = useFetch(`${process.env.REACT_APP_API_ENDPOINT}fpltm/static/overview_current.json`);

    const bg_colour = useColorModeValue('gray.200', 'gray.800');

    // Abreviate position and team on small screens
    const abbreviate = useBreakpointValue({ base: true, md: false });
    //console.log(abbreviate); //DEBUG

    useEffect(() => {

        if (overviewStatus === 'fetched') {

            //console.log(element_code); //DEBUG

            // Find the player we want
            let element_overview =
                overviewData.filter(element => element['code'] == element_code);

            //console.log(element_overview); //DEBUG

            setElementOverview(element_overview[0]);
        }

    }, [status, gwStatus, overviewStatus]);

    return (
        <>
            {status === 'idle' && (
                <div>Player Details</div>
            )}
            {status === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{error}</AlertTitle>
                </Alert>}
            {overviewStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{overviewError}</AlertTitle>
                </Alert>}
            {gwStatus === 'error' &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>{gwError}</AlertTitle>
                </Alert>}
            {status === 'fetching' && <SpinnerWithMessage message='Loading Player Overview' />}
            {status === 'fetched' && gwStatus === 'fetched' && overviewStatus === 'fetched' &&
                <>
                    <SimpleGrid minChildWidth='320px' spacing='8px'
                        p='4px'
                        bt={'brand.500'}
                        bg={bg_colour}>

                        <Card>
                            <CardHeader title="Overview" />
                            <CardContent>
                                <Stack>
                                    <HStack alignItems='center' p='12px'>
                                        <Image
                                            src={getShirtImg(elementOverview.team_code)}
                                            width={['100px', '150px', '200px']}
                                            fit='scale-down' />
                                        <Stack align='center'>
                                            <Text fontWeight='bold' fontSize={["lg", "xl", "3xl"]}>{elementOverview.first_name} {elementOverview.second_name}</Text>
                                            <RecommendationBadge recommendation={elementOverview.recommendation} />
                                            <HStack>
                                                {elementOverview.player_position === 'G' && abbreviate && <Text fontStyle='italic'>GKP</Text>}
                                                {elementOverview.player_position === 'D' && abbreviate && <Text fontStyle='italic'>DEF</Text>}
                                                {elementOverview.player_position === 'M' && abbreviate && <Text fontStyle='italic'>MID</Text>}
                                                {elementOverview.player_position === 'F' && abbreviate && <Text fontStyle='italic'>FWD</Text>}
                                                {elementOverview.player_position === 'G' && !abbreviate && <Text fontStyle='italic'>Goalkeeper</Text>}
                                                {elementOverview.player_position === 'D' && !abbreviate && <Text fontStyle='italic'>Defender</Text>}
                                                {elementOverview.player_position === 'M' && !abbreviate && <Text fontStyle='italic'>Midfielder</Text>}
                                                {elementOverview.player_position === 'F' && !abbreviate && <Text fontStyle='italic'>Forward</Text>}
                                                <Text color='gray.500'>/</Text>
                                                {abbreviate && <Text fontWeight='bold'>{elementOverview.team_short_name}</Text>}
                                                {!abbreviate && <Text fontWeight='bold'>{elementOverview.team_name}</Text>}
                                            </HStack>
                                        </Stack>
                                    </HStack>

                                    <Table size='xs' colorScheme="brand">
                                        <Tbody>

                                            <Tr>
                                                <Td fontSize={['xs', 'sm', 'md']}
                                                    color={'gray.500'}
                                                    overflow="hidden"
                                                    textOverflow="ellipsis">Selected by:</Td>
                                                <Td pl={['4px', '6px', '8px']}>{elementOverview.selected_by_percent}%</Td>
                                            </Tr>
                                            <Tr>
                                                <Td fontSize={['xs', 'sm', 'md']}
                                                    color={'gray.500'}
                                                    overflow="hidden"
                                                    textOverflow="ellipsis">Cost:</Td>
                                                <Td pl={['4px', '6px', '8px']}>{elementOverview.now_cost / 10}m</Td>
                                            </Tr>
                                            <Tr>
                                                <Td fontSize={['xs', 'sm', 'md']}
                                                    color={'gray.500'}
                                                    overflow="hidden"
                                                    textOverflow="ellipsis">Points:</Td>
                                                <Td pl={['4px', '6px', '8px']}>{elementOverview.total_points}</Td>
                                            </Tr>
                                            {elementOverview.news &&
                                                <Tr>
                                                    <Td fontSize={['xs', 'sm', 'md']}
                                                        color={'gray.500'}
                                                        overflow="hidden"
                                                        textOverflow="ellipsis">News:</Td>
                                                    <Td>{elementOverview.news}</Td>
                                                </Tr>}
                                        </Tbody>
                                    </Table>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title="Price and selected by trends" />
                            <CardContent>
                                <PricesAndSelectedBy
                                    prices={data.prices}
                                    selected_by={data.selected_by} />
                            </CardContent>
                        </Card>
                    </SimpleGrid>
                    <SimpleGrid minChildWidth='320px' spacing='8px'
                        p='4px'
                        bt={'brand.500'}
                        bg={bg_colour}>
                        <Card>
                            <CardHeader title="Fixture List" />
                            <CardContent>
                                <FixtureList team_short_name={elementOverview.team_short_name} />
                            </CardContent>
                        </Card>

                        <Card>
                            <CardHeader title="Points By Gameweek" />
                            <CardContent>
                                <PointsByGameweek
                                    team_short_name={elementOverview.team_short_name}
                                    element_code={element_code}
                                    web_name={web_name} />
                            </CardContent>
                        </Card>
                    </SimpleGrid>
                </>
            }
        </>
    );
}

