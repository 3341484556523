import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Link,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import stepOne from '../../assets/img/fpl_team_id/find_your_fpl_team_id_step_1.png';
import stepTwo from '../../assets/img/fpl_team_id/find_your_fpl_team_id_step_2.png';
import stepThree from '../../assets/img/fpl_team_id/find_your_fpl_team_id_step_3.png';
import { useAuth } from "../../context/AuthContext";
import { ExternalLinkIcon } from '@chakra-ui/icons';

export default function FindYourFPLId() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="left">
                <Image
                    src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                    height={["75px", "125px", "175px"]} />
                <Heading as="h1" fontSize={["lg", "xl", "4xl"]} p={["4px", "8px", "16px"]}>
                    How to find your FPL team ID
                </Heading>

                <Heading as="h2" width='75%' fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Step One</Heading>+
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}>Log into your FPL team page on the Fantasy Premier League website:</Text>
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}><Link href='https://fantasy.premierleague.com/my-team/' isExternal>https://fantasy.premierleague.com/my-team/<ExternalLinkIcon mx='2px' /></Link>.</Text>
                <Image src={stepOne} width={["175px", "225px", "375px"]} />

                <Heading as="h2" width='75%' fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Step Two</Heading>
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}>
                Under Points/Rankings click <i>“View Gameweek history”</i>.
                </Text>
                <Image src={stepTwo} width={["175px", "225px", "375px"]} />

                <Heading as="h2" width='75%' fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Step Three</Heading>
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}>Your FPL team ID will be in your browser URL, which is of the form</Text>
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}>fantasy.premierleague.com/entry/<b>YOUR_FPL_TEAM_ID</b>/history</Text>
                <Text width='75%' align='left' px={["4px", "8px", "16px"]}>Your FPL team ID only contains digits.</Text>
                <Image src={stepThree} width={["175px", "225px", "375px"]} />
            </VStack>
        </Box>
    );
}