import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Avatar,
    Box,
    Flex,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import moment from 'moment';
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { SpinnerWithMessage } from "../ui/SpinnerWithMessage";

export default function Gameweek({horizon, nextGameweek}) {

    const { currentUser, currentProfile } = useAuth();

    // ...........
    // Gather data
    //const gameweek_url = `${process.env.REACT_APP_API_ENDPOINT}fpltm/events/next`;
    //const { status, data, error } = useFetch(gameweek_url);

    //const fixtures_url = '/fpltm/fixtures_current.json';
    //const { fixturesStatus, fixturesData, fixturesError } = useFetch(fixtures_url);

    //const overview_url = '/fpltm/overview_current.json';
    //const { overviewStatus, overviewData, overviewError } = useFetch(overview_url)

    //console.log(fpl_entry_url);

    return (
        <>
            {moment.duration(moment(nextGameweek.deadline_time).diff(moment())).asHours() < horizon &&
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle mr={2}>Deadline {moment(nextGameweek.deadline_time).fromNow()}!</AlertTitle>
                    <AlertDescription>
                        Gameweek {nextGameweek.id} deadline is at {moment(nextGameweek.deadline_time).format('HH:mm')}. Fixture horizon is {horizon} gameweeks.
                    </AlertDescription>
                </Alert>
            }
            {moment.duration(moment(nextGameweek.deadline_time).diff(moment())).asHours() >= horizon &&
                <Alert status='info'>
                    <AlertIcon />
                    Gameweek {nextGameweek.id} deadline is {moment(nextGameweek.deadline_time).fromNow()}. Fixture horizon is {horizon} gameweeks.
                </Alert>

            }
        </>

    )
}

