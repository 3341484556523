import React from "react";
import { useEffect, useState } from "react";
import { render } from 'react-dom';
import { useAuth } from "../../context/AuthContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink,
    useRouteMatch,
    useParams
} from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Flex,
    SimpleGrid,
    Grid,
    GridItem,
    Stack,
    HStack,
    Heading,
    Image,
    Link,
    Tr,
    Td,
    Table,
    TableCaption,
    Tbody,
    Thead,
    Th,
    Text,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import Header from "../Header";
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { Card } from '../ui/Card'
import { CardContent } from '../ui/CardContent'
import { CardHeader } from '../ui/CardHeader'
import Gameweek from "../dashboard/Gameweek";
import MyTeam from "./MyTeam";
import TransferSuggestions from "./TransferSuggestions";

import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";
import { BsArrowsExpand } from "react-icons/bs";


export default function Premium() {

    const { currentUser, currentProfile } = useAuth();

    return (
        <>
            <SimpleGrid minChildWidth='320px' spacing='8px'
                p='4px'
                bt={'brand.500'}
                bg={useColorModeValue('gray.50', 'gray.900')}>
                <Card>
                    <CardHeader title="My Team" />
                    <CardContent>
                        <MyTeam />
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader title="Transfer Suggestions" />
                    <CardContent>
                        <TransferSuggestions />
                    </CardContent>
                </Card>
            </SimpleGrid>
        </>
    )
}

