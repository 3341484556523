import React from "react";
import {
    Flex,
    useColorModeValue
} from '@chakra-ui/react';
import { Outlet, Link, useRoutes, useParams } from "react-router-dom";
import { render } from 'react-dom';

export default function ChatLayout() {

    const bg_colour = useColorModeValue('gray.200', 'gray.800');

    return (
        <Flex 
            flex={1} 
            direction="column" 
            h="100vh"
            bg='bg_color'>
            <Outlet />
        </Flex>
    )
}