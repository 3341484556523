import React, { useEffect, useState, ReactNode } from "react";
import {
    Box,
    Image,
    Stack,
    HStack,
    Heading,
    Text,
    Link,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Switch
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaCheckCircle } from 'react-icons/fa';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";

export default function About() {

    const { currentUser, currentProfile } = useAuth();
    const navigate = useNavigate();

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Image
                    src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                    height={["75px", "125px", "175px"]} />
                <Heading as="h1" fontSize={["lg", "xl", "4xl"]} p={["4px", "8px", "16px"]}>
                    Refund and Cancellation Policy
                </Heading>
<Text width='75%' align='left'>Updated at 2021-09-07</Text>

<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Definitions and key terms</Heading>
<Text width='75%' align='left'>To help explain things as clearly as possible in this Refund and Cancellation Policy, every time any of these terms are referenced, are strictly defined as:
 <List width='75%' align='left'>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information.</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to FPL Transfer Market, that is responsible for your information under this Return & Refund Policy.</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Customer: refers to the company, organization or person that signs up to use the FPL Transfer Market Service to manage the relationships with your consumers or service users.</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit FPL Transfer Market and use the services.</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Service: refers to the service provided by FPL Transfer Market as described in the relative terms (if available) and on this platform.</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Website: The FPL Transfer Market site, which can be accessed via this URL: www.fpltransfermarket.com</ListItem>
   <ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />You: a person or entity that is registered with FPL Transfer Market to use the Services.</ListItem>
 </List>
</Text>
<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Refund Policy</Heading>
<Text width='75%' align='left'>Subscription payments are final and are not refunded. FPL Transfer Market offers a 14-day trial period during which customers are encouraged to evaluate the product and decide if they want to pay the subscription fee. An electronic reminder notification, such as an email or SMS, and a link to online cancellation will be sent at least 7 days before the initiation of any initial or recurring subscription fee transaction.</Text>

<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Cancellation Policy</Heading>
<Text width='75%' align='left'>You may cancel your subscription at any time. After cancellation, your subscription will still be valid until the next billing cycle. Your credit card will not be charged again.</Text>

<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Your Consent</Heading>
<Text width='75%' align='left'>By using our website, registering an account, or making a purchase, you hereby consent to our Refund and Cancellation Policy and agree to its terms.</Text>

<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Changes To Our Refund and Cancellation Policy</Heading>
<Text width='75%' align='left'>Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Return & Refund Policy. If you do not want to agree to this or any updated Return & Refund Policy, you can delete your account.</Text>

<Heading as="h2" width='75%'  fontSize={["md", "lg", "3xl"]} p={["4px", "8px", "16px"]}>Contact Us</Heading>
<Text width='75%' align='left'>If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.</Text>
<List width='75%' align='left'><ListItem><ListIcon as={BsArrowRightCircleFill} color='brand.200' />Via Email: support@fpltransfermarket.com</ListItem></List>

            </VStack>

        </Box>
    );
}