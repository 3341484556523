import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Container,
    useColorMode,
    useColorModeValue,
    useTheme
} from "@chakra-ui/react";
import chroma from "chroma-js";
import moment from 'moment';
import ReactEcharts from "echarts-for-react";
import { useFetch } from "../../hooks/fetch";
import { getShirtImg } from "../../util/teams";

export default function PricesAndSelectedBy({ prices, selected_by }) {

    const [xAxis, setXAxis] = useState([]);
    const [pricesSeries, setPricesSeries] = useState([]);
    const [selectedBySeries, setSelectedBySeries] = useState([]);

    // Theme and color mode
    const { colorMode, toggleColorMode } = useColorMode();
    const theme = useTheme();
    const colors = theme.colors.echarts_default;

    //console.log(colors); //DEBUG

    useEffect(() => {
        // Get chart data
        let x_axis_data = [];
        let prices_series_data = [];
        let selected_by_series_data = [];

        prices.map(p => {
            x_axis_data.push(moment(p[0], 'YYYY-MM-DD').format('MMM Do'));
            prices_series_data.push(p[2] / 10); // Closing price
        });
        //console.log(x_axis_data); //DEBUG

        selected_by.map(sb => {
            selected_by_series_data.push(sb[2]);
        });

        // The data arrives reversed from the API
        setXAxis(x_axis_data.reverse());
        setPricesSeries(prices_series_data.reverse());
        setSelectedBySeries(selected_by_series_data.reverse());

        //console.log(selectedBySeries); //DEBUG
        //console.log(pricesSeries); //DEBUG
    }, [])

    return (
        <>
            <ReactEcharts
                option={{
                    color: colors,
                    backgroundColor: '',
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top: '10%',
                        bottom: '8%',
                        containLabel: true
                    },
                    //toolbox: {
                    //    feature: {
                    //        dataView: { show: true, readOnly: false },
                    //        magicType: { show: true, type: ['line', 'bar'] },
                    //        restore: { show: true },
                    //        saveAsImage: { show: true }
                    //    }
                    //},
                    legend: {
                        data: ['Price', 'Selected By']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            name: 'Date',
                            data: xAxis,
                            nameLocation: 'middle',
                            nameGap: 30,
                            axisPointer: {
                                type: 'shadow'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: 'Price',
                            position: 'left',
                            axisLine: {
                                show: true,
                                //lineStyle: {
                                //    color: colors[0]
                                //}
                            },
                            axisLabel: {
                                formatter: '{value} m'
                            }
                        },
                        {
                            type: 'value',
                            name: 'Selected By',
                            position: 'right',
                            alignTicks: true,
                            axisLine: {
                                show: true,
                                //lineStyle: {
                                //    color: colors[1]
                                //}
                            },
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        },
                    ],
                    series: [
                        {
                            name: 'Price',
                            type: 'line',
                            smooth: 0.5,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' m';
                                }
                            },
                            lineStyle: {
                                //color: colors[0],
                                width: 3
                            },
                            yAxisIndex: 0,
                            data: pricesSeries,
                        },
                        {
                            name: 'Selected By',
                            type: 'line',
                            smooth: 0.5,
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value + ' %';
                                }
                            },
                            lineStyle: {
                                //color: colors[5],
                                width: 3
                            },
                            yAxisIndex: 1,
                            data: selectedBySeries,
                        },
                    ]
                }}
                style={{ height: '400px', width: '100%' }}
                opts={{ renderer: 'svg' }}
                theme={colorMode === "light" ? "light" : "dark"}
            />
        </>
    );
}

