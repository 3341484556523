import React, { useRef, useState } from "react"
import {
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    Heading,
    Stack,
    HStack,
    Image,
    Flex,
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import logoTextLightMode from "../../assets/img/logo/FPL_Logo_V2_1.png";
import logoTextDarkMode from "../../assets/img/logo/FPL_Logo_V2_2.png";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("Check your inbox for further instructions.");
        } catch (e) {
            setError(`Failed to reset password. Error: ${e}`);
        }

        setLoading(false);
    }

    return (
        <Flex
            minH={['40vh', '60vh', '80vh']}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                my={[2, 4, 8]}
            >
                <Stack align={'left'} spacing={4} mx={'auto'} minW={['xs', 'sm', 'md']}
                    maxW={['xs', 'sm', 'md']} py={6} px={2}>

                    <Image
                        src={useColorModeValue(logoTextLightMode, logoTextDarkMode)}
                        height={["75px", "125px", "175px"]}
                        fit='scale-down' />
                    <Heading as="h1" fontSize={["lg", "xl", "4xl"]}>Reset Password</Heading>
                    {error &&
                        <Alert status='error'>
                            <AlertIcon />
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>}
                    {message &&
                        <Alert status='success'>
                            <AlertIcon />
                            <AlertTitle>{message}</AlertTitle>
                        </Alert>}
                    <form onSubmit={handleSubmit}>
                        <FormControl pb={3} id="email">
                            <FormLabel>Email</FormLabel>
                            <Input type="email" ref={emailRef} required />
                        </FormControl>
                        <Button colorScheme="brand" disabled={loading} type="submit">
                            Send Reset Password Email
                        </Button>
                    </form>
                    <Text color='brand.100' mt={4}>
                        <Link color="brand.100" to="/login">Login</Link>
                    </Text>
                    <HStack>
                        <Text>
                            Need an account?
                        </Text>
                        <Text color='brand.100' >
                            <Link to="/signup">Sign Up</Link>
                        </Text>
                    </HStack>

                </Stack>
            </Box>
        </Flex>

    )
}